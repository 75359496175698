.form-membership {
  background: url(../../asset/media/img/bg.png) white;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  padding: 3rem 0;
  height: 100vh;
}

.form-membership .form-wrapper {
  background-color: white;
  padding: 3rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 430px;
  margin: 50px auto;
  text-align: center;
}

.form-membership .form-wrapper .logo {
  background: url(../../asset/media/img/VentureTel-SMS-Logo.png) no-repeat;
  background-size: 100%;
  width: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100px;
  position: absolute;
  right: 0;
  left: 0;
  top: 45px;
  margin: auto;
}

.form-membership .form-wrapper h5 {
  text-align: center;
  margin-bottom: 2rem;
}

.form-membership .form-wrapper form .form-control {
  margin-bottom: 1.5rem;
}

.form-membership .form-wrapper hr {
  margin: 2rem 0;
}

@media screen and (max-width: 576px) {
  .form-membership {
    padding: 20px;
  }

  .form-membership .form-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.custom-control-label {
  line-height: 25px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3db16b;
  border-color: #3db16b;
}
