@import url(https://fonts.googleapis.com/css?family=Nunito:400&display=swap&subset=latin-ext);
@font-face {
	font-family: 'themify';
	src:url(../../static/media/themify.2c454669.eot);
	src:url(../../static/media/themify.2c454669.eot?#iefix-fvbane) format('embedded-opentype'),
		url(../../static/media/themify.a1ecc3b8.woff) format('woff'),
		url(../../static/media/themify.e23a7dca.ttf) format('truetype'),
		url(../../static/media/themify.cba68f98.svg#themify) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
	content: "\e600";
}
.ti-volume:before {
	content: "\e601";
}
.ti-user:before {
	content: "\e602";
}
.ti-unlock:before {
	content: "\e603";
}
.ti-unlink:before {
	content: "\e604";
}
.ti-trash:before {
	content: "\e605";
}
.ti-thought:before {
	content: "\e606";
}
.ti-target:before {
	content: "\e607";
}
.ti-tag:before {
	content: "\e608";
}
.ti-tablet:before {
	content: "\e609";
}
.ti-star:before {
	content: "\e60a";
}
.ti-spray:before {
	content: "\e60b";
}
.ti-signal:before {
	content: "\e60c";
}
.ti-shopping-cart:before {
	content: "\e60d";
}
.ti-shopping-cart-full:before {
	content: "\e60e";
}
.ti-settings:before {
	content: "\e60f";
}
.ti-search:before {
	content: "\e610";
}
.ti-zoom-in:before {
	content: "\e611";
}
.ti-zoom-out:before {
	content: "\e612";
}
.ti-cut:before {
	content: "\e613";
}
.ti-ruler:before {
	content: "\e614";
}
.ti-ruler-pencil:before {
	content: "\e615";
}
.ti-ruler-alt:before {
	content: "\e616";
}
.ti-bookmark:before {
	content: "\e617";
}
.ti-bookmark-alt:before {
	content: "\e618";
}
.ti-reload:before {
	content: "\e619";
}
.ti-plus:before {
	content: "\e61a";
}
.ti-pin:before {
	content: "\e61b";
}
.ti-pencil:before {
	content: "\e61c";
}
.ti-pencil-alt:before {
	content: "\e61d";
}
.ti-paint-roller:before {
	content: "\e61e";
}
.ti-paint-bucket:before {
	content: "\e61f";
}
.ti-na:before {
	content: "\e620";
}
.ti-mobile:before {
	content: "\e621";
}
.ti-minus:before {
	content: "\e622";
}
.ti-medall:before {
	content: "\e623";
}
.ti-medall-alt:before {
	content: "\e624";
}
.ti-marker:before {
	content: "\e625";
}
.ti-marker-alt:before {
	content: "\e626";
}
.ti-arrow-up:before {
	content: "\e627";
}
.ti-arrow-right:before {
	content: "\e628";
}
.ti-arrow-left:before {
	content: "\e629";
}
.ti-arrow-down:before {
	content: "\e62a";
}
.ti-lock:before {
	content: "\e62b";
}
.ti-location-arrow:before {
	content: "\e62c";
}
.ti-link:before {
	content: "\e62d";
}
.ti-layout:before {
	content: "\e62e";
}
.ti-layers:before {
	content: "\e62f";
}
.ti-layers-alt:before {
	content: "\e630";
}
.ti-key:before {
	content: "\e631";
}
.ti-import:before {
	content: "\e632";
}
.ti-image:before {
	content: "\e633";
}
.ti-heart:before {
	content: "\e634";
}
.ti-heart-broken:before {
	content: "\e635";
}
.ti-hand-stop:before {
	content: "\e636";
}
.ti-hand-open:before {
	content: "\e637";
}
.ti-hand-drag:before {
	content: "\e638";
}
.ti-folder:before {
	content: "\e639";
}
.ti-flag:before {
	content: "\e63a";
}
.ti-flag-alt:before {
	content: "\e63b";
}
.ti-flag-alt-2:before {
	content: "\e63c";
}
.ti-eye:before {
	content: "\e63d";
}
.ti-export:before {
	content: "\e63e";
}
.ti-exchange-vertical:before {
	content: "\e63f";
}
.ti-desktop:before {
	content: "\e640";
}
.ti-cup:before {
	content: "\e641";
}
.ti-crown:before {
	content: "\e642";
}
.ti-comments:before {
	content: "\e643";
}
.ti-comment:before {
	content: "\e644";
}
.ti-comment-alt:before {
	content: "\e645";
}
.ti-close:before {
	content: "\e646";
}
.ti-clip:before {
	content: "\e647";
}
.ti-angle-up:before {
	content: "\e648";
}
.ti-angle-right:before {
	content: "\e649";
}
.ti-angle-left:before {
	content: "\e64a";
}
.ti-angle-down:before {
	content: "\e64b";
}
.ti-check:before {
	content: "\e64c";
}
.ti-check-box:before {
	content: "\e64d";
}
.ti-camera:before {
	content: "\e64e";
}
.ti-announcement:before {
	content: "\e64f";
}
.ti-brush:before {
	content: "\e650";
}
.ti-briefcase:before {
	content: "\e651";
}
.ti-bolt:before {
	content: "\e652";
}
.ti-bolt-alt:before {
	content: "\e653";
}
.ti-blackboard:before {
	content: "\e654";
}
.ti-bag:before {
	content: "\e655";
}
.ti-move:before {
	content: "\e656";
}
.ti-arrows-vertical:before {
	content: "\e657";
}
.ti-arrows-horizontal:before {
	content: "\e658";
}
.ti-fullscreen:before {
	content: "\e659";
}
.ti-arrow-top-right:before {
	content: "\e65a";
}
.ti-arrow-top-left:before {
	content: "\e65b";
}
.ti-arrow-circle-up:before {
	content: "\e65c";
}
.ti-arrow-circle-right:before {
	content: "\e65d";
}
.ti-arrow-circle-left:before {
	content: "\e65e";
}
.ti-arrow-circle-down:before {
	content: "\e65f";
}
.ti-angle-double-up:before {
	content: "\e660";
}
.ti-angle-double-right:before {
	content: "\e661";
}
.ti-angle-double-left:before {
	content: "\e662";
}
.ti-angle-double-down:before {
	content: "\e663";
}
.ti-zip:before {
	content: "\e664";
}
.ti-world:before {
	content: "\e665";
}
.ti-wheelchair:before {
	content: "\e666";
}
.ti-view-list:before {
	content: "\e667";
}
.ti-view-list-alt:before {
	content: "\e668";
}
.ti-view-grid:before {
	content: "\e669";
}
.ti-uppercase:before {
	content: "\e66a";
}
.ti-upload:before {
	content: "\e66b";
}
.ti-underline:before {
	content: "\e66c";
}
.ti-truck:before {
	content: "\e66d";
}
.ti-timer:before {
	content: "\e66e";
}
.ti-ticket:before {
	content: "\e66f";
}
.ti-thumb-up:before {
	content: "\e670";
}
.ti-thumb-down:before {
	content: "\e671";
}
.ti-text:before {
	content: "\e672";
}
.ti-stats-up:before {
	content: "\e673";
}
.ti-stats-down:before {
	content: "\e674";
}
.ti-split-v:before {
	content: "\e675";
}
.ti-split-h:before {
	content: "\e676";
}
.ti-smallcap:before {
	content: "\e677";
}
.ti-shine:before {
	content: "\e678";
}
.ti-shift-right:before {
	content: "\e679";
}
.ti-shift-left:before {
	content: "\e67a";
}
.ti-shield:before {
	content: "\e67b";
}
.ti-notepad:before {
	content: "\e67c";
}
.ti-server:before {
	content: "\e67d";
}
.ti-quote-right:before {
	content: "\e67e";
}
.ti-quote-left:before {
	content: "\e67f";
}
.ti-pulse:before {
	content: "\e680";
}
.ti-printer:before {
	content: "\e681";
}
.ti-power-off:before {
	content: "\e682";
}
.ti-plug:before {
	content: "\e683";
}
.ti-pie-chart:before {
	content: "\e684";
}
.ti-paragraph:before {
	content: "\e685";
}
.ti-panel:before {
	content: "\e686";
}
.ti-package:before {
	content: "\e687";
}
.ti-music:before {
	content: "\e688";
}
.ti-music-alt:before {
	content: "\e689";
}
.ti-mouse:before {
	content: "\e68a";
}
.ti-mouse-alt:before {
	content: "\e68b";
}
.ti-money:before {
	content: "\e68c";
}
.ti-microphone:before {
	content: "\e68d";
}
.ti-menu:before {
	content: "\e68e";
}
.ti-menu-alt:before {
	content: "\e68f";
}
.ti-map:before {
	content: "\e690";
}
.ti-map-alt:before {
	content: "\e691";
}
.ti-loop:before {
	content: "\e692";
}
.ti-location-pin:before {
	content: "\e693";
}
.ti-list:before {
	content: "\e694";
}
.ti-light-bulb:before {
	content: "\e695";
}
.ti-Italic:before {
	content: "\e696";
}
.ti-info:before {
	content: "\e697";
}
.ti-infinite:before {
	content: "\e698";
}
.ti-id-badge:before {
	content: "\e699";
}
.ti-hummer:before {
	content: "\e69a";
}
.ti-home:before {
	content: "\e69b";
}
.ti-help:before {
	content: "\e69c";
}
.ti-headphone:before {
	content: "\e69d";
}
.ti-harddrives:before {
	content: "\e69e";
}
.ti-harddrive:before {
	content: "\e69f";
}
.ti-gift:before {
	content: "\e6a0";
}
.ti-game:before {
	content: "\e6a1";
}
.ti-filter:before {
	content: "\e6a2";
}
.ti-files:before {
	content: "\e6a3";
}
.ti-file:before {
	content: "\e6a4";
}
.ti-eraser:before {
	content: "\e6a5";
}
.ti-envelope:before {
	content: "\e6a6";
}
.ti-download:before {
	content: "\e6a7";
}
.ti-direction:before {
	content: "\e6a8";
}
.ti-direction-alt:before {
	content: "\e6a9";
}
.ti-dashboard:before {
	content: "\e6aa";
}
.ti-control-stop:before {
	content: "\e6ab";
}
.ti-control-shuffle:before {
	content: "\e6ac";
}
.ti-control-play:before {
	content: "\e6ad";
}
.ti-control-pause:before {
	content: "\e6ae";
}
.ti-control-forward:before {
	content: "\e6af";
}
.ti-control-backward:before {
	content: "\e6b0";
}
.ti-cloud:before {
	content: "\e6b1";
}
.ti-cloud-up:before {
	content: "\e6b2";
}
.ti-cloud-down:before {
	content: "\e6b3";
}
.ti-clipboard:before {
	content: "\e6b4";
}
.ti-car:before {
	content: "\e6b5";
}
.ti-calendar:before {
	content: "\e6b6";
}
.ti-book:before {
	content: "\e6b7";
}
.ti-bell:before {
	content: "\e6b8";
}
.ti-basketball:before {
	content: "\e6b9";
}
.ti-bar-chart:before {
	content: "\e6ba";
}
.ti-bar-chart-alt:before {
	content: "\e6bb";
}
.ti-back-right:before {
	content: "\e6bc";
}
.ti-back-left:before {
	content: "\e6bd";
}
.ti-arrows-corner:before {
	content: "\e6be";
}
.ti-archive:before {
	content: "\e6bf";
}
.ti-anchor:before {
	content: "\e6c0";
}
.ti-align-right:before {
	content: "\e6c1";
}
.ti-align-left:before {
	content: "\e6c2";
}
.ti-align-justify:before {
	content: "\e6c3";
}
.ti-align-center:before {
	content: "\e6c4";
}
.ti-alert:before {
	content: "\e6c5";
}
.ti-alarm-clock:before {
	content: "\e6c6";
}
.ti-agenda:before {
	content: "\e6c7";
}
.ti-write:before {
	content: "\e6c8";
}
.ti-window:before {
	content: "\e6c9";
}
.ti-widgetized:before {
	content: "\e6ca";
}
.ti-widget:before {
	content: "\e6cb";
}
.ti-widget-alt:before {
	content: "\e6cc";
}
.ti-wallet:before {
	content: "\e6cd";
}
.ti-video-clapper:before {
	content: "\e6ce";
}
.ti-video-camera:before {
	content: "\e6cf";
}
.ti-vector:before {
	content: "\e6d0";
}
.ti-themify-logo:before {
	content: "\e6d1";
}
.ti-themify-favicon:before {
	content: "\e6d2";
}
.ti-themify-favicon-alt:before {
	content: "\e6d3";
}
.ti-support:before {
	content: "\e6d4";
}
.ti-stamp:before {
	content: "\e6d5";
}
.ti-split-v-alt:before {
	content: "\e6d6";
}
.ti-slice:before {
	content: "\e6d7";
}
.ti-shortcode:before {
	content: "\e6d8";
}
.ti-shift-right-alt:before {
	content: "\e6d9";
}
.ti-shift-left-alt:before {
	content: "\e6da";
}
.ti-ruler-alt-2:before {
	content: "\e6db";
}
.ti-receipt:before {
	content: "\e6dc";
}
.ti-pin2:before {
	content: "\e6dd";
}
.ti-pin-alt:before {
	content: "\e6de";
}
.ti-pencil-alt2:before {
	content: "\e6df";
}
.ti-palette:before {
	content: "\e6e0";
}
.ti-more:before {
	content: "\e6e1";
}
.ti-more-alt:before {
	content: "\e6e2";
}
.ti-microphone-alt:before {
	content: "\e6e3";
}
.ti-magnet:before {
	content: "\e6e4";
}
.ti-line-double:before {
	content: "\e6e5";
}
.ti-line-dotted:before {
	content: "\e6e6";
}
.ti-line-dashed:before {
	content: "\e6e7";
}
.ti-layout-width-full:before {
	content: "\e6e8";
}
.ti-layout-width-default:before {
	content: "\e6e9";
}
.ti-layout-width-default-alt:before {
	content: "\e6ea";
}
.ti-layout-tab:before {
	content: "\e6eb";
}
.ti-layout-tab-window:before {
	content: "\e6ec";
}
.ti-layout-tab-v:before {
	content: "\e6ed";
}
.ti-layout-tab-min:before {
	content: "\e6ee";
}
.ti-layout-slider:before {
	content: "\e6ef";
}
.ti-layout-slider-alt:before {
	content: "\e6f0";
}
.ti-layout-sidebar-right:before {
	content: "\e6f1";
}
.ti-layout-sidebar-none:before {
	content: "\e6f2";
}
.ti-layout-sidebar-left:before {
	content: "\e6f3";
}
.ti-layout-placeholder:before {
	content: "\e6f4";
}
.ti-layout-menu:before {
	content: "\e6f5";
}
.ti-layout-menu-v:before {
	content: "\e6f6";
}
.ti-layout-menu-separated:before {
	content: "\e6f7";
}
.ti-layout-menu-full:before {
	content: "\e6f8";
}
.ti-layout-media-right-alt:before {
	content: "\e6f9";
}
.ti-layout-media-right:before {
	content: "\e6fa";
}
.ti-layout-media-overlay:before {
	content: "\e6fb";
}
.ti-layout-media-overlay-alt:before {
	content: "\e6fc";
}
.ti-layout-media-overlay-alt-2:before {
	content: "\e6fd";
}
.ti-layout-media-left-alt:before {
	content: "\e6fe";
}
.ti-layout-media-left:before {
	content: "\e6ff";
}
.ti-layout-media-center-alt:before {
	content: "\e700";
}
.ti-layout-media-center:before {
	content: "\e701";
}
.ti-layout-list-thumb:before {
	content: "\e702";
}
.ti-layout-list-thumb-alt:before {
	content: "\e703";
}
.ti-layout-list-post:before {
	content: "\e704";
}
.ti-layout-list-large-image:before {
	content: "\e705";
}
.ti-layout-line-solid:before {
	content: "\e706";
}
.ti-layout-grid4:before {
	content: "\e707";
}
.ti-layout-grid3:before {
	content: "\e708";
}
.ti-layout-grid2:before {
	content: "\e709";
}
.ti-layout-grid2-thumb:before {
	content: "\e70a";
}
.ti-layout-cta-right:before {
	content: "\e70b";
}
.ti-layout-cta-left:before {
	content: "\e70c";
}
.ti-layout-cta-center:before {
	content: "\e70d";
}
.ti-layout-cta-btn-right:before {
	content: "\e70e";
}
.ti-layout-cta-btn-left:before {
	content: "\e70f";
}
.ti-layout-column4:before {
	content: "\e710";
}
.ti-layout-column3:before {
	content: "\e711";
}
.ti-layout-column2:before {
	content: "\e712";
}
.ti-layout-accordion-separated:before {
	content: "\e713";
}
.ti-layout-accordion-merged:before {
	content: "\e714";
}
.ti-layout-accordion-list:before {
	content: "\e715";
}
.ti-ink-pen:before {
	content: "\e716";
}
.ti-info-alt:before {
	content: "\e717";
}
.ti-help-alt:before {
	content: "\e718";
}
.ti-headphone-alt:before {
	content: "\e719";
}
.ti-hand-point-up:before {
	content: "\e71a";
}
.ti-hand-point-right:before {
	content: "\e71b";
}
.ti-hand-point-left:before {
	content: "\e71c";
}
.ti-hand-point-down:before {
	content: "\e71d";
}
.ti-gallery:before {
	content: "\e71e";
}
.ti-face-smile:before {
	content: "\e71f";
}
.ti-face-sad:before {
	content: "\e720";
}
.ti-credit-card:before {
	content: "\e721";
}
.ti-control-skip-forward:before {
	content: "\e722";
}
.ti-control-skip-backward:before {
	content: "\e723";
}
.ti-control-record:before {
	content: "\e724";
}
.ti-control-eject:before {
	content: "\e725";
}
.ti-comments-smiley:before {
	content: "\e726";
}
.ti-brush-alt:before {
	content: "\e727";
}
.ti-youtube:before {
	content: "\e728";
}
.ti-vimeo:before {
	content: "\e729";
}
.ti-twitter:before {
	content: "\e72a";
}
.ti-time:before {
	content: "\e72b";
}
.ti-tumblr:before {
	content: "\e72c";
}
.ti-skype:before {
	content: "\e72d";
}
.ti-share:before {
	content: "\e72e";
}
.ti-share-alt:before {
	content: "\e72f";
}
.ti-rocket:before {
	content: "\e730";
}
.ti-pinterest:before {
	content: "\e731";
}
.ti-new-window:before {
	content: "\e732";
}
.ti-microsoft:before {
	content: "\e733";
}
.ti-list-ol:before {
	content: "\e734";
}
.ti-linkedin:before {
	content: "\e735";
}
.ti-layout-sidebar-2:before {
	content: "\e736";
}
.ti-layout-grid4-alt:before {
	content: "\e737";
}
.ti-layout-grid3-alt:before {
	content: "\e738";
}
.ti-layout-grid2-alt:before {
	content: "\e739";
}
.ti-layout-column4-alt:before {
	content: "\e73a";
}
.ti-layout-column3-alt:before {
	content: "\e73b";
}
.ti-layout-column2-alt:before {
	content: "\e73c";
}
.ti-instagram:before {
	content: "\e73d";
}
.ti-google:before {
	content: "\e73e";
}
.ti-github:before {
	content: "\e73f";
}
.ti-flickr:before {
	content: "\e740";
}
.ti-facebook:before {
	content: "\e741";
}
.ti-dropbox:before {
	content: "\e742";
}
.ti-dribbble:before {
	content: "\e743";
}
.ti-apple:before {
	content: "\e744";
}
.ti-android:before {
	content: "\e745";
}
.ti-save:before {
	content: "\e746";
}
.ti-save-alt:before {
	content: "\e747";
}
.ti-yahoo:before {
	content: "\e748";
}
.ti-wordpress:before {
	content: "\e749";
}
.ti-vimeo-alt:before {
	content: "\e74a";
}
.ti-twitter-alt:before {
	content: "\e74b";
}
.ti-tumblr-alt:before {
	content: "\e74c";
}
.ti-trello:before {
	content: "\e74d";
}
.ti-stack-overflow:before {
	content: "\e74e";
}
.ti-soundcloud:before {
	content: "\e74f";
}
.ti-sharethis:before {
	content: "\e750";
}
.ti-sharethis-alt:before {
	content: "\e751";
}
.ti-reddit:before {
	content: "\e752";
}
.ti-pinterest-alt:before {
	content: "\e753";
}
.ti-microsoft-alt:before {
	content: "\e754";
}
.ti-linux:before {
	content: "\e755";
}
.ti-jsfiddle:before {
	content: "\e756";
}
.ti-joomla:before {
	content: "\e757";
}
.ti-html5:before {
	content: "\e758";
}
.ti-flickr-alt:before {
	content: "\e759";
}
.ti-email:before {
	content: "\e75a";
}
.ti-drupal:before {
	content: "\e75b";
}
.ti-dropbox-alt:before {
	content: "\e75c";
}
.ti-css3:before {
	content: "\e75d";
}
.ti-rss:before {
	content: "\e75e";
}
.ti-rss-alt:before {
	content: "\e75f";
}

body {
  margin: 0;
  padding: 0;
  background-color: #ebebeb !important;
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px !important;
}

.form-membership {
  background: url(../../static/media/bg.78f5154d.png) white;
  background-attachment: fixed;
  background-size: cover;
  padding: 3rem 0;
  height: 100vh;
}

.form-membership .form-wrapper {
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
  width: 430px;
  margin: 50px auto;
  text-align: center;
}

.form-membership .form-wrapper .logo {
  background: url(../../static/media/VentureTel-SMS-Logo.53766e2f.png) no-repeat;
  background-size: 100%;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: absolute;
  right: 0;
  left: 0;
  top: 45px;
  margin: auto;
}

.form-membership .form-wrapper h5 {
  text-align: center;
  margin-bottom: 2rem;
}

.form-membership .form-wrapper form .form-control {
  margin-bottom: 1.5rem;
}

.form-membership .form-wrapper hr {
  margin: 2rem 0;
}

@media screen and (max-width: 576px) {
  .form-membership {
    padding: 20px;
  }

  .form-membership .form-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.custom-control-label {
  line-height: 25px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3db16b;
  border-color: #3db16b;
}

.dark {
  background-color: #272935;
  color: rgba(255, 255, 255, 0.75);
}

.dark .text-muted {
  color: #181C2F;
  margin-right: 10px;
}

.dark nav.navigation {
  background-color: #464852;
  position: fixed;
}

.dark nav.navigation ul li span {
  color: rgba(255, 255, 255, 0.7);
}

.dark nav.navigation ul li span:hover,
.dark nav.navigation ul li span:focus {
  background-color: #5a5c66;
}

.dark .layout .content .sidebar-group .sidebar {
  background-color: #464852;
}

.dark .layout .content .sidebar-group .sidebar .list-group-item .users-list-body p {
  color: rgba(255, 255, 255, 0.4);
}

.dark .layout .content .sidebar-group .sidebar .sidebar-form input[type='text'] {
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
}

.dark .layout .content .sidebar-group .sidebar .sidebar-form input[type='text']::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark .layout .content .sidebar-group .sidebar .sidebar-form input[type='text']::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark .layout .content .chat .chat-header {
  border-bottom-color: #32343e;
}

.dark .layout .content .chat .chat-body .messages .message-item .message-content {
  background-color: #464852;
}

.dark .layout .content .chat .chat-body .messages .message-item.outgoing-message .message-content {
  background-color: rgba(61, 177, 107, 0.3);
}

.dark .layout .content .chat .chat-footer {
  border-top-color: #32343e;
}

.dark .layout .content .chat .chat-footer .chat-footer-form {
  background-color: #464852;
}

.dark .layout .content .chat .chat-footer .chat-footer-form input[type='text'] {
  color: rgba(255, 255, 255, 0.7);
}

.dark .layout .content .chat .chat-footer .chat-footer-form input[type='text']::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark .layout .content .chat .chat-footer .chat-footer-form input[type='text']::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark.rtl .layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action {
  box-shadow: 8px 1px 10px 5px #464852 !important;
}

.dark .list-group-item {
  border-color: #5a5c66;
}

.dark .dropdown-menu {
  background-color: #5a5c66;
  border-color: transparent;
}

.dark .dropdown-menu .dropdown-item {
  color: rgba(255, 255, 255, 0.7);
}

.dark .dropdown-menu .dropdown-item:focus,
.dark .dropdown-menu .dropdown-item:hover,
.dark .dropdown-menu .dropdown-item:active,
.dark .dropdown-menu .dropdown-item.active {
  background-color: #464852;
}

.dark input[type='text'],
.dark textarea,
.dark select {
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
  border-color: #6e707a;
}

.dark input[type='text']:focus,
.dark textarea:focus,
.dark select:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: #5a5c66;
  border-color: #aaacb6;
}

.dark input[type='text']::-webkit-input-placeholder,
.dark textarea::-webkit-input-placeholder,
.dark select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::-webkit-input-placeholder, .dark textarea::-webkit-input-placeholder, .dark select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::placeholder,
.dark textarea::placeholder,
.dark select::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark .custom-control-label::before {
  background-color: #5a5c66;
  border-color: #6e707a;
}

.dark .input-group-text {
  background-color: #464852;
  color: rgba(255, 255, 255, 0.7);
  border-color: #6e707a;
}

.dark .custom-file-label {
  background-color: #5a5c66;
  border-color: #6e707a;
  color: rgba(255, 255, 255, 0.7);
}

.dark .custom-file-label:after {
  background-color: #464852;
  color: rgba(255, 255, 255, 0.7);
}

.dark .btn.btn-light {
  background-color: #5a5c66;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

.dark .btn.btn-light:focus,
.dark .btn.btn-light:hover {
  border-color: transparent !important;
  background-color: #5a5c66 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 102, 0.6) !important;
}

.dark figure.avatar.avatar-state-danger:before,
.dark figure.avatar.avatar-state-dark:before,
.dark figure.avatar.avatar-state-info:before,
.dark figure.avatar.avatar-state-light:before,
.dark figure.avatar.avatar-state-primary:before,
.dark figure.avatar.avatar-state-secondary:before,
.dark figure.avatar.avatar-state-success:before,
.dark figure.avatar.avatar-state-warning:before {
  border-color: #464852;
}

.dark .layout .content .sidebar-group .sidebar .list-group-item:first-child {
  border-top: 1px solid #5a5c66 !important;
}

.dark .layout .content .sidebar-group .sidebar .list-group-item:last-child {
  border-bottom: 1px solid #5a5c66 !important;
}

.dark .nav-tabs .nav-link.active {
  color: #fff;
}

.dark-modal .calendar {
  width: 100%;
  background-position: 95% 50%;
  background-color: white;
  position: relative;
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
}

.dark-modal .calendar:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: #5a5c66;
  border-color: #aaacb6;
}

.dark-modal .calendar::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.light {
  background-color: #ebebeb;
  color: #212529;
  height: 100%;
}

.light nav.navigation {
  background-color: #fff;
  position: absolute;
}

.light nav.navigation ul li span {
  color: #828282;
}

.light nav.navigation ul li span:hover,
.light nav.navigation ul li span:focus {
  color: #3db16b;
}

.light .layout .content .sidebar-group .sidebar {
  background-color: #ebebeb;
}

.light .layout .content .sidebar-group .sidebar .list-group-item .users-list-body p {
  color: #212529;
}

.light .layout .content .sidebar-group .sidebar .sidebar-form input[type='text']::-webkit-input-placeholder {
  color: #828282;
}

.light .layout .content .sidebar-group .sidebar .sidebar-form input[type='text']::placeholder {
  color: #828282;
}

.light .layout .content .chat .chat-header {
  border-bottom-color: #e1e1e4;
}

.light .layout .content .chat .chat-body .messages .incoming-message .message-content {
  background-color: #fff;
  border-radius: 25px;
  color: #1A2028;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 14px 25px;
}
.light .layout .content .chat .chat-body .messages .incoming-message .message-content:before {
  position: absolute;
  background: url(../../static/media/arrow-white.797931c2.svg) no-repeat;
  content: '';
  left: 0;
  bottom: -13px;
  height: 30px;
  width: 30px;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
  background-size: 100% 100%;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);

}

.light .layout .content .chat .chat-body .messages .message-item.outgoing-message .message-content {
  background-color: #5FBC76;
  border-radius: 25px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  padding: 14px 25px;
}
.light .layout .content .chat .chat-body .messages .message-item.outgoing-message .messsage-content-no-background {
  background: none;
}
.light .layout .content .chat .chat-body .messages .message-item.outgoing-message .message-content::before {
  background: url(../../static/media/arrow.c54462d1.svg) no-repeat;
  position: absolute;
  content: '';
  right: 0;
  bottom: -13px;
  height: 30px;
  width: 30px;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  background-size: 100% 100%;
}

.light .layout .content .chat .chat-body .messages .message-item.outgoing-message .messsage-content-no-background::before {
  background: none !important;
}
.light .layout .content .chat .chat-footer {
  border-top-color: #e1e1e1;
}

.light .layout .content .chat .chat-footer .chat-footer-form {
  background: #F1EDED;
  border-radius: 24px;
  margin: 12px 30px;
}

.light .layout .content .chat .chat-footer .chat-footer-form input[type='text'] {
  color: #212529;
}

.light .layout .content .chat .chat-footer .chat-footer-form input[type='text']::-webkit-input-placeholder {
  color: #828282;
}

.light .layout .content .chat .chat-footer .chat-footer-form input[type='text']::placeholder {
  color: #828282;
}

.light .rtl .layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action {
  box-shadow: 8px 1px 10px 5px #464852 !important;
}

.light .list-group-item {
  border-color: #ebebeb;
}

.light .dropdown-menu {
  background-color: #ffffff;
  border-color: transparent;
}

.light .dropdown-menu .dropdown-item {
  color: #212529;
}

.light .dropdown-menu .dropdown-item:focus,
.light .dropdown-menu .dropdown-item:hover,
.light .dropdown-menu .dropdown-item:active,
.light .dropdown-menu .dropdown-item.active {
  background-color: #e6e6e6;
}

.light input[type='text'],
.light textarea,
.light select {
  border-color: #6e707a;
}

.light input[type='text']:focus,
.light textarea:focus,
.light select:focus {
  border-color: #aaacb6;
}

.light input[type='text']::-webkit-input-placeholder,
.light textarea::-webkit-input-placeholder,
.light select::-webkit-input-placeholder {
  color: #828282;
}

.light input[type='text']::-webkit-input-placeholder, .light textarea::-webkit-input-placeholder, .light select::-webkit-input-placeholder {
  color: #828282;
}

.light input[type='text']::placeholder,
.light textarea::placeholder,
.light select::placeholder {
  color: #828282;
}

.light .custom-control-label::before {
  background-color: #fff;
  border-color: #6e707a;
}

.light .input-group-text {
  background-color: #464852;
  border-color: #6e707a;
}
.nav-tabs .nav-link {
  cursor: pointer;
}
.nav-tabs .nav-link.active {
  border-color: #5a5c66;
  border-bottom-color: #464852;
  /* background-color: #464852; */
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #5a5c66;
  border-bottom-color: #464852;
}

.nav-tabs {
  border-bottom-color: #5a5c66;
}

.light .custom-file-label {
  background-color: #5a5c66;
  border-color: #6e707a;
}

.light .custom-file-label:after {
  background-color: #464852;
}

.light .btn.btn-light {
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.light .btn.btn-light:focus,
.light .btn.btn-light:hover {
  border-color: transparent !important;
  background-color: #c8c8c8 !important;
}

.light figure.avatar.avatar-state-danger:before,
.light figure.avatar.avatar-state-light :before,
.light figure.avatar.avatar-state-info:before,
.light figure.avatar.avatar-state-light:before,
.light figure.avatar.avatar-state-primary:before,
.light figure.avatar.avatar-state-secondary:before,
.light figure.avatar.avatar-state-success:before,
.light figure.avatar.avatar-state-warning:before {
  border-color: #e6e6e6;
}

/* .light .layout .content .sidebar-group .sidebar .list-group-item:first-child {
  border-top: 1px solid #ebebeb !important;
}

.light .layout .content .sidebar-group .sidebar .list-group-item:last-child {
  border-bottom: 1px solid #ebebeb !important;
} */

.light .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.light .nav-tabs .nav-link:hover,
.light .nav-tabs .nav-link:focus {
  border-color: #dee2e6;
  border-bottom-color: #dee2e6;
}

.light .nav-tabs {
  border-bottom-color: #dee2e6;
}
.chat-side-header.nav-tabs .nav-link {
  cursor: pointer;
  padding: 0.5rem 0rem;
  margin-right: 15px;
  color: #181C2F;
  opacity: 0.4;
}
.chat-side-header.nav-tabs  {
  border: none;
}
.light .chat-side-header .nav-link:hover,
.light .chat-side-header .nav-link:focus {
  border-color: #dee2e6;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: #dee2e6;
}
.light .chat-side-header .nav-link.active {
  color: #181C2F;
  opacity: 1;
  border: none;
  border-bottom: 1px solid #181c2f !important;
  background-color: #ebebeb;
  border-color: #dee2e6 #dee2e6 #fff;
}

/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.2
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */
/* 2D TRANSITIONS */
/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Shrink */
.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-shrink:hover, .hvr-shrink:focus, .hvr-shrink:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.hvr-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse:hover, .hvr-pulse:focus, .hvr-pulse:active {
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Pulse Grow */
@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.hvr-pulse-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse-grow:hover, .hvr-pulse-grow:focus, .hvr-pulse-grow:active {
  -webkit-animation-name: hvr-pulse-grow;
  animation-name: hvr-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Pulse Shrink */
@-webkit-keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.hvr-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pulse-shrink:hover, .hvr-pulse-shrink:focus, .hvr-pulse-shrink:active {
  -webkit-animation-name: hvr-pulse-shrink;
  animation-name: hvr-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Push */
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.hvr-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-push:hover, .hvr-push:focus, .hvr-push:active {
  -webkit-animation-name: hvr-push;
  animation-name: hvr-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Pop */
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.hvr-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
/* .hvr-pop:hover, .hvr-pop:focus, .hvr-pop:active { */
.hvr-pop, .hvr-pop:focus, .hvr-pop:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Bounce Out */
.hvr-bounce-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.5s;
}
.hvr-bounce-out:hover, .hvr-bounce-out:focus, .hvr-bounce-out:active {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Rotate */
.hvr-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-rotate:hover, .hvr-rotate:focus, .hvr-rotate:active {
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
}

/* Grow Rotate */
.hvr-grow-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-grow-rotate:hover, .hvr-grow-rotate:focus, .hvr-grow-rotate:active {
  -webkit-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg);
}

/* Float */
.hvr-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-float:hover, .hvr-float:focus, .hvr-float:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

/* Sink */
.hvr-sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-sink:hover, .hvr-sink:focus, .hvr-sink:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

/* Bob */
@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
.hvr-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.hvr-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-hang:hover, .hvr-hang:focus, .hvr-hang:active {
  -webkit-animation-name: hvr-hang-sink, hvr-hang;
  animation-name: hvr-hang-sink, hvr-hang;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Skew */
.hvr-skew {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-skew:hover, .hvr-skew:focus, .hvr-skew:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

/* Skew Forward */
.hvr-skew-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-skew-forward:hover, .hvr-skew-forward:focus, .hvr-skew-forward:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
}

/* Skew Backward */
.hvr-skew-backward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-skew-backward:hover, .hvr-skew-backward:focus, .hvr-skew-backward:active {
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
}

/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hvr-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-horizontal:hover, .hvr-wobble-horizontal:focus, .hvr-wobble-horizontal:active {
  -webkit-animation-name: hvr-wobble-horizontal;
  animation-name: hvr-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble To Bottom Right */
@-webkit-keyframes hvr-wobble-to-bottom-right {
  16.65% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  49.95% {
    -webkit-transform: translate(4px, 4px);
    transform: translate(4px, 4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  83.25% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes hvr-wobble-to-bottom-right {
  16.65% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px);
  }
  49.95% {
    -webkit-transform: translate(4px, 4px);
    transform: translate(4px, 4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  83.25% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.hvr-wobble-to-bottom-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-to-bottom-right:hover, .hvr-wobble-to-bottom-right:focus, .hvr-wobble-to-bottom-right:active {
  -webkit-animation-name: hvr-wobble-to-bottom-right;
  animation-name: hvr-wobble-to-bottom-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble To Top Right */
@-webkit-keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
  }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.hvr-wobble-to-top-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-to-top-right:hover, .hvr-wobble-to-top-right:focus, .hvr-wobble-to-top-right:active {
  -webkit-animation-name: hvr-wobble-to-top-right;
  animation-name: hvr-wobble-to-top-right;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Top */
@-webkit-keyframes hvr-wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
@keyframes hvr-wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
.hvr-wobble-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-wobble-top:hover, .hvr-wobble-top:focus, .hvr-wobble-top:active {
  -webkit-animation-name: hvr-wobble-top;
  animation-name: hvr-wobble-top;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Bottom */
@-webkit-keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
@keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
.hvr-wobble-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.hvr-wobble-bottom:hover, .hvr-wobble-bottom:focus, .hvr-wobble-bottom:active {
  -webkit-animation-name: hvr-wobble-bottom;
  animation-name: hvr-wobble-bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Wobble Skew */
@-webkit-keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
@keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg);
  }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
  }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg);
  }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg);
  }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg);
  }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0);
  }
}
.hvr-wobble-skew {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-skew:hover, .hvr-wobble-skew:focus, .hvr-wobble-skew:active {
  -webkit-animation-name: hvr-wobble-skew;
  animation-name: hvr-wobble-skew;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Buzz */
@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
.hvr-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz:hover, .hvr-buzz:focus, .hvr-buzz:active {
  -webkit-animation-name: hvr-buzz;
  animation-name: hvr-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out:hover, .hvr-buzz-out:focus, .hvr-buzz-out:active {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Forward */
.hvr-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-forward:hover, .hvr-forward:focus, .hvr-forward:active {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

/* Backward */
.hvr-backward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-backward:hover, .hvr-backward:focus, .hvr-backward:active {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}

/* BACKGROUND TRANSITIONS */
/* Fade */
.hvr-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  transition-duration: 0.3s;
  transition-property: color, background-color;
}
.hvr-fade:hover, .hvr-fade:focus, .hvr-fade:active {
  background-color: #2098D1;
  color: white;
}

/* Back Pulse */
@-webkit-keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}
@keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}
.hvr-back-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: color, background-color;
}
.hvr-back-pulse:hover, .hvr-back-pulse:focus, .hvr-back-pulse:active {
  -webkit-animation-name: hvr-back-pulse;
  animation-name: hvr-back-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-color: #2098D1;
  background-color: #2098d1;
  color: white;
}

/* Sweep To Right */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: white;
}
.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Sweep To Left */
.hvr-sweep-to-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-sweep-to-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-left:hover, .hvr-sweep-to-left:focus, .hvr-sweep-to-left:active {
  color: white;
}
.hvr-sweep-to-left:hover:before, .hvr-sweep-to-left:focus:before, .hvr-sweep-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Sweep To Bottom */
.hvr-sweep-to-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-sweep-to-bottom:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-bottom:hover, .hvr-sweep-to-bottom:focus, .hvr-sweep-to-bottom:active {
  color: white;
}
.hvr-sweep-to-bottom:hover:before, .hvr-sweep-to-bottom:focus:before, .hvr-sweep-to-bottom:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* Sweep To Top */
.hvr-sweep-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-sweep-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
  color: white;
}
.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* Bounce To Right */
.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
}
.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right:hover, .hvr-bounce-to-right:focus, .hvr-bounce-to-right:active {
  color: white;
}
.hvr-bounce-to-right:hover:before, .hvr-bounce-to-right:focus:before, .hvr-bounce-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Left */
.hvr-bounce-to-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
}
.hvr-bounce-to-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-left:hover, .hvr-bounce-to-left:focus, .hvr-bounce-to-left:active {
  color: white;
}
.hvr-bounce-to-left:hover:before, .hvr-bounce-to-left:focus:before, .hvr-bounce-to-left:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Bottom */
.hvr-bounce-to-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
}
.hvr-bounce-to-bottom:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-bottom:hover, .hvr-bounce-to-bottom:focus, .hvr-bounce-to-bottom:active {
  color: white;
}
.hvr-bounce-to-bottom:hover:before, .hvr-bounce-to-bottom:focus:before, .hvr-bounce-to-bottom:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Bounce To Top */
.hvr-bounce-to-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
}
.hvr-bounce-to-top:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-top:hover, .hvr-bounce-to-top:focus, .hvr-bounce-to-top:active {
  color: white;
}
.hvr-bounce-to-top:hover:before, .hvr-bounce-to-top:focus:before, .hvr-bounce-to-top:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Radial Out */
.hvr-radial-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  background: #e1e1e1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-radial-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-radial-out:hover, .hvr-radial-out:focus, .hvr-radial-out:active {
  color: white;
}
.hvr-radial-out:hover:before, .hvr-radial-out:focus:before, .hvr-radial-out:active:before {
  -webkit-transform: scale(2);
  transform: scale(2);
}

/* Radial In */
.hvr-radial-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  background: #2098D1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-radial-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e1e1e1;
  border-radius: 100%;
  -webkit-transform: scale(2);
  transform: scale(2);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-radial-in:hover, .hvr-radial-in:focus, .hvr-radial-in:active {
  color: white;
}
.hvr-radial-in:hover:before, .hvr-radial-in:focus:before, .hvr-radial-in:active:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

/* Rectangle In */
.hvr-rectangle-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #2098D1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-rectangle-in:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e1e1e1;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-rectangle-in:hover, .hvr-rectangle-in:focus, .hvr-rectangle-in:active {
  color: white;
}
.hvr-rectangle-in:hover:before, .hvr-rectangle-in:focus:before, .hvr-rectangle-in:active:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

/* Rectangle Out */
.hvr-rectangle-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-rectangle-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
  color: white;
}
.hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Shutter In Horizontal */
.hvr-shutter-in-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #2098D1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-shutter-in-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e1e1e1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-shutter-in-horizontal:hover, .hvr-shutter-in-horizontal:focus, .hvr-shutter-in-horizontal:active {
  color: white;
}
.hvr-shutter-in-horizontal:hover:before, .hvr-shutter-in-horizontal:focus:before, .hvr-shutter-in-horizontal:active:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

/* Shutter Out Horizontal */
.hvr-shutter-out-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-shutter-out-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-horizontal:hover, .hvr-shutter-out-horizontal:focus, .hvr-shutter-out-horizontal:active {
  color: white;
}
.hvr-shutter-out-horizontal:hover:before, .hvr-shutter-out-horizontal:focus:before, .hvr-shutter-out-horizontal:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Shutter In Vertical */
.hvr-shutter-in-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #2098D1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-shutter-in-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e1e1e1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-shutter-in-vertical:hover, .hvr-shutter-in-vertical:focus, .hvr-shutter-in-vertical:active {
  color: white;
}
.hvr-shutter-in-vertical:hover:before, .hvr-shutter-in-vertical:focus:before, .hvr-shutter-in-vertical:active:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

/* Shutter Out Vertical */
.hvr-shutter-out-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #e1e1e1;
  transition-property: color;
  transition-duration: 0.3s;
}
.hvr-shutter-out-vertical:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2098D1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-shutter-out-vertical:hover, .hvr-shutter-out-vertical:focus, .hvr-shutter-out-vertical:active {
  color: white;
}
.hvr-shutter-out-vertical:hover:before, .hvr-shutter-out-vertical:focus:before, .hvr-shutter-out-vertical:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* BORDER TRANSITIONS */
/* Border Fade */
.hvr-border-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hvr-border-fade:hover, .hvr-border-fade:focus, .hvr-border-fade:active {
  box-shadow: inset 0 0 0 4px #2098D1, 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}

/* Hollow */
.hvr-hollow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: background;
  box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hvr-hollow:hover, .hvr-hollow:focus, .hvr-hollow:active {
  background: none;
}

/* Trim */
.hvr-trim {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-trim:before {
  content: '';
  position: absolute;
  border: white solid 4px;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.hvr-trim:hover:before, .hvr-trim:focus:before, .hvr-trim:active:before {
  opacity: 1;
}

/* Ripple Out */
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
.hvr-ripple-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-ripple-out:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.hvr-ripple-out:hover:before, .hvr-ripple-out:focus:before, .hvr-ripple-out:active:before {
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
}

/* Ripple In */
@-webkit-keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
@keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
.hvr-ripple-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-ripple-in:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 4px;
  top: -12px;
  right: -12px;
  bottom: -12px;
  left: -12px;
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.hvr-ripple-in:hover:before, .hvr-ripple-in:focus:before, .hvr-ripple-in:active:before {
  -webkit-animation-name: hvr-ripple-in;
  animation-name: hvr-ripple-in;
}

/* Outline Out */
.hvr-outline-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-outline-out:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-duration: 0.3s;
  transition-property: top, right, bottom, left;
}
.hvr-outline-out:hover:before, .hvr-outline-out:focus:before, .hvr-outline-out:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

/* Outline In */
.hvr-outline-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-outline-in:before {
  pointer-events: none;
  content: '';
  position: absolute;
  border: #e1e1e1 solid 4px;
  top: -16px;
  right: -16px;
  bottom: -16px;
  left: -16px;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: top, right, bottom, left;
}
.hvr-outline-in:hover:before, .hvr-outline-in:focus:before, .hvr-outline-in:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  opacity: 1;
}

/* Round Corners */
.hvr-round-corners {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: border-radius;
}
.hvr-round-corners:hover, .hvr-round-corners:focus, .hvr-round-corners:active {
  border-radius: 1em;
}

/* Underline From Left */
.hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-from-left:hover:before, .hvr-underline-from-left:focus:before, .hvr-underline-from-left:active:before {
  right: 0;
}

/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

/* Underline From Right */
.hvr-underline-from-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: left;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-from-right:hover:before, .hvr-underline-from-right:focus:before, .hvr-underline-from-right:active:before {
  left: 0;
}

/* Overline From Left */
.hvr-overline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  top: 0;
  background: #2098D1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-overline-from-left:hover:before, .hvr-overline-from-left:focus:before, .hvr-overline-from-left:active:before {
  right: 0;
}

/* Overline From Center */
.hvr-overline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  top: 0;
  background: #2098D1;
  height: 4px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-overline-from-center:hover:before, .hvr-overline-from-center:focus:before, .hvr-overline-from-center:active:before {
  left: 0;
  right: 0;
}

/* Overline From Right */
.hvr-overline-from-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-from-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  top: 0;
  background: #2098D1;
  height: 4px;
  transition-property: left;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-overline-from-right:hover:before, .hvr-overline-from-right:focus:before, .hvr-overline-from-right:active:before {
  left: 0;
}

/* Reveal */
.hvr-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-color: #2098D1;
  border-style: solid;
  border-width: 0;
  transition-property: border-width;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
.hvr-reveal:hover:before, .hvr-reveal:focus:before, .hvr-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border-width: 4px;
}

/* Underline Reveal */
.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* Overline Reveal */
.hvr-overline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-overline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  background: #2098D1;
  height: 4px;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-overline-reveal:hover:before, .hvr-overline-reveal:focus:before, .hvr-overline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* SHADOW/GLOW TRANSITIONS */
/* Glow */
.hvr-glow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow;
}
.hvr-glow:hover, .hvr-glow:focus, .hvr-glow:active {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

/* Shadow */
.hvr-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow;
}
.hvr-shadow:hover, .hvr-shadow:focus, .hvr-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
}

/* Grow Shadow */
.hvr-grow-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, transform;
  transition-property: box-shadow, transform, -webkit-transform;
}
.hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Box Shadow Outset */
.hvr-box-shadow-outset {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow;
}
.hvr-box-shadow-outset:hover, .hvr-box-shadow-outset:focus, .hvr-box-shadow-outset:active {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

/* Box Shadow Inset */
.hvr-box-shadow-inset {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: box-shadow;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}
.hvr-box-shadow-inset:hover, .hvr-box-shadow-inset:focus, .hvr-box-shadow-inset:active {
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
  /* Hack to improve aliasing on mobile/tablet devices */
}

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  transition-duration: 0.3s;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

/* Shadow Radial */
.hvr-shadow-radial {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-shadow-radial:before, .hvr-shadow-radial:after {
  pointer-events: none;
  position: absolute;
  content: '';
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  height: 5px;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.hvr-shadow-radial:before {
  bottom: 100%;
  background: radial-gradient(ellipse at 50% 150%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%);
}
.hvr-shadow-radial:after {
  top: 100%;
  background: radial-gradient(ellipse at 50% -50%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%);
}
.hvr-shadow-radial:hover:before, .hvr-shadow-radial:focus:before, .hvr-shadow-radial:active:before, .hvr-shadow-radial:hover:after, .hvr-shadow-radial:focus:after, .hvr-shadow-radial:active:after {
  opacity: 1;
}

/* SPEECH BUBBLES */
/* Bubble Top */
.hvr-bubble-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-top:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  left: calc(50% - 10px);
  top: 0;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e1e1e1 transparent;
}
.hvr-bubble-top:hover:before, .hvr-bubble-top:focus:before, .hvr-bubble-top:active:before {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* Bubble Right */
.hvr-bubble-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-right:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  top: calc(50% - 10px);
  right: 0;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #e1e1e1;
}
.hvr-bubble-right:hover:before, .hvr-bubble-right:focus:before, .hvr-bubble-right:active:before {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

/* Bubble Bottom */
.hvr-bubble-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-bottom:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  left: calc(50% - 10px);
  bottom: 0;
  border-width: 10px 10px 0 10px;
  border-color: #e1e1e1 transparent transparent transparent;
}
.hvr-bubble-bottom:hover:before, .hvr-bubble-bottom:focus:before, .hvr-bubble-bottom:active:before {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

/* Bubble Left */
.hvr-bubble-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-bubble-left:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  border-style: solid;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  top: calc(50% - 10px);
  left: 0;
  border-width: 10px 10px 10px 0;
  border-color: transparent #e1e1e1 transparent transparent;
}
.hvr-bubble-left:hover:before, .hvr-bubble-left:focus:before, .hvr-bubble-left:active:before {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

/* Bubble Float Top */
.hvr-bubble-float-top {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-top:before {
  position: absolute;
  z-index: -1;
  content: '';
  left: calc(50% - 10px);
  top: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e1e1e1 transparent;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-top:hover, .hvr-bubble-float-top:focus, .hvr-bubble-float-top:active {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}
.hvr-bubble-float-top:hover:before, .hvr-bubble-float-top:focus:before, .hvr-bubble-float-top:active:before {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/* Bubble Float Right */
.hvr-bubble-float-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-right:before {
  position: absolute;
  z-index: -1;
  top: calc(50% - 10px);
  right: 0;
  content: '';
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #e1e1e1;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-right:hover, .hvr-bubble-float-right:focus, .hvr-bubble-float-right:active {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}
.hvr-bubble-float-right:hover:before, .hvr-bubble-float-right:focus:before, .hvr-bubble-float-right:active:before {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

/* Bubble Float Bottom */
.hvr-bubble-float-bottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-bottom:before {
  position: absolute;
  z-index: -1;
  content: '';
  left: calc(50% - 10px);
  bottom: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #e1e1e1 transparent transparent transparent;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-bottom:hover, .hvr-bubble-float-bottom:focus, .hvr-bubble-float-bottom:active {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.hvr-bubble-float-bottom:hover:before, .hvr-bubble-float-bottom:focus:before, .hvr-bubble-float-bottom:active:before {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

/* Bubble Float Left */
.hvr-bubble-float-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-left:before {
  position: absolute;
  z-index: -1;
  content: '';
  top: calc(50% - 10px);
  left: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #e1e1e1 transparent transparent;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-bubble-float-left:hover, .hvr-bubble-float-left:focus, .hvr-bubble-float-left:active {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.hvr-bubble-float-left:hover:before, .hvr-bubble-float-left:focus:before, .hvr-bubble-float-left:active:before {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

/* ICONS */
/* Icon Back */
.hvr-icon-back {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.1s;
}
.hvr-icon-back .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.1s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-back:hover .hvr-icon, .hvr-icon-back:focus .hvr-icon, .hvr-icon-back:active .hvr-icon {
  -webkit-transform: translateX(-4px);
  transform: translateX(-4px);
}

/* Icon Forward */
.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.1s;
}
.hvr-icon-forward .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.1s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-forward:hover .hvr-icon, .hvr-icon-forward:focus .hvr-icon, .hvr-icon-forward:active .hvr-icon {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}

/* Icon Down */
@-webkit-keyframes hvr-icon-down {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@keyframes hvr-icon-down {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
/* Icon Down */
.hvr-icon-down {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-down .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-down:hover .hvr-icon, .hvr-icon-down:focus .hvr-icon, .hvr-icon-down:active .hvr-icon {
  -webkit-animation-name: hvr-icon-down;
  animation-name: hvr-icon-down;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Up */
@-webkit-keyframes hvr-icon-up {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-up {
  0%,
	50%,
	100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
	75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
/* Icon Up */
.hvr-icon-up {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-up .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-up:hover .hvr-icon, .hvr-icon-up:focus .hvr-icon, .hvr-icon-up:active .hvr-icon {
  -webkit-animation-name: hvr-icon-up;
  animation-name: hvr-icon-up;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Spin */
.hvr-icon-spin {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-spin .hvr-icon {
  transition-duration: 1s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-in-out;
}
.hvr-icon-spin:hover .hvr-icon, .hvr-icon-spin:focus .hvr-icon, .hvr-icon-spin:active .hvr-icon {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

/* Icon Drop */
@-webkit-keyframes hvr-icon-drop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51%,
	100% {
    opacity: 1;
  }
}
@keyframes hvr-icon-drop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51%,
	100% {
    opacity: 1;
  }
}
/* Icon Drop */
.hvr-icon-drop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-drop .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-drop:hover .hvr-icon, .hvr-icon-drop:focus .hvr-icon, .hvr-icon-drop:active .hvr-icon {
  opacity: 0;
  transition-duration: 0.3s;
  -webkit-animation-name: hvr-icon-drop;
  animation-name: hvr-icon-drop;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Icon Fade */
.hvr-icon-fade {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-fade .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.5s;
  transition-property: color;
}
.hvr-icon-fade:hover .hvr-icon, .hvr-icon-fade:focus .hvr-icon, .hvr-icon-fade:active .hvr-icon {
  color: #0F9E5E;
}

/* Icon Float Away */
@-webkit-keyframes hvr-icon-float-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1em);
    transform: translateY(-1em);
  }
}
@keyframes hvr-icon-float-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1em);
    transform: translateY(-1em);
  }
}
/* Icon Float Away */
.hvr-icon-float-away {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-float-away .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.hvr-icon-float-away:hover .hvr-icon, .hvr-icon-float-away:focus .hvr-icon, .hvr-icon-float-away:active .hvr-icon {
  -webkit-animation-name: hvr-icon-float-away;
  animation-name: hvr-icon-float-away;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Sink Away */
@-webkit-keyframes hvr-icon-sink-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1em);
    transform: translateY(1em);
  }
}
@keyframes hvr-icon-sink-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1em);
    transform: translateY(1em);
  }
}
/* Icon Sink Away */
.hvr-icon-sink-away {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-sink-away .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.hvr-icon-sink-away:hover .hvr-icon, .hvr-icon-sink-away:focus .hvr-icon, .hvr-icon-sink-away:active .hvr-icon {
  -webkit-animation-name: hvr-icon-sink-away;
  animation-name: hvr-icon-sink-away;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/* Icon Grow */
.hvr-icon-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-grow .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-grow:hover .hvr-icon, .hvr-icon-grow:focus .hvr-icon, .hvr-icon-grow:active .hvr-icon {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}

/* Icon Shrink */
.hvr-icon-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-shrink:hover .hvr-icon, .hvr-icon-shrink:focus .hvr-icon, .hvr-icon-shrink:active .hvr-icon {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

/* Icon Pulse */
@-webkit-keyframes hvr-icon-pulse {
  25% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes hvr-icon-pulse {
  25% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.hvr-icon-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-pulse .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-timing-function: ease-out;
}
.hvr-icon-pulse:hover .hvr-icon, .hvr-icon-pulse:focus .hvr-icon, .hvr-icon-pulse:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse;
  animation-name: hvr-icon-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Icon Pulse Grow */
@-webkit-keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
@keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
.hvr-icon-pulse-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-pulse-grow .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-timing-function: ease-out;
}
.hvr-icon-pulse-grow:hover .hvr-icon, .hvr-icon-pulse-grow:focus .hvr-icon, .hvr-icon-pulse-grow:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-grow;
  animation-name: hvr-icon-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Icon Pulse Shrink */
@-webkit-keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.hvr-icon-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-icon-pulse-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-timing-function: ease-out;
}
.hvr-icon-pulse-shrink:hover .hvr-icon, .hvr-icon-pulse-shrink:focus .hvr-icon, .hvr-icon-pulse-shrink:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-shrink;
  animation-name: hvr-icon-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

/* Icon Push */
@-webkit-keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.hvr-icon-push {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-push .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-push:hover .hvr-icon, .hvr-icon-push:focus .hvr-icon, .hvr-icon-push:active .hvr-icon {
  -webkit-animation-name: hvr-icon-push;
  animation-name: hvr-icon-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Pop */
@-webkit-keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
.hvr-icon-pop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-pop .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-pop:hover .hvr-icon, .hvr-icon-pop:focus .hvr-icon, .hvr-icon-pop:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pop;
  animation-name: hvr-icon-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Bounce */
.hvr-icon-bounce {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-bounce .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-bounce:hover .hvr-icon, .hvr-icon-bounce:focus .hvr-icon, .hvr-icon-bounce:active .hvr-icon {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Icon Rotate */
.hvr-icon-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-rotate .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-rotate:hover .hvr-icon, .hvr-icon-rotate:focus .hvr-icon, .hvr-icon-rotate:active .hvr-icon {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

/* Icon Grow Rotate */
.hvr-icon-grow-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-grow-rotate .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-grow-rotate:hover .hvr-icon, .hvr-icon-grow-rotate:focus .hvr-icon, .hvr-icon-grow-rotate:active .hvr-icon {
  -webkit-transform: scale(1.5) rotate(12deg);
  transform: scale(1.5) rotate(12deg);
}

/* Icon Float */
.hvr-icon-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-float .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-float:hover .hvr-icon, .hvr-icon-float:focus .hvr-icon, .hvr-icon-float:active .hvr-icon {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

/* Icon Sink */
.hvr-icon-sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-sink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-icon-sink:hover .hvr-icon, .hvr-icon-sink:focus .hvr-icon, .hvr-icon-sink:active .hvr-icon {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}

/* Icon Bob */
@-webkit-keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@-webkit-keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
@keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
.hvr-icon-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-bob .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-bob:hover .hvr-icon, .hvr-icon-bob:focus .hvr-icon, .hvr-icon-bob:active .hvr-icon {
  -webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
  animation-name: hvr-icon-bob-float, hvr-icon-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Icon Hang */
@-webkit-keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
}
.hvr-icon-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-hang .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-hang:hover .hvr-icon, .hvr-icon-hang:focus .hvr-icon, .hvr-icon-hang:active .hvr-icon {
  -webkit-animation-name: hvr-icon-hang-sink, hvr-icon-hang;
  animation-name: hvr-icon-hang-sink, hvr-icon-hang;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* Icon Wobble Horizontal */
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.hvr-icon-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-wobble-horizontal .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-wobble-horizontal:hover .hvr-icon, .hvr-icon-wobble-horizontal:focus .hvr-icon, .hvr-icon-wobble-horizontal:active .hvr-icon {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Wobble Vertical */
@-webkit-keyframes hvr-icon-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  33.3% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes hvr-icon-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
  }
  33.3% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.hvr-icon-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-wobble-vertical .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-wobble-vertical:hover .hvr-icon, .hvr-icon-wobble-vertical:focus .hvr-icon, .hvr-icon-wobble-vertical:active .hvr-icon {
  -webkit-animation-name: hvr-icon-wobble-vertical;
  animation-name: hvr-icon-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* Icon Buzz */
@-webkit-keyframes hvr-icon-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes hvr-icon-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
.hvr-icon-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-buzz .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-buzz:hover .hvr-icon, .hvr-icon-buzz:focus .hvr-icon, .hvr-icon-buzz:active .hvr-icon {
  -webkit-animation-name: hvr-icon-buzz;
  animation-name: hvr-icon-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Icon Buzz Out */
@-webkit-keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-icon-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
}
.hvr-icon-buzz-out .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.hvr-icon-buzz-out:hover .hvr-icon, .hvr-icon-buzz-out:focus .hvr-icon, .hvr-icon-buzz-out:active .hvr-icon {
  -webkit-animation-name: hvr-icon-buzz-out;
  animation-name: hvr-icon-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

/* CURLS */
/* Curl Top Left */
.hvr-curl-top-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-top-left:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(135deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#000000');
  /*For IE7-8-9*/
  z-index: 1000;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  transition-duration: 0.3s;
  transition-property: width, height;
}
.hvr-curl-top-left:hover:before, .hvr-curl-top-left:focus:before, .hvr-curl-top-left:active:before {
  width: 25px;
  height: 25px;
}

/* Curl Top Right */
.hvr-curl-top-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-top-right:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  top: 0;
  right: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(225deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
  transition-duration: 0.3s;
  transition-property: width, height;
}
.hvr-curl-top-right:hover:before, .hvr-curl-top-right:focus:before, .hvr-curl-top-right:active:before {
  width: 25px;
  height: 25px;
}

/* Curl Bottom Right */
.hvr-curl-bottom-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-bottom-right:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  bottom: 0;
  right: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(315deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.4);
  transition-duration: 0.3s;
  transition-property: width, height;
}
.hvr-curl-bottom-right:hover:before, .hvr-curl-bottom-right:focus:before, .hvr-curl-bottom-right:active:before {
  width: 25px;
  height: 25px;
}

/* Curl Bottom Left */
.hvr-curl-bottom-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-curl-bottom-left:before {
  pointer-events: none;
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  bottom: 0;
  left: 0;
  background: white;
  /* IE9 */
  background: linear-gradient(45deg, white 45%, #aaa 50%, #ccc 56%, white 80%);
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.4);
  transition-duration: 0.3s;
  transition-property: width, height;
}
.hvr-curl-bottom-left:hover:before, .hvr-curl-bottom-left:focus:before, .hvr-curl-bottom-left:active:before {
  width: 25px;
  height: 25px;
}

/*# sourceMappingURL=hover.css.map */
/* .quick-call-modal {
  width: 50vw;
  max-width: 50vw;
} */

.custom-outer-menu {
  box-shadow: 3px 3px 7px 1px #aaaaaa;
  padding: 3px 0;
  border-radius: 6px;
  z-index: 100000;
}
.custom-outer {
  box-shadow: 3px 3px 7px 1px #aaaaaa;
  padding: 3px 0;
}

.custom-outer-menu.dropdown-menu .dropdown-item {
  color: #181C2F;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 7px;
}

.custom-outer-menu.dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}

.quick-call-modal .modal-content {
  overflow: visible !important;
}

.important-msg-count {
  position: absolute;
  top: 162px;
  padding: 0 !important;
  width: 15px;
  height: 15px;
  left: 221px;
}

.important-count {
  position: absolute;
  top: 162px;
  padding: 0 !important;
  width: 15px;
  height: 15px;
  left: 215px;
}

.unread-msg-count {
  position: absolute;
  top: 162px;
  padding: 0 !important;
  width: 15px;
  height: 15px;
  left: 97px;
}
.assigned-msg-count {
  position: absolute;
  top: 162px;
  padding: 0 !important;
  width: 15px;
  height: 15px;
  left: 323px;
}

.conversation-font-size {
  font-size: 40px;
}
.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action {
  display: flex;
  align-items: flex-start;
}

.layout {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  position: relative;
}

.layout .content {
  padding: 0px;
  display: flex;
  flex: 1 1;
  margin-left: 70px;
  height: calc(100vh - 67px);
  background-color: #F2F2F2;
  margin-top: 67px;
}

.layout .content .sidebar-group {
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
  padding: 30px;
}

.layout .content .sidebar-group .sidebar {
  margin-right: 0px;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  width: 330px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.layout .content .sidebar-group .sidebar:not(.active) {
  display: none;
}

.layout .content .sidebar-group .sidebar .nav-tabs {
  padding-left: 0px;
  display: flex;
  justify-content: space-around;
}

.layout .content .sidebar-group .sidebar > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* margin-top: 18px; */
  height: 80px;
  font-weight: 700;
  border-radius: 0;
}

.layout .content .sidebar-group .sidebar > header > span {
  font-size: 22px;
}

.layout .content .sidebar-group .sidebar > button .close {
  position: absolute;
  display: none;
}

.layout .content .contact-details .sidebar .close {
  float: right;
  font-size: 2rem !important;
  font-weight: bolder;
  line-height: 2;
  background-color: #fff !important;
  color: #c3bdb5;
  position: absolute;
  right: 50px;
}

.about-avatar {
  height: 160px !important;
  width: 160px !important;
}

.layout .content .sidebar-group .sidebar > button .btn {
  position: absolute;
}

.layout .content .sidebar-group .sidebar > header ul {
  margin-bottom: 0;
}

.layout .content .sidebar-group .sidebar > header ul li {
  margin-bottom: 0;
}

.layout .content .sidebar-group .sidebar .sidebar-form {
  padding: 10px 20px;
}

.layout .content .sidebar-group .sidebar .sidebar-form input[type='text'] {
  border-radius: 5px;
  border: none;
  height: auto;
  padding: 10px 15px;
  background-color: #ebebeb;
}

.layout .content .sidebar-group .sidebar .sidebar-form input[type='text']:focus {
  box-shadow: none;
}

.layout .content .sidebar-group .sidebar .sidebar-body {
  flex: 1 1;
  overflow: hidden;
  height: 100%;
}

.layout .content .sidebar-group .sidebar .list-group-item {
  padding: 27px 25px;
  display: flex;
  cursor: pointer;
  height: auto;
}

.layout .content .sidebar-group .sidebar .list-group-item:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  color: #fff;
  background-color: #292F4C;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
}
.layout .content .sidebar-group .sidebar .list-group-item.open-chat {
  color: #fff;
  border-radius: 6px;
  border-left: 3px solid #5fbc76 !important;
}

.layout .content .sidebar-group .sidebar .list-group-item.open-chat:before {
  opacity: 1;
}

.layout .content .sidebar-group .sidebar .list-group-item.open-chat .users-list-body p {
  font-weight: 600;
  color: #646464;
}

.layout .content .sidebar-group .sidebar .list-group-item figure {
  margin-right: 15px;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body {
  flex: 1 1;
  position: relative;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.2rem;
  line-height: 1;
}
.layout .content .sidebar-group .sidebar .list-group-item .users-list-body h6 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.5rem;
  font-style: normal;
  color: #666666;
}
.layout .content .sidebar-group .sidebar .list-group-item:hover .users-list-body h6 {
  font-style: normal;
  color: #fff;
  mix-blend-mode: normal;
  opacity: 0.5;
}
.layout .content .sidebar-group .sidebar .list-group-item.open-chat .users-list-body h6 {
  color: #fff;
  mix-blend-mode: normal;
  opacity: 0.5;
}
.layout .content .sidebar-group .sidebar .list-group-item .users-list-body p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #969696;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 5px;
}

.layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  .users-list-action
  [data-toggle='dropdown']
  i {
  font-size: 18px;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action .new-message-count {
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 13px;
  height: 23px;
  background-color: #3db16b;
  color: white;
  border-radius: 50%;
  margin-top: 35px;
  margin-right: 10px;
}

.layout .list-group-item .users-list-body .users-list-action .action-toggle .dropdown .btn {
  color: #5fbc76;
  border: none;
  background: none;
  padding-top: 0;
  outline: none !important;
  box-shadow: none !important;
}
.layout .list-group-item.open-chat .users-list-body .users-list-action .action-toggle .dropdown .btn {
  border-color: #292F4C;
  color: #fff;
}

.layout .list-group-item:hover .users-list-body .users-list-action .action-toggle {
  display: block;
}

.layout .list-group-item:hover .users-list-body .users-list-action .action-toggle .dropdown > button {
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
  outline: none;
}
.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-msg {
  list-style: none;
}
.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .msg-list {
  padding-left: 0px;
}

.layout .content .chat {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 3px;
}

.layout .content .chat .chat-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 20px;
}

.layout .content .chat .chat-header .chat-header-user {
  display: flex;
  align-items: center;
}

.layout .content .chat .chat-header .chat-header-user figure {
  margin-right: 1rem;
  position: relative;
}
.layout .content .chat .chat-header .chat-header-user h5 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}
.layout .content .chat .chat-header .chat-header-user figure .chat-user-image label {
  position: absolute;
  top: 3px;
  right: 2px;
  font-size: 16px;
  display: none;
}
.layout .content .chat .chat-header .chat-header-user figure .chat-user-image label i {
  color: #3db16b;
  font-size: 16px;
  border: 1px solid #3db16b;
}
.layout .content .chat .chat-header .chat-header-user figure:hover .chat-user-image label {
  display: block;
}
.layout .content .chat .chat-header .chat-header-action {
  display: flex;
  align-items: center;
}

.layout .content .chat .chat-header .chat-header-action ul {
  margin-bottom: 0;
}

.layout .content .chat .chat-header .chat-header-action ul > li > span {
  font-size: 16px;
}

.layout .content .chat .chat-body {
  flex: 1 1;
  margin: 10px 0;
}

.layout .content .chat .chat-body:not(.no-message) {
  overflow: hidden;
}

.layout .content .chat .chat-body.no-message {
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
}

.layout .content .chat .chat-body.no-message .no-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.layout .content .chat .chat-body.no-message .no-message-container i {
  font-size: 7em;
  opacity: 0.15;
  margin-bottom: 15px;
}

.layout .content .chat .chat-body.no-message .no-message-container p {
  font-size: 16px;
  color: #828282;
}

.layout .content .chat .chat-body .messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px 0 10px;
  position: relative;
}

.layout .content .chat .chat-body .messages .message-item {
  max-width: 75%;
  margin-bottom: 20px;
}

.layout .content .chat .chat-body .messages .message-item .message-content {
  background: white;
  border-radius: 5px;
  padding: 12px;
  width: 100%;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file .file-icon {
  font-size: 24px;
  margin-right: 1rem;
  color: #828282;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file ul {
  margin-top: 3px;
  margin-bottom: 0;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file ul li.list-inline-item {
  margin-left: 0;
  margin-right: 0.5rem;
}

.layout .content .chat .chat-body .messages .incoming-message .message-action {
  color: #828282;
  margin-top: 5px;
  font-style: normal;
  font-size: 12px;
  margin-left: 94px;
  color: #1A2028;
  mix-blend-mode: normal;
  opacity: 0.3;
}

.layout .content .chat .chat-body .messages .message-item .message-action .sender {
  font-style: normal;
  margin-right: 10px;
  font-size: 12px;
  line-height: 12px;
  color: #1A2028;
  padding-top: 2px;
}
.layout .content .chat .chat-body .messages .message-item .message-action .ti-double-check:before,
.layout .content .chat .chat-body .messages .message-item .message-action .ti-double-check:after {
  content: '\e64c';
  font-family: 'themify';
}

.layout .content .chat .chat-body .messages .message-item .message-action .ti-double-check:after {
  margin-left: -4px;
}

.layout .content .chat .chat-body .messages .message-item.outgoing-message {
  display: flex;
  align-self: flex-end;
  width: 100%;
  justify-content: flex-end;
  position: relative;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-row {
  display: flex;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-row .user-image {
  border-radius: 100%;
  width: 52px;
  height: 52px;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-row .message-text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-row .user-image img.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-row .user-image img.robot {
  object-fit: cover;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-content {
  width: 100%;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-content.schedule-message {
  position: relative;
  background-color: #97e0e0;
  padding-right: 45px;
}

.light .layout .content .chat .chat-body .messages .message-item.outgoing-message .schedule-message:before {
  background: url(../../static/media/arrow-blue.d8899900.svg) no-repeat !important;
  position: absolute;
  content: '';
  right: 6px;
  bottom: -19px;
  height: 30px;
  width: 30px;
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg);
}

.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-action {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  font-size: 12px;
  color: #1A2028;
  padding-top: 3px;
}
.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-action p {
  opacity: 0.3;
  margin-bottom: 0;
}

.layout .content .chat .chat-footer {
  border-top: 2px solid #e1e1e1;
  padding-top: 15px;
}

.layout .content .chat .chat-footer .chat-footer-form {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  background-color: white;
}

.layout .content .chat .chat-footer .chat-footer-form {
  justify-content: space-between;
  word-break: break-all;
}

.layout .content .chat .chat-footer .chat-footer-form .form-buttons {
  display: flex;
}

.layout .content .chat .chat-footer .chat-footer-form .form-buttons .btn {
  margin-left: 0.5rem;
}

.layout .content .chat .chat-footer .chat-footer-form input[type='text'], .layout .content .chat .chat-footer .chat-footer-form input {
  border: none;
  background-color: inherit;
  resize: none;
}

.layout .content .chat .chat-footer .chat-footer-form input[type='text']:focus, .layout .content .chat .chat-footer .chat-footer-form input:focus {
  box-shadow: none;
}

nav.navigation {
  background: white;
}

nav.navigation .nav-group {
  width: 70px;
  min-height: 617px;
}

nav.navigation ul {
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

nav.navigation ul li {
  list-style: none;
}
nav.navigation ul li span {
  display: block;
  text-align: center;
  padding: 15px 0;
  color: #828282;
  position: relative;
  transition: all 0.3s;
}

nav.navigation ul li span.notifiy_badge:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: #ff7373;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

nav.navigation ul li span.active {
  color: #3db16b;
}

nav.navigation ul li span.logo {
  margin-bottom: 1rem;
  /* background-color: #3db16b; */
  background: url(../../static/media/VentureTel-SMS-Logo.f3c59019.jpg) no-repeat;
  background-size: 100%;
  height: 70px;
  cursor: pointer;
}

nav.navigation ul li span.logo:hover,
nav.navigation ul li span.logo:focus {
  background-color: #47bb75;
}

nav.navigation ul li span:hover,
nav.navigation ul li span:focus {
  color: #3db16b;
}

nav.navigation ul li span i {
  font-size: 19px;
}

nav.navigation ul li.brackets {
  flex: 1 1;
}

@media screen and (min-width: 993px) {
  /* .sidebar-group {
    opacity: 1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    visibility: visible;
  }
  .sidebar-group.open {
    display: none !important;
    transition: opacity 1s ease-out;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
  } */
  .nav-menu {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .nav-menu {
    display: block;
  }

  .sidebar-group {
    position: fixed;
    right: -50%;
    top: 0;
    bottom: 0;
    width: 40%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 25px 0px 30px 5px black;
    visibility: hidden;
  }

  .sidebar-group.open {
    z-index: 1;
    right: 0;
    opacity: 1;
    transition: all 0.5s;
    visibility: visible;
  }

  .sidebar-group .sidebar {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
  }

  .sidebar-group .sidebar > button {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 17px;
    opacity: 1 !important;
  }

  .sidebar-group.mobile-open {
    opacity: 1;
    right: 0;
    visibility: visible;
  }
}

@media screen and (max-width: 768px) {
  .sidebar-group {
    width: 60%;
  }

  .sidebar-group .sidebar .list-group-item .users-list-body h5 {
    font-size: 21px !important;
  }

  .layout .content {
    padding: 20px !important;
  }

  .layout .content .chat .chat-header {
    display: block !important;
    padding-bottom: 15px !important;
  }

  .layout .content .chat .chat-header .chat-header-user h5 {
    font-size: 19px !important;
  }

  .layout .content .chat .chat-header .chat-header-action {
    margin-top: 15px;
  }

  .layout .content .chat .chat-body .messages .message-item {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .sidebar-group {
    width: 70%;
  }
}

.list-group-item {
  background-color: inherit;
  border-color: #ebebeb;
  border: none !important;
}
.list-group-item:hover {
  background: #292F4C;
  color: #fff;
  border-radius: 6px;
}
ul.list-inline .list-inline-item {
  margin-bottom: 0.5rem;
}

ul.list-inline .list-inline-item:not(:last-child) {
  margin-right: 0;
}

ul.list-inline:not(.social-links) .list-inline-item {
  margin-left: 0.5rem;
}

ul.list-inline.social-links .list-inline-item {
  margin-right: 0.5rem;
  margin-bottom: 0.6rem;
}

.files ul.list-inline {
  display: flex;
  overflow-x: auto;
}

.files ul.list-inline .list-inline-item {
  margin-left: 0;
  margin-right: 0.5rem;
}

figure.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}

figure.avatar .avatar-title {
  color: rgba(255, 255, 255, 0.8);
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 600;
}

figure.avatar > a {
  width: 100%;
  height: 100%;
  display: block;
  transition: color 0.3s;
  color: #3db16b;
}

figure.avatar > a > img,
figure.avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  padding: 2px;
}

figure.avatar.avatar-sm {
  height: 1.5rem;
  width: 1.5rem;
}

figure.avatar.avatar-sm .avatar-title {
  font-size: 14px;
}

figure.avatar.avatar-sm.avatar-state-primary:before,
figure.avatar.avatar-sm.avatar-state-success:before,
figure.avatar.avatar-sm.avatar-state-danger:before,
figure.avatar.avatar-sm.avatar-state-warning:before,
figure.avatar.avatar-sm.avatar-state-info:before,
figure.avatar.avatar-sm.avatar-state-secondary:before,
figure.avatar.avatar-sm.avatar-state-light:before,
figure.avatar.avatar-sm.avatar-state-dark:before {
  width: 0.8rem;
  height: 0.8rem;
}

figure.avatar.avatar-lg {
  height: 4rem;
  width: 4rem;
}

figure.avatar.avatar-lg .avatar-title {
  font-size: 29px;
}

figure.avatar.avatar-lg.avatar-state-primary:before,
figure.avatar.avatar-lg.avatar-state-success:before,
figure.avatar.avatar-lg.avatar-state-danger:before,
figure.avatar.avatar-lg.avatar-state-warning:before,
figure.avatar.avatar-lg.avatar-state-info:before,
figure.avatar.avatar-lg.avatar-state-secondary:before,
figure.avatar.avatar-lg.avatar-state-light:before,
figure.avatar.avatar-lg.avatar-state-dark:before {
  width: 1.2rem;
  height: 1.2rem;
  right: -1px;
}

figure.avatar.avatar-xl {
  height: 6.3rem;
  width: 6.3rem;
}

figure.avatar.avatar-xl .avatar-title {
  font-size: 39px;
}

figure.avatar.avatar-xl.avatar-state-primary:before,
figure.avatar.avatar-xl.avatar-state-success:before,
figure.avatar.avatar-xl.avatar-state-danger:before,
figure.avatar.avatar-xl.avatar-state-warning:before,
figure.avatar.avatar-xl.avatar-state-info:before,
figure.avatar.avatar-xl.avatar-state-secondary:before,
figure.avatar.avatar-xl.avatar-state-light:before,
figure.avatar.avatar-xl.avatar-state-dark:before {
  width: 1.2rem;
  height: 1.2rem;
  top: 2px;
  right: 7px;
}

figure.avatar.avatar-state-primary,
figure.avatar.avatar-state-success,
figure.avatar.avatar-state-danger,
figure.avatar.avatar-state-warning,
figure.avatar.avatar-state-info,
figure.avatar.avatar-state-secondary,
figure.avatar.avatar-state-light,
figure.avatar.avatar-state-dark {
  position: relative;
}

figure.avatar.avatar-state-primary:before,
figure.avatar.avatar-state-success:before,
figure.avatar.avatar-state-danger:before,
figure.avatar.avatar-state-warning:before,
figure.avatar.avatar-state-info:before,
figure.avatar.avatar-state-secondary:before,
figure.avatar.avatar-state-light:before,
figure.avatar.avatar-state-dark:before {
  content: '';
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  top: -3px;
  right: -3px;
  border: 3px solid white;
}

figure.avatar.avatar-state-primary:before {
  background: #3db16b;
}

figure.avatar.avatar-state-success:before {
  background: #0abb87;
}

figure.avatar.avatar-state-danger:before {
  background: #ea4141;
}

figure.avatar.avatar-state-warning:before {
  background: #ff9d36;
}

figure.avatar.avatar-state-info:before {
  background: #00bcd4;
}

figure.avatar.avatar-state-secondary:before {
  background: dimgray;
}

figure.avatar.avatar-state-light:before {
  background: #e6e6e6;
}

figure.avatar.avatar-state-dark:before {
  background: #3a3f51;
}

.tab-content {
  margin: 15px 0;
}

.tab-content .form-item {
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
}
.tab-content .soft-form {
  display: block !important;
}
.check-enable {
  padding-left: 38px;
  padding-top: 12px;
}

.tab-title {
  font-size: 16px;
}

.input-message {
  width: 100%;
  min-height: 50px;
  max-height: 100px;
  overflow: auto;
  padding: 9px;
  resize: none;
}

.sender {
  color: #828282;
  margin-bottom: 10px;
  font-size: 15px;
}

.sender span:hover {
  color: #e6e6e6;
  cursor: pointer;
}

.chat-footer-form i {
  font-size: 18px;
}

.chat-footer-form i:hover {
  font-size: 20px;
}

input[type='file'] {
  display: none;
}

.chat-image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-image-upload label {
  margin-bottom: 0px;
}

.message-content .img-view {
  width: auto;
  height: auto;
  max-width: '25%';
  max-height: 150px;
  outline: none;
}
.message-content audio {
  outline: none;
}
.converstaion {
  background-color: #5a5c66;
  border-color: #aaacb6;
}

.calendar {
  width: 100%;
  background-position: 95% 50%;
}

.unread-message-count {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 10px;
  background-color: #3db16b;
  color: white;
  border-radius: 45%;
  padding: 8px 4px;
}

.btn.call-forward-light {
  background: #e6e6e6;
  border-color: #d4d4de;
  border-radius: 0px;
}
.btn.call-forward-light:focus,
.btn.call-forward-light:hover {
  border-color: transparent !important;
  background-color: #c8c8c8 !important;
}

.btn.call-forward-dark {
  background: #5a5c66;
  border-color: #7b7e82;
  border-radius: 0px;
}

.btn.call-forward-dark:focus,
.btn.call-forward-dark:hover {
  border-color: transparent !important;
  background-color: #e6e6e6 !important;
}
.call-forward-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.number-information {
  position: absolute;
  margin-top: -37px;
  left: 440px;
}

.position-number-dropdown {
  position: relative;
  border-radius: 60%;
  color: #fff;
}

.total-msg-count-1 {
  position: absolute;
  top: 0px;
  right: 10px;
  padding: 10px 6px;
}

.total-msg-count {
  position: absolute;
  top: -18px;
  right: -7px;
  padding: 10px 6px;
}

.timeline .name {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  width: 20%;
  white-space: nowrap;
  vertical-align: middle;
}
.scale-container {
  position: relative;
  border-bottom: solid 1px #ddd;
  height: 15px;
  display: flex;
}
.scale-container .scale-element {
  position: relative;
  width: 16.666666666666668%;
  border-right: solid 1px #cdcdcd;
  height: 100%;
}
.scale-container .scale-element:first-child {
  border-left: solid 1px #cdcdcd;
}
.scale-container .scale-element > span {
  position: absolute;
  top: -20px;
  right: -20px;
  color: #999;
  font-size: 12px;
}
.scale-container > span {
  position: absolute;
  top: -20px;
  left: -10px;
  color: #999;
  font-size: 12px;
}
.check-ring-state {
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-ring-name {
  display: flex;
  align-items: center;
  cursor: default;
}
.device-icon {
  width: 18px;
  height: 18px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: gray;
  margin-right: 10px;
}
.check-ring-name:hover .cell-phone {
  color: #3db16b;
  cursor: pointer;
}
.check-ring-name:hover .cell-phone-text {
  color: #3db16b;
  cursor: pointer;
}

.following-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: 'center';
}
.new-conversation i {
  position: absolute;
  top: 11px;
  left: 10px;
  z-index: 10;
}
.new-conversation input {
  padding-left: 30px;
}
.add-new-phone {
  display: inline !important;
}
.loader-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  z-index: 1048;
  background-color: #404040;
  opacity: 0.8;
  touch-action: none;
}
.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3db16b;
  width: 120px;
  height: 120px;
  opacity: 1;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.device-footer {
  display: flex;
  justify-content: space-between;
}

.contact-details {
  background-color: #fff !important;
  border-left: 1px solid #EFECE8;
}

.contact-details .sidebar {
  /* width: 290px !important; */
  background-color: #fff !important;
  width: 100% !important;
  overflow-y: scroll !important;
}

.media-list {
  white-space: nowrap;
  overflow-x: auto;
}

.btn-facebook {
  color: #3a5998;
}
.btn-twitter {
  color: #56acee;
}
.btn-dribbble {
  color: #eb4c89;
}
.btn-whatsapp {
  color: #42d854;
}
.btn-linkedin {
  color: #0077b5;
}
.btn-google {
  color: #dc4437;
}
.btn-behance {
  color: #1769ff;
}
.btn-instagram {
  color: #3f729b;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* label color */
/* .input-group label {
  color: #000;
} */

/* label focus color */
.input-group input[type='text']:focus + label {
  top: -1rem;
  left: 0;
}

/* label underline focus color */
.input-group .form-control {
  padding-left: 5px;
  background-clip: unset;
  transition: all 0.28s ease;
  border-radius: 0 !important;
  resize: none;
}

.input-group input[type='text'],.input-group input {
  border: none;
  border-bottom: 1px solid #6e707a;
}

.input-group input[type='text']:focus,.input-group input:focus {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}

/* valid color */
.input-group input[type='text'].valid, .input-group input.valid{
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}

/* invalid color */
.input-group input[type='text'].invalid, .input-group textarea.invalid {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}

/* icon prefix focus color */
.input-group .prefix.active {
  color: #000;
}

.autocomplete-wrapper {
  width: 332px;
  position: relative;
  display: contents;
}

.autocomplete-wrapper > div {
  width: 82%;
}

.autocomplete-wrapper input {
  /* border: 1px solid #cecece;
  padding: 12px 15px;
  font-size: 14px;
  width: 100%;
   */
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 24px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-left: 40px;
}

.autocomplete-wrapper input:focus {
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  z-index: 10;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}

.autocomplete-wrapper .itemMenu {
  border: none;
}

.autocomplete-wrapper .item.selected-item {
  /* background-color: #0069ff; */
  background-color: #666;
  color: #fafbfc;
}

.autocomplete-wrapper .item:hover {
  /* background-color: #0069ff; */
  background-color: #666;
  color: #fafbfc;
}
.assign-conversation {
  background-color: #d5f5c5;
}

[class^='hvr-'] {
  margin: 0.4em;
  padding: 1em;
  cursor: pointer;
  background: #e1e1e1;
  text-decoration: none;
  color: #666;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.assigned-message {
  text-align: center;
  align-self: center;
}
.assigned-message .title {
  font-style: normal;
  margin-right: 10px;
  font-size: 15px;
  color: #3db16b;
}
.assigned-message .time {
  color: #828282;
  margin-top: 5px;
  font-style: italic;
  font-size: 12px;
}
.con-pagination {
  margin-top: 20px;
  padding: 0px !important;
  text-align: center;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  margin-left: 72px;
}
.con-pagination ul {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.con-pagination li {
  display: inline-block;
}
.con-pagination li.active a {
  background-color: #3db16b;
  color: #fff !important;
}
.con-pagination li a {
  box-shadow: rgb(0 0 0 / 9%) 0px 8px 18px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px;
  position: relative;
  display: block;
  color: #212529 !important;
  text-decoration: none;
  background-color: #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-radius: 15px;
  margin-left: 6px;
  margin-right: 6px;
  min-width: 30px;
  min-height: 30px;
  padding: 6px 11px;
}

.layout .content .chat .chat-footer .chat-footer-form .form-buttons .dropdown > button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-left: 0px !important;
  border-left: 1px solid #1f934d !important;
  border-radius: 50px;
  padding: 5px 10px;
  background: #3db16b;
  border-color: #3db16b;
}

.send-btn {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding: 13px 20px !important;
}
.schedule-dropdown {
  padding: 13px 20px !important;
}
.schedule-icon {
  display: flex !important;
  align-items: center !important;
}
.schedule-icon i {
  font-size: 20px;
  margin-right: 10px;
}
.new-sms {
  background: #FFFFFF !important;
  border-radius: 24px;
}
.sms-group {
  margin-left: 10px
}
.sms-icon {
  color: #b3b3b7;
}
.header-home {
  position: absolute;
  height: 67px;
  width: 100%;
  background: #fff;
  left: 0;
  padding: 10px 0;
}
.chat-upper {
  width: 100%;
}
.conversation-header {
  padding-left: 30px;
  padding-top: 28px;

}
.conversation-header h5{
  color: #181C2F;
  mix-blend-mode: normal;
  opacity: 0.6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.user-number {
  display: flex;
}
.number-btn {
  background-color:#5fbc76;
  font-size: 10px !important;
  padding: 0 !important;
  margin-left: 10px;
  width: 21px !important;
  height: 21px;
  line-height: 21px !important;
  text-align: center !important;
  display: block !important;
}

.phoneNumber-admin {
  color: #181C2F !important;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
}
.chat-name {
  background: #FFFFFF;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 10px;
}
.chat-name .card-body {
  padding: 0 !important;
  position: relative;
}
.header-action {
  text-align: right;
}
.user-info {
  display: flex;
  align-items: center;
  padding-right: 26px;
}
.user-info figure {
  position: relative;
  margin-right: 13px;
  text-align: center;
  width: 46px !important;
  height: 46px !important;
}
.user-info h5 {
  margin-bottom: 0;
}
.chat-user-image {
  position: absolute;
  right: 5px;
  top: 2px;
  opacity: 0;
}
.chat-user-image:hover {
  opacity: 1;
}
.header-chat-action {
  display: flex;
  align-items: center;

}
.chat-icons {
  margin-right: 30px;
}
.assign-label {
  margin-left: 0;
  vertical-align: middle;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.assign-input {
  border: 1px solid #CECECE;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
}
.assign-input:checked ~ .assign-label::before {
  background-color: #3db16b;
  color: #fff;
}
.assign-form input[type="checkbox"] {
  visibility: hidden;
}

.assign-form input[type="checkbox"] + label::before {
  border: 1px solid #CECECE;
  content: "";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 .25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 3px;
}
.assign-form input[type="checkbox"]:checked + label::before {
  content: "";
  text-align: center;
  font-weight: bold;
}
.assign-form input[type="checkbox"]:checked + label::after {
  font-weight: bold;
}

.assign-form input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
}
.assign-form {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.react-calendar__tile--active {
  border-radius: 50%;
  width: 36px !important;
  height: 36px;
  padding: 0 !important;
  background: #5FBC76 !important;
}
.react-date-picker {
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #C3C3C3;
}
.react-time-picker {
  width: 45%;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #C3C3C3;
}
.react-date-picker__wrapper, .react-time-picker__wrapper{
  border: none !important;
}

.assign-user-image {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.assign-user-image img{
  width: 100%;
  height: 100%;
  margin-left: 10px;
  border-radius: 50%;
}
.delete-body {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #181C2F;
  padding: 20px 55px 20px 55px !important;
}
.delete-footer {
  justify-content: center;
  padding: 0px !important;
  padding-bottom: 50px !important;
}
.del-conversation-modal {
  width: 343px;
  height: 317px;
}
.delete-icon i {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #5FBC76;
  line-height: 52px;
  color: #fff;
}
button.drop-menu:after {
  content: '';
  background: #eee;
  width: 90%;
  height: 1px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 0;
}
button.drop-menu:last-child {
  padding-bottom: 0 !important;
}
.custom-outer-menu.dropdown-menu .drop-menu:last-child::after {
 display: none;
}
.delete-icon {
  padding-bottom: 20px;
}
.call-modal.light-modal .modal-content .call-header {
  background-color: #fff !important;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #181C2F;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  margin: 20px 30px 0;
  padding-bottom: 20px;
}
.call-modal {
  border-radius: 6px;
  max-width: 410px;
}
.start-call {
  padding: 12px 31px 11px 29px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.update-modal.light-modal .modal-content .update-header {
  background-color: #fff !important;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #181C2F;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  margin: 20px 30px 0;
  padding-bottom: 20px;
}
.input-group .update-input {
  background: #FFFFFF;
  border: 1px solid #CECECE !important;
  box-sizing: border-box;
  padding-left: 0.75rem;
  border-radius: 3px !important;
  margin-bottom: 5px !important;
}
.update-div {
  padding-left: 15px;
  padding-right: 0px;
}
.add-btn {
  background-color: #5FBC76 !important;
  border-color: #5FBC76 !important;
  margin-left: 10px
}
.update-modal {
 max-width: 571px;
}
.zip-update-div {
  padding-right: 0px;
  padding-left: 10px;
}
.state-update-div {
  padding-left: 10px;
}
.input-group .update-input:focus {
  box-shadow: 0 1px 0 0 #ebebeb !important;
}
.del-contact-modal{
  max-width: 343px;
}
.print-modal {
  max-width: 570px;
}
.print-modal.light-modal .modal-content .print-header {
  background-color: #fff !important;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #181C2F;
}
.print-body {
  padding-top: 0 !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.print-date {
  padding-right: 0;
}
.new-sms-modal.light-modal .modal-content .new-sms-header {
  background-color: #fff !important;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #181C2F;
}
.upload-input {
  float:left;
  background: #FFFFFF;
  border: 1px solid #C3C3C3;
  box-sizing: border-box;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 31px;
}
.upload-input label {
  margin-bottom: 0;
}
.sms-footer {
  justify-content: space-between;
  display: flex
}
.sms-start {
 padding: 12px 31px 11px 31px !important;
}
.about-labelName {
  font-style: normal;
  padding-top: 25px;
  font-weight: 900;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #181C2F;
}
.about-company {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding-top: 10px;
  text-align: center;
  color: #181C2F;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  margin: 20px 30px 0;
  padding-bottom: 20px;
}
.about-phone {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  margin: 20px 30px 0;
  padding-bottom: 20px;
  cursor: pointer;
}
.about-phone-item {
  font-size: 14px;
  mix-blend-mode: normal;
  opacity: 0.4;
  margin-right: 16px;
  line-height: 17px;
  margin-bottom: 0;
  color: #181C2F;

}
.about-phone-number {
  font-size: 14px;
  line-height: 17px;
}
.about-voicemail {
  /* padding: 27px 27px 27px 31px; */
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  margin: 20px 30px 0;
  padding-bottom: 20px;
}
.about-voicemail div {
  display: flex;
}
.about-voicemail h5 {
  font-size: 14px;
  line-height: 17px;
  color: #181C2F;
  mix-blend-mode: normal;
  opacity: 0.4;
}
.about-voicemail .about-see{
  font-size: 12px;
  line-height: 17px;
  color: #5FBC76;
  margin-left: 35px;
}
.about-voicemail-list {
  overflow: auto !important;
}
.about-call {
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  margin: 20px 30px 0;
  padding-bottom: 20px;
}
.about-call h5 {
  font-size: 14px;
  line-height: 17px;
  color: #181C2F;
  mix-blend-mode: normal;
  opacity: 0.4;
}
.about-call div {
  display: flex;
}
.about-call .about-see{
  font-size: 12px;
  line-height: 17px;
  color: #5FBC76;
  margin-left: 85px;
}
.about-call-icon {
  background: #181C2F !important;
  border-radius: 50%;
  opacity: 0.4;
  margin-right: 10px;
  color: #FFFFFF !important;
  width: 35px !important;
  height: 35px;
  line-height: 35px !important;
  text-align: center;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.btn-head {
  color: #292F4C;
  opacity: 0.3;
}
.assign-badge {
  background-color: #5FBC76;
  padding: 7px 20px;
  float: right;
  position: absolute;
  right: -25px;
  border-radius: 0;
  bottom: -10px;
  font-weight: normal;
  z-index: 1;
}
.open-chat .assign-badge::after, .list-group-item:hover .assign-badge::after {
  border-left-color: #292F4C;
}

.assign-badge::after {
  top: 0;
  bottom: 0;
  position: absolute;
  content: '';
  left: 0;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid #fff;
}
.about-audio {
  border-radius: 50%;
  opacity: 0.4;
  margin-right: 10px;
  color: #FFFFFF !important;
  width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  text-align: center;
  padding: 0 !important;
  display: inline-block !important;
}
.home-search {
  position: absolute;
  margin-top: 10px;
  margin-left: 15px;
  width: auto !important;

}
.home-search i {
  color: #292F4C;
  opacity: 0.3;
}
.chat-labelName {
  display: flex;
}
.chat-important-icon {
  padding-left: 10px;
  position: absolute;
  top: 0px;
  right: 50px;
}
.chat-favorite-icon {
  padding-left: 10px;
  position: absolute;
  top: 0px;
  right: 50px;
  color: #FDC703;
}
.home-imp {
  position: relative;
  max-height: 90px;
  height: 100%;
}
li.list-group-item.open-chat figure {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  transition: all 0.5s ease-in-out;
}
li.list-group-item:hover figure {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

li.list-group-item:hover figure.profile, li.list-group-item.open-chat figure.profile {
  z-index: 10;
  -webkit-filter: brightness(1) invert(0) !important;
          filter: brightness(1) invert(0) !important;
}

.chat-schedule-msg {
  font-size: 12px;
  line-height: 12px;
  font-style: normal;
  font-weight: normal;
  color: #666666;
}
.chat-schedule-msg .chat-schedule-edit{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #5FBC76;
  cursor: pointer;
}
.chat-schedule-msg .chat-schedule-name{
  color: #1A2028;
  font-size: 12px;
  font-weight: 600;
}
.chat-end-msg {
  bottom: -10px;
  left: 0;
  right: 15px;
  text-align: right;
  position: absolute;
}
.modal {
  background: #0000000d;
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}
.active-menu i {
  color: #5fbc76;
}
.react-time-picker__inputGroup__input:invalid {
  background: rgb(255 255 255) !important;
}
.sms-header {
 border-bottom: none;
}
.sms-header .nav-link {
  padding: 3px 0;
}

.sms-header .nav-item{
  margin-right: 20px;
}

.sms-header .nav-item:last-child{
  margin-right: 0;
}

.receiver-voice {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}
.list-chat {
  min-height: 145px;
}
.list-count-chat {
  min-height: 125px;
}
.conversationTab {
  margin-left: 10px;
  margin-right: 0 !important;
}
.unreadTab {
  margin-right: 0 !important;
  padding: 0 !important;
}
.quick-call-header {
  border-bottom: none;
}
.quick-call-header .close {
 color: #fff;
}

.uplaodmsgtext{
  padding-left: 5%;
}

#whatappDiv{
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 100px;
  min-height: 20px;
}


/** This is the css of the input **/
#w-input-container {
  max-width: 100%;
  width:100%;
  min-height: 20px;
  box-sizing: border-box;
  padding: 9px 18px 11px;
  margin: 5px 10px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 21px;
    border: none;
  background-color: inherit;
  resize: none;
}

.w-input-text-group {
  position: relative;
}

.w-placeholder {
  color: #a0a0a0;
  top: 0;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  position: absolute;
  opacity: 0;
  transition: 0.2s padding ease-in-out;
}

#msgText {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  max-height: 100px;
  min-height: 20px;
  padding: 0 0 0 2px;
  outline: 0;
  transition: 0.2s padding ease-in-out;
}

#msgText:empty + .w-placeholder {
  opacity: 1;
}

#msgText:focus + .w-placeholder {
  padding: 0 6px 0px;
}

#msgText:focus {
  padding: 0 6px 0px;
}


.allbtnStyle{
  margin: 10px;
}
.extNo{
  margin: '6px',
}
.extFind{
  width: '395px'
}

.dtmf-body{
  height: 400px;
}

.dtmf-body-inline{
  height: 300px;
}

.dtmf-input{
  border-radius: 10px;
  width: 400px;
}

.dtmf-number{
  display: flex;
  justify-content: space-around;
  font-size:"20%"
}


.link-preview {
  padding: 10px;
  background: #ffffff;
  border: 2px solid #aaaaaa;
  border-radius: 10px;
  margin-top: 10px;
  outline: none;
  box-shadow: none;
}

.messgae-content .link-preview:last-child {
  margin-bottom: 7px;
}

.link-preview a {
  outline: none;
  box-shadow: none;
  border: none;
}

.preview-older {
  padding: 0;
}

.preview-older .load-preview {
  width: 100%;
  display: block;
  text-align: center;
  background: #423c3a;
  height: 100px;
  line-height: 100px;
  color: #fff;
  cursor: pointer;
}

.preview-older a.link {
  padding: 10px;
  display: block;
  background: #5b5553;
  color: #fff;
}

.arrow-right {
  float: right;
  padding-top: 5px;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.modalFooter {
  display: block !important;
}

.updateBtn {
  float: right;
  padding: 12px 30px 11px 30px !important;
}

.deleteBtn {
  float: left;
  padding: 12px 30px 11px 30px !important;
}

.modalHeader {
  padding: 0;
  border-bottom: none;
}

.modalHeader .modal-header {
  border-bottom: none;
}

.modalHeader .modal-footer {
  border-top: none;
}

.sentiment_result {
  color: #3db16b;
}

.close-icon {
  color: #ea4141;
}

.error-tooltip .tooltip-inner {
  color: #ea4141;
  font-weight: bold;
}
.schedule-edit {
  position: absolute;
  right: 10px;
  top: 10px;
}

.select-box {
  position: relative;
  z-index: 1050;
}
.startConversation {
  float: right;
  margin-top: 31px;
  line-height: normal !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-right: 1px;
}
.sms-cancel-button {
  margin-top: 31px;
  line-height: normal !important;
  padding: 12px 30px 11px 30px !important;
}
.callPhone-icon {
  background: #5fbc76 !important;
  border-radius: 50%;
  color: #292F4C !important;
  width: 35px !important;
  height: 35px;
  line-height: 35px !important;
  text-align: center;
  padding: 0 !important;
  display: inline-block !important;
}
.callPhone-icon i {
  color: #292F4C !important;
  mix-blend-mode: normal;
}

.modal-received-call .modal-content{
  background-color: #000 !important;
  color: #fff;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
}
.end-call {
  border-radius: 25px;
  background-color: red !important;
  margin-right: 20px
}
.receive-call {
  border-radius: 25px;
  background-color: #5fbc76 !important;
}
.view-profile{
  margin: 20px;
  position: relative;
  border-bottom: 50%;
  cursor: pointer;
}
.sender-user-image {
    border-radius: 100%;
    width: 52px;
    height: 52px;
    margin-right: 10px;
    margin-left: 20px;
    overflow: hidden;
    flex: 0 0 auto;
}
.sender-user-image img {
  width: 100%;
  height: 100%;

}
.incoming-sender {
  display: flex;
  /* flex-direction: column; */
}
.schedule-modal.light-modal .modal-content  .schedule-header{
  font-weight: 900;
  font-size: 18px;
  color: #181C2F;
  background-color: white !important ;
}
.receiver-name {
  margin: 20px 0px;
  color: #fff;
  opacity: 0.6
}
.error-close-icon {
  padding-left: 5px;
  font-weight: normal;
  font-size: 12px;
  color: #D10E0E;
}
.audio-voice {
  display: flex;
}
.audio-mic {
  margin-top: 20px;
  margin-left: 10px;
}
.deleteContact {
  padding: 12px 38px 11px 39px !important;
}
.cancel-btn {
  padding: 12px 30px 11px 31px !important;
}
.start-buttons {
  display: flex;
}
.start-buttons .dropdown > button {
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
  margin-left: 0!important;
  border-left: 1px solid #1f934d!important;
  border-radius: 5px;
  background: #3db16b;
  border-color: #3db16b;
  margin-top: 31px;
  padding: 17px 14px 17px 14px !important;
  font-size: 14px;
  border: none;
}
.start-buttons .dropdown > button .schedule-dropdown:focus,
.schedule-dropdown:active,
.schedule-dropdown:hover {
  box-shadow: none !important;
  background: #3db16b !important;
  border-color: #3db16b !important;
}
.users-list-action {
  position: absolute;
  top: 25px;
  right: 29px;
  bottom: auto;
}
.layout .users-list-action .action-toggle .dropdown .drop-btn {
  position: static;
  background: none !important;
  border-color: transparent !important;
  outline: none;
  color: #3db16b;
}
.layout .list-group-item:hover .drop-btn {
  outline: none !important;
  color: #fff !important;
}
.layout .card-body .users-list-action .action-toggle .dropdown .drop-btn:hover {
  color: #5fbc76 !important;
  outline: none
}
.drop-btn.btn.btn-secondary:not(:disabled):not(.disabled):focus {
  outline: none !important;
  box-shadow: none !important
}

.container-audio audio {
  width: 100%;
}

.container-audio {
  width: 100%;
  height: auto;
  border-radius: 1px;
  background-color: #f6f6f9;
  overflow: hidden;
  border: solid 1px #e1e1e7;
  height: 40px;
  margin-right: 15px;
}

.container-audio audio::-webkit-media-controls-panel {
  background-color: transparent;
  margin-bottom: -20px;
  margin-top: -30px;
  padding: 0px;
  height: 100px;
}

.container-audio audio::-webkit-media-controls-fullscreen-volume-slider {
  display: block;
}

.container-audio audio::-webkit-media-controls-closed-captions-container {
  display: block;
}

audio::-webkit-media-controls-closed-captions-track-list {
  display: block;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

.container-audio audio::-webkit-media-controls-download-button {
  display: none;
}

.container-audio .audio-close {
  background-color: #f6f6f9;
  overflow: hidden;
  border: solid 1px #e1e1e7;
  margin-left: -31px;
  padding: 8px;
  font-size: 15px;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.numbers {
  padding: 30px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.numbers table tbody {
  background: #ffffff;
}
.numbers table thead th {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #3db16b;
  color: #ffffff;
}
.numbers table td {
  vertical-align: middle;
}
.numbers table td.td-center {
  text-align: center;
}
.numbers table td i {
  font-size: 20px;
}
.numbers table td .assignment:hover {
  cursor: pointer;
  padding: 5px 10px;
  border: 2px solid #3db16b;
}
.search-btns {
  display: flex;
}
.numbers-btns {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.numbers-btns input {
  width: 350px;
  margin-right: 20px;
}

.right-heading {
  background: #fff;
  border: none;
  color: #000;
  padding: 0 20px 0 0;
}

.right-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  padding: 3px 10px;
}
.header-dash {
  width: 100%;
  display: flex;
  background: #fff;
  justify-content: space-between;
}

.user-header h5 {
  font-size: 19px !important;
}

.user-header .header-action {
  margin-top: 15px;
}

.user-header {
  display: flex;
  justify-content: space-between;
}

.user-header {
  display: flex;
  align-items: center;
}
.user-header figure {
  margin-right: 1rem;
  position: relative;
}

.user-header h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}

.user-header figure .user-image label {
  position: absolute;
  top: 3px;
  right: 2px;
  font-size: 16px;
  display: none;
}

.user-header figure .user-image label {
  position: absolute;
  top: 3px;
  right: 2px;
  font-size: 16px;
  display: none;
}
.user-header figure .user-image label i {
  color: #3db16b;
  font-size: 16px;
  border: 1px solid #3db16b;
}
.user-header figure:hover .user-image label {
  display: block;
}

.right-links {
  align-items: center;
  display: flex;
  padding-left: 30px;
}

.right-links > div {
  font-size: 18px;
  padding-right: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.link-pointer {
  cursor: pointer;
}

.main-container {
  margin: 0px;
  text-align: left;
}

.history {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 30px;
}

.history-search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 26px;
}

.history-search label {
  display: block;
  text-align: left;
  font-size: 14px;
  color: grey;
}

.state-date {
  margin-right: 8px;
  width: 140px;
}

.calendar1 {
  width: 130px;
  background-position: 95% 50%;
  background-color: white;
  position: relative;
}

.end-date {
  margin-right: 8px;
  width: 140px;
}

.history-search-button {
  position: absolute;
  bottom: 0px;
}

.call-search-text {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 15rem !important;
  background-position: 95% 50%;
  background-color: white;
}

.page-num {
  font-size: 0.7rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.button-disable {
  color: grey !important;
}

.button-enable {
  color: #000 !important;
}

.call-history-table {
  border: 0px;
  width: 100%;
  margin-left: 15px;
}

.history-table-title {
  font-size: 12px;
  color: grey;
  margin-bottom: 15px;
  width: 100%;
}

.call-history-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  margin-top: -1px;
  float: left;
  width: 100%;
  border-top: solid 1px #bcbcc2;
  border-bottom: solid 1px #bcbcc2;
}
.about-call-history {
  border-top: none !important;
  border-bottom: none !important;
}

.history-from {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.call-icon {
  float: left;
}

.calls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: gray;
}

.incalls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #3da00e;
}

.outcalls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #3553cc;
}

.missed-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #ff3d24;
}

.name {
  margin-bottom: 0;
  font-size: 16px;
}

.number {
  font-size: 14px;
  color: gray;
}

.quick-call {
  position: absolute;
  right: 255px;
  bottom: 0px;
}
.action-btn {
  margin-right: 10px
}
.action-col {
  display: flex;
  flex-direction: row;
}
.action-header {
  padding-left: 40px;
}

.notes-text {
  width: 100% !important;
}

.notes-history {
  font-size: 13px;
  color: #767676;
  margin-top: 10px;
}

.privateKeep{
  margin: 5px;
}

.main-container {
  margin: 0px;
  text-align: left;
}

.voicemails {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 30px;
}

.voicemails td {
  border-bottom: 1px solid #ddd;
}

.voicemailbox-container {
  width: 100%;
  display: inline-block;
}

.voicemailbox-wrapper {
  padding: 24px 0;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.voicemailbox-wrapper:hover {
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
}

.voicemail-list {
  display: flex;
  margin-top: 20px;
}

.voicemailbox {
  width: 100%;
  text-align: center;
}

.voicemailbox .voicemail-mailbox > div {
  width: 50%;
}

.voicemailbox .voicemail-mailbox {
  display: flex;
  margin: 0 auto;
  width: 45%;
  color: #808080ed;
}

.newcount {
  color: #33db24 !important;
}

.totalcount {
  color: #151517;
}

.light .num-title {
  font-size: 14px;
  font-weight: 500;
}

.dark .num-title {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.dark .voicemails-top {
  background-color: #6e707a;
}

.light .voicemails-top {
  background-color: #ffffff;
}

.dark-voicemailbox-wrapper {
  background-color: #6e707a;
}

.light-voicemailbox-wrapper {
  background-color: #ffffff;
}

.voicemails-top-1 h1,
.voicemails-top-2 h1 {
  margin-bottom: 0;
  font-size: 32px;
  /* color: #151517; */
}

.voicemails-top-1 h2,
.voicemails-top-2 h2 {
  margin-bottom: 0;
  font-size: 16px;
  /* color: #151517; */
}

.voicemails-top-2 {
  border-top: 4px solid #ffffff;
}

.voicemails-top-1 {
  border-top: 4px solid #33db24;
  margin-right: 2px;
}

.back-box {
  cursor: pointer;
  font-size: 15px;
  color: gray;
  margin-bottom: 17px;
}

.light .vmbox-title {
  font-size: 23px;
  color: #151517;
  margin-bottom: 24px;
}

.dark .vmbox-title {
  font-size: 23px;
  color: #ffffff;
  margin-bottom: 24px;
}

.voicemail-top-wrap {
  display: block;
  color: #808080ed;
  margin-bottom: 24px;
}

.voicemails-top {
  width: 11rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  padding: 15px 20px 15px 20px;
  margin-right: 1px;
  float: left;
}

.checkbox-wrap {
  padding: 5px 15px;
  border: solid 1px #bcbcc2;
  border-radius: 2px;
  margin-bottom: 1rem;
  font-weight: 300;
  background: #f9f9f9;
  margin-right: 0;
  float: left;
  display: block;
}

.checkbox-wrap > input[type='checkbox'] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.voicemail-search {
  display: block;
  float: right;
}

.voicemail-search input[type='text'] {
  width: 15rem;
}

.bottom-nav {
  float: right;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.bottom-nav button {
  font-size: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.1rem;
  color: grey;
  background: #f7f7f7;
  border: 1px solid #cdcdd0;
  margin-right: 0;
  margin: 0;
}

.bottom-nav button:first-of-type,
.bottom-nav button:nth-of-type(2) {
  color: lightgrey;
}

.bottom-nav button:nth-of-type(3) {
  background: #d3d3d6;
  color: black;
}

.bottom-nav select {
  height: 2rem;
  border: 1px solid grey;
  padding: 0rem 1rem;
  margin: 0 10px;
  color: black;
  font-size: 0.7rem;
  font-weight: 400;
  border-radius: 5px;
}

.page-num {
  font-size: 0.7rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.button-disable {
  color: grey !important;
}

.button-enable {
  color: #000 !important;
}

.voicemailtable {
  border: 0px;
  margin: 0 15px 0 15px !important;
  width: 100%;
  padding: 15px;
}

.row1 {
  color: grey;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  margin-left: 16px;
  width: 100%;
}

.voicemail-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  margin-top: -1px;
  float: left;
  width: 100%;
  border-top: solid 1px #bcbcc2;
  border-bottom: solid 1px #bcbcc2;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.5;
}

.checkbox {
  width: 15px !important;
  height: 15px !important;
  margin-left: -10px;
}

.status {
  margin-left: -15px;
}

.menu-postion {
  position: absolute;
  top: 10px !important;
  left: -57px !important;
}

.newstatus {
  background-color: #33db24 !important;
  border-color: #33db24 !important;
  color: #071f05;
  border-radius: 15px;
  padding: 4px 12px 4px 12px;
  font-size: 12px;
  cursor: pointer;
}

.listenedstatus {
  background-color: #d0d0d9 !important;
  border-color: #d0d0d9 !important;
  color: #071f05;
  border-radius: 15px;
  padding: 4px 12px 4px 12px;
  font-size: 12px;
  cursor: pointer;
}

.deletedstatus {
  background-color: #ff3d24 !important;
  border-color: #ff3d24 !important;
  color: #ffffff;
  border-radius: 15px;
  padding: 4px 12px 4px 12px;
  font-size: 12px;
  cursor: pointer;
}

.mailchange {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audioplay {
  cursor: pointer;
}

.delete {
  cursor: pointer;
}

.audiodown {
  cursor: pointer;
}

.name {
  margin-bottom: 0;
  font-size: 16px;
}

.number {
  font-size: 14px;
  color: gray;
}

.audio-close {
  background-color: #f6f6f9;
  overflow: hidden;
  border: solid 1px #e1e1e7;
  margin-left: -31px;
  padding: 8px;
  font-size: 15px;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.gray-icon {
  width: 18px;
  height: 18px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: gray;
}

.multi-select-control-input:checked ~ .multi-select-control-label::before {
  background-color: #3db16b;
  border-color: #3db16b;
}

.multi-select-control-input {
  position: inherit !important;
  left: 0;
  z-index: 1 !important;
  width: 1rem;
  height: 1.25rem;
  opacity: 1 !important;
}

.quick-input {
  padding-left: 0;
  padding-right: 0;
  margin-top: 10px;
}
.quick-input .form-control {
  border: 1px solid #6e707a !important;
  border-radius: 5px !important;
  margin-bottom: 10px;
}
.voicemail-actions {
  padding-right: 0;
  padding-left: 0;
}

.automation {
  display: inline-block;
  width: 100%;
  position: relative;
}
.automation table tbody {
  background: #ffffff;
}
.automation table thead th {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #3db16b;
  color: #ffffff;
}
.automation table td {
  text-align: center;
}
.automation table td i {
  font-size: 20px;
}
.css-yk16xz-control {
  border-color: #6e707a !important;
}
.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}
.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.automation {
  padding: 30px;
}


.redtail-model-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #3db16b;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.redtail-model-ui>button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}


.form-membership .form-wrapper{
    text-align: left;
}
.form-membership .form-wrapper .form-group span {
    display: block;
    text-align: center;
    margin: 20px 0;
}
.form-info {
    margin-bottom: 20px;
    color: #202029;
}
.info{
    display: block;
    margin: 20px 0 5px;
    text-align: center;
    color: #808089;
}
.infobox{text-align: center;}
.link{
    color: #22A5FF;
}
.msg{margin: 5px 0 20px;}
.form-membership .form-wrapper {
  text-align: left;
}
.form-membership .form-wrapper .form-group p {
  color: #ea4141;
}
.form-info {
  margin-bottom: 20px;
  color: #202029;
}
.info {
  display: block;
  margin: 20px 0 5px;
  text-align: center;
  color: #808089;
}
.infobox {
  text-align: center;
}
.link {
  color: #22a5ff;
}
.msg {
  margin: 5px 0 20px;
}

.layout-admin {
  display: flex;
  height: 100vh;
}

.layout-admin .content {
  flex: 1 1;
  margin-left: 70px;
  flex-direction: column;
  overflow: auto;
  display: flex;
}

.header {
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}

.right-dash-heading {
  padding: 19px 20px 18px;
  background: #5fbc76;
  border: none;
  color: #fff;
  display: flex;
}

.right-dash-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;
}
.right-dash {
  width: 100%;
}

.right-dash-middle {
  padding: 50px 50px 0px !important;
}
.right-dash-data{
  display: flex;
  flex-direction: column;
  flex:1 1;
}

.dashboard-box {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.dash-box-heading h3 {
  font-size: 1.45rem !important;
  font-weight: bold !important;
  float: left;
}
.dash-box-heading span {
  float: none !important;
  font-size: 12px;
  font-weight: 500;
  display: block;
}
@media screen and (min-width: 1200px) {
  .dash-box-heading {
    border-bottom: none !important;
    padding: 0px 20px !important;
    display: flex !important;
  }
  .dash-box-heading h3 {
    font-size: 1.45rem !important;
    font-weight: bold !important;
    float: left;
  }
  .dashboard-user {
    margin-bottom: 0;
    margin-left: 50px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 0px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1199px) {
  .dash-box-heading h3 {
    font-size: 18px !important;
    margin-right: 5px;
  }
  .dashboard-user {
    margin-left: 17px !important;
    margin-top: -2px !important;
    font-size: 17px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1099px) {
  .dash-box-heading {
    border-bottom: none !important;
    padding: 10px 8px !important;
    display: flex;
  }
  .dash-box-heading h3 {
    font-size: 16px !important;
  }
  .dashboard-user {
    margin-left: 12px !important;
    margin-top: -2px !important;
    font-size: 14px !important;
  }
  .account-data {
    font-size: 10px !important;
    margin-top: 4px !important;
  }
}

.dash-box-account {
  border-bottom: none !important;
  padding: 0px 0px;
}

.dash-box-account span {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  margin-right: 10px;
}
.admin-users {
  padding: 0 !important;
}
.dash-box-heading:after {
  content: '';
  display: block;
  clear: both;
}
.box-btm-cont {
  padding: 15px 20px;
}
.box-btm-cont p {
  margin-bottom: 0;
}

.dashboard-chart {
  border: 1px solid #ddd;
  padding: 15px 20px;
  background-color: #ffffff;
}
.dash-fix-content {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.right-dash {
  position: relative;
}
.account-data {
  font-size: 12px;
  font-weight: normal;
  margin-left: 6px;
  margin-bottom: 0;
  margin-top: 2px;
}
.dashboard-user {
  margin-bottom: 0;
  margin-left: 50px;
  font-weight: bold;
  font-size: 20px;
  margin-top: 0px;
}
.dash-row {
  display: flex;
  align-items: center;
  background-color: #82828282;
  border-bottom: 1px solid #ddd;
}
.account-col {
  padding-left: 0;
  padding-right: 0;
}
.dash-footer {
  margin-left: 0;
  padding: 20px;
  margin-right: 0px;
  background-color: #82828282;
}
.number-data h3 {
  font-weight: bold;
  font-size: 1.45rem;
}
.sms-sender {
  background-color: #82828282;
  padding: 20px;
}
.sms-sender h3 {
  font-weight: bold;
  font-size: 1.45rem;
}
.chart-data {
  margin-bottom: 10px;
}
.account-id {
  width: 60%;
}

.header {
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}

.right-dash-heading {
  padding: 19px 20px 18px;
  background: #5fbc76;
  border: none;
  color: #fff;
  display: flex;
}

.right-dash-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;
}
.right-dash {
  width: 100%;
}

.right-dash-middle {
  padding: 50px 50px 0px !important;
}

.dashboard-box {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.dash-box-heading h3 {
  font-size: 18px;
  font-weight: 600;
  float: left;
}
.dash-box-heading span {
  float: right;
  font-size: 18px;
  font-weight: 600;
}
.dash-box-heading {
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}
.dash-box-heading:after {
  content: '';
  display: block;
  clear: both;
}
.box-btm-cont {
  padding: 15px 20px;
}
.box-btm-cont p {
  margin-bottom: 0;
}

.dashboard-chart {
  border: 1px solid #ddd;
  padding: 15px 20px;
  background-color: #ffffff;
}
.dash-fix-content {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.right-dash {
  position: relative;
}

.csv-reader-input {
  background-color: #3db16b;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer;
  margin-left: 1rem !important;
  border-radius: 0.25rem;
  margin-right: 1rem;
  vertical-align: middle;
  height: 40px;
  display: flex;
  align-items: center;
}

.csv-reader-input label {
  margin-bottom: 0 !important;
  cursor: pointer;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .search-input {
    border: 1px solid transparent;
    border-radius: 25px;
    width: 240px !important;
  }
}

.search-input {
  border: 1px solid transparent !important;
  border-radius: 25px !important;
  padding-left: 40px;
}
.contact-search {
  position: absolute;
  margin-top: 2px;
  margin-left: 18px;
  width: auto !important;
}
.contact-search i {
  color: #292f4c;
  opacity: 0.3;
}
.distribution-menu .distribution-icon {
  background-color: #3db16b !important;
  border-color: #3db16b !important;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer !important;
  margin-left: 1rem !important;
  border-radius: 0.25rem;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.distribution-menu .btn.btn-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: none !important;
}
.filter {
  background-color: #3db16b;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer;
  margin-left: 1rem !important;
  border-radius: 0.25rem;
  vertical-align: middle;
}
.filter,
.distribution-icon i {
  color: #fff;
}
.initials-avatar {
  background-color: #3db16b !important;
}
.phone-icon {
  margin-right: 10px;
}
.contact-sideMenu {
  display: flex;
}
.contact-action {
  display: flex;
  justify-content: space-evenly;
  padding-top: 25px !important;
  cursor: pointer;
}
.contact-call {
  margin-right: 10px;
}
.contact-action i {
  font-size: 14px !important;
}
.position-add-dropdown {
  background-color: #3db16b !important;
  border-color: #3db16b !important;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer !important;
  border-radius: 0.25rem;
  vertical-align: middle;
  color: #fff;
  margin-top: 10px;
}
.position-add-dropdown:hover {
  color: #fff;
}
.distribution-dropmenu {
  width: 100%;
  min-width: 15rem;
  text-align: center;
  left: -83px !important;
  box-shadow: 3px 3px 7px 1px #aaaaaa;
  -webkit-transform: initial !important;
          transform: initial !important;
  top: 50px !important;
  margin-left: 38px !important;
}
.drop-distribution {
  border-bottom: 1px solid #ddd;
  display: flex;
  margin: 10px 5px;
}
.dist-badge {
  width: 13%;
  display: block !important;
  padding: 10px !important;
  margin-right: 10px !important;
  border-radius: 7px !important;
}
.dist-input {
  border: none !important;
  width: inherit !important;
}
.dist-input:focus-visible {
  outline: none;
}
.drop-filter {
  border-bottom: 1px solid #ddd;
  padding: 10px 24px;
  text-align: left;
}
.filter-header {
  display: flex;
  color: #000;
  font-weight: 700;
  padding: 10px 0px;
  justify-content: space-between;
}
.filter-clear {
  font-size: 12px;
  color: #5fbc76 !important;
}
.drop-filter-distribution {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 10px 24px;
  justify-content: space-between;
}
.drop-filter-distribution i {
  font-size: 12px;
  color: #000;
  font-weight: 600;
  padding-top: 7px;
}
.drop-select {
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.dist-more {
  color: #5fbc76;
  margin-bottom: 0;
  cursor: pointer;
}
.drop-scroll {
  height: 289;
  overflow-y: auto;
  overflow-x: hidden;
}
.drop-scroll-distribution {
  max-height: 317px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}
.multiple-dist {
  display: flex;
}
.filter-dropMenu {
  width: 100%;
  min-width: 13rem;
  text-align: center;
  left: -285px !important;
  box-shadow: 3px 3px 7px 1px #aaaaaa;
  -webkit-transform: initial !important;
          transform: initial !important;
  top: 126px !important;
  margin-left: 32px !important;
}
.dist-toggle {
  display: none !important;
}

.disDataDis {
  margin-right: 5px;
}

.drop-scrolling {
  height: 155px;
  overflow-y: auto;
  overflow-x: hidden;
}

.iconsSet {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.disIconst button{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.addContactBtn {
    background-color: #3db16b !important;
    border-color: #3db16b !important;
    font-size: 14px;
    width: auto;
    display: inline-flex;
    padding: 10px 15px;
    line-height: 14px;
    cursor: pointer !important;
    margin-left: 1rem !important;
    border-radius: 0.25rem;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}


.layout-admin .content .distribution {
  padding: 25px;
  display: flex;
  flex: 1 1;
  min-height: calc(100vh - 70px);
  overflow: hidden;
}
.distribution .distributionList {
  margin-right: 30px;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  width: 350px;
  padding: 20px;
  height: calc(100vh - 125px);
}
.distributionList .listHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.distributionList .headerTitle {
  font-size: 20px;
  font-weight: 700;
  margin-left: 20px;
}

.distributionList .lists {
  height: 100%;
}
.distributionList .lists .list {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  padding: 15px 0px 15px 20px;
  border-top: 1px solid #ebebeb !important;
  display: flex;
  justify-content: space-between;
}
.distributionList .lists .list div {
  width: 90%;
}

.distributionList .lists .list .edit-btn:hover {
  color: #3db16b;
}
.distributionList .lists .active-list {
  position: relative;
}
.distributionList .lists .active-list::before {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 53px;
  background-color: #3db16b;
  top: 0;
  bottom: 0;
  left: 0;
}
.distributionList .lists .list:last-child {
  border-bottom: 1px solid #ebebeb !important;
}
.distributionList .list i {
  font-size: 15px;
  font-weight: 900;
  margin-right: 10px;
}
.distribution .contactList {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.distribution .contactList table tbody {
  background: #ffffff;
}
.distribution .contactList table thead th {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #3db16b;
  color: #ffffff;
}
.distribution .contactList table td {
  vertical-align: middle;
}
.distribution .contactList table td.td-center {
  text-align: center;
}
.distribution .contactList table td i {
  font-size: 20px;
}

.header {
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}

.right-dash-heading {
  padding: 19px 20px 18px;
  background: #5fbc76;
  border: none;
  color: #fff;
  display: flex;
}

.right-dash-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;
}
.right-dash {
  width: 100%;
}

.right-dash-middle {
  padding: 60px 50px;
}

.dashboard-box {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.dash-box-heading h3 {
  font-size: 18px;
  font-weight: 600;
  float: left;
}
.dash-box-heading span {
  float: right;
  font-size: 18px;
  font-weight: 600;
}
.dash-box-heading {
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}
.dash-box-heading:after {
  content: '';
  display: block;
  clear: both;
}
.box-btm-cont {
  padding: 15px 20px;
}
.box-btm-cont p {
  margin-bottom: 0;
}

.dashboard-chart {
  border: 1px solid #ddd;
  padding: 15px 20px;
  background-color: #ffffff;
}
.dash-fix-content {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.right-dash {
  position: relative;
}

.header {
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}

.right-dash-heading {
  padding: 19px 20px 18px;
  background: #5fbc76;
  border: none;
  color: #fff;
  display: flex;
}

.right-dash-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;
}
.right-dash {
  width: 100%;
}

.dashboard-box {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.dash-box-heading h3 {
  font-size: 18px;
  font-weight: 600;
  float: left;
}
.dash-box-heading span {
  float: right;
  font-size: 18px;
  font-weight: 600;
}
.dash-box-heading {
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}
.dash-box-heading:after {
  content: '';
  display: block;
  clear: both;
}
.box-btm-cont {
  padding: 15px 20px;
}
.box-btm-cont p {
  margin-bottom: 0;
}

.dashboard-chart {
  border: 1px solid #ddd;
  padding: 15px 20px;
  background-color: #ffffff;
}
.dash-fix-content {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.right-dash {
  position: relative;
}
.main-container {
  margin: 0px;
  text-align: left;
}

.faxes {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 30px;
}

.faxes td {
  border-bottom: 1px solid #ddd;
}

.faxbox-container {
  width: 100%;
  display: inline-block;
}

.faxbox-wrapper {
  padding: 24px 0;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.faxbox-wrapper:hover {
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
}

.fax-list {
  display: flex;
  margin-top: 20px;
}

.faxbox {
  width: 100%;
  text-align: center;
}

.faxbox .fax-mailbox > div {
  width: 100%;
  text-align: center;
  height: 85px;
}

.faxbox .fax-mailbox {
  display: flex;
  margin: 0 auto;
  width: 45%;
  color: #808080ed;
}

.newcount {
  color: #33db24 !important;
  float: none !important;
  display: inline-block;
  font-size: 30px;
}

.totalcount {
  color: #151517;
  line-height: 2rem;
  border-radius: 0.2rem;
  padding: 15px 20px 15px 20px;
  margin-right: 1px;
  font-size: 30px;
}

.light .num-title {
  font-size: 14px;
  font-weight: 500;
  display: inherit;
}

.dark .num-title {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  display: inherit;
}

.dark .faxes-top {
  background-color: #6e707a;
}

.light .faxes-top {
  background-color: #ffffff;
}

.dark-faxbox-wrapper {
  background-color: #6e707a;
}

.light-faxbox-wrapper {
  background-color: #ffffff;
}

.faxes-top-1 h1,
.faxes-top-2 h1 {
  margin-bottom: 0;
  font-size: 32px;
  /* color: #151517; */
}

.faxes-top-1 h2,
.faxes-top-2 h2 {
  margin-bottom: 0;
  font-size: 16px;
  /* color: #151517; */
}

.faxes-top-2 {
  border-top: 4px solid #ffffff;
}

.faxes-top-1 {
  border-top: 4px solid #33db24;
  margin-right: 2px;
}

.back-box {
  cursor: pointer;
  font-size: 15px;
  color: gray;
  margin-bottom: 17px;
}

.light .vmbox-title {
  font-size: 23px;
  color: #151517;
  margin-bottom: 24px;
}

.dark .vmbox-title {
  font-size: 23px;
  color: #ffffff;
  margin-bottom: 24px;
}

.fax-top-wrap {
  display: block;
  color: #808080ed;
  margin-bottom: 24px;
}

.faxes-top {
  width: 6rem;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  padding: 15px 20px 15px 20px;
  margin-right: 1px;
  float: left;
}

.mailchange {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.display-file {
  display: block !important;
  padding-top: 3px;
  padding-left: 8px;
}
.fax-table td {
  text-transform: capitalize;
}
.cursor-pointer {
  cursor: pointer;
}

.management {
  padding: 30px;
  display: inline-block;
  width: 100%;
  position: relative;
}
.user-card {
  border-radius: 5px;
  border: 1px solid #cacaca;
  overflow: hidden;
}
.user-card .header {
  background-color: #3db16b;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-card .header img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
}
.user-card .header div {
  margin-top: 20px;
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 210px;
}

.user-card .card-body {
  background-color: white;
}
.user-card .card-body .chart {
  display: flex;
}
.user-card .card-body .chart canvas {
  height: 120px;
}
.user-card .card-body .user-numbers {
  margin-left: 10px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
}
.user-card .card-body span {
  font-size: 12px;
}
.user-card .card-body .footer {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.user-card .card-body .footer i {
  font-size: 25px;
}
.user-edit .icon {
  font-size: 20px;
  margin-right: 10px;
}
.user-edit .extensionIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.change_btn {
  cursor: pointer;
}

.user-name {
  font-size: 18px;
}
.report-table table tbody {
  background: #ffffff;
}
.report-table table thead th {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #3db16b;
  color: #ffffff;
}
.report-table table td {
  vertical-align: middle;
}
.report-table table td {
  text-align: center;
}
.user-list {
  font-size: 18px;
  padding: 12px 5px;
  color: #555;
  border: 1px solid #dcdcdc;
  background: #f6f6f6;
  margin-bottom: 5px;
  padding-left: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bg-primary {
  background: #3db16b !important;
  color: white !important;
}

.bg-primary-bright {
  background: #c0c5e4 !important;
}

.bg-primary-gradient {
  background: -moz- oldlinear-gradient(220deg, #3db16b, #65d993) !important;
  background: linear-gradient(230deg, #3db16b, #65d993) !important;
  color: white !important;
}

.bg-info {
  background: #00bcd4 !important;
  color: white !important;
}

.bg-info-bright {
  background: #e1efff !important;
}

.bg-info-gradient {
  background: -moz- oldlinear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: linear-gradient(230deg, #00bcd4, #28e4fc) !important;
  color: white !important;
}

.bg-secondary {
  background: dimgray !important;
  color: white !important;
}

.bg-secondary-bright {
  background: #ededed !important;
}

.bg-secondary-gradient {
  background: -moz- oldlinear-gradient(220deg, dimgray, #919191) !important;
  background: linear-gradient(230deg, dimgray, #919191) !important;
  color: white !important;
}

.bg-success {
  background: #0abb87 !important;
  color: white !important;
}

.bg-success-bright {
  background: #cef5ea !important;
}

.bg-success-gradient {
  background: -moz- oldlinear-gradient(220deg, #0abb87, #32e3af) !important;
  background: linear-gradient(230deg, #0abb87, #32e3af) !important;
  color: white !important;
}

.bg-danger {
  background: #ea4141 !important;
  color: white !important;
}

.bg-danger-bright {
  background: #fcd0df !important;
}

.bg-danger-gradient {
  background: -moz- oldlinear-gradient(220deg, #ea4141, #ff6969) !important;
  background: linear-gradient(230deg, #ea4141, #ff6969) !important;
  color: white !important;
}

.bg-danger-light {
  background: #ffd7d7 !important;
}

.bg-warning {
  background: #ff9d36 !important;
  color: white !important;
}

.bg-warning-bright {
  background: #ffe6cc !important;
}

.bg-warning-gradient {
  background: -moz- oldlinear-gradient(220deg, #ff9d36, #ffc55e) !important;
  background: linear-gradient(230deg, #ff9d36, #ffc55e) !important;
  color: white !important;
}

.bg-light {
  background: #e6e6e6 !important;
}

.bg-dark {
  background: #3a3f51 !important;
  color: white !important;
}

.bg-dark-bright {
  background: #d4d5d8 !important;
}

.bg-dark-gradient {
  background: -moz- oldlinear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: linear-gradient(230deg, #3a3f51, #585d6f) !important;
  color: white !important;
}

.bg-facebook {
  background: #3b5998 !important;
  color: white !important;
}

.bg-twitter {
  background: #55acee !important;
  color: white !important;
}

.bg-linkedin {
  background: #0077b5 !important;
  color: white !important;
}

.bg-whatsapp {
  background: #43d854 !important;
  color: white !important;
}

.bg-instagram {
  background: #3f729b !important;
  color: white !important;
}

.bg-dribbble {
  background: #ea4c89 !important;
  color: white !important;
}

.bg-google {
  background: #db4437 !important;
  color: white !important;
}

.bg-youtube {
  background: #cd201f !important;
  color: white !important;
}

.text-primary {
  color: #3db16b !important;
}

.text-info {
  color: #00bcd4 !important;
}

.text-success {
  color: #0abb87 !important;
}

.text-danger {
  color: #ea4141 !important;
}

.text-warning {
  color: #ff9d36 !important;
}

.text-light {
  color: #e6e6e6 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #55acee !important;
}

.text-google {
  color: #db4437 !important;
}

.text-linkedin {
  color: #0077b5 !important;
}

.text-instagram {
  color: #3f729b !important;
}

.text-whatsapp {
  color: #43d854 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

a {
  color: #3db16b;
  text-decoration: none;
  transition: color 0.2s;
}

a:not([href]):not([tabindex]) {
  color: #3db16b;
  text-decoration: none;
}

a.link-1 {
  color: black;
}

a.link-1:hover,
a.link-1:focus {
  color: #828282;
}

a.link-2 {
  color: black;
}

a.link-2:hover,
a.link-2:focus {
  color: #3db16b;
}

a:hover {
  text-decoration: none;
}

a:not(.active.list-group-item),
a:not(.btn):hover,
a:not(.btn):active,
a:not(.btn):focus {
  text-decoration: none !important;
  color: #000;
  outline: none;
}

a.btn:hover,
a.btn:active,
a.btn:focus {
  text-decoration: none !important;
}

.btn {
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
}

.btn[data-toggle='dropdown'] {
  align-items: center;
}

.btn.btn-sm {
  padding: 5px 10px;
  font-size: 13px;
}

.btn.btn-lg {
  padding: 15px 20px;
  font-size: 17px;
}

.btn.btn-block {
  width: 100%;
  justify-content: center;
}

.btn.btn-square {
  border-radius: 0;
}

.btn.btn-rounded {
  border-radius: 50px;
  padding: 10px 20px;
}

.btn.btn-rounded.btn-sm {
  padding: 5px 15px;
  font-size: 13px;
}

.btn.btn-rounded.btn-lg {
  padding: 20px 30px;
  font-size: 17px;
}

.btn.btn-floating {
  height: 35px;
  width: 35px;
  align-items: center;
  padding: 0;
  justify-content: center;
  border-radius: 50%;
}

.btn.btn-floating.btn-sm {
  height: 30px;
  width: 30px;
}

.btn.btn-floating.btn-lg {
  height: 50px;
  width: 50px;
}

.btn.btn-uppercase {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  align-items: center;
  font-weight: 600;
}

.btn.btn-uppercase.btn-sm {
  font-size: 11px;
}

.btn.btn-uppercase.btn-lg {
  font-size: 14px;
}

.btn.btn-shadow {
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.3);
}

.btn.btn-shadow:focus,
.btn.btn-shadow:active {
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.3) !important;
}

.btn.btn-primary,
a.btn[href='#next'],
a.btn[href='#previous'] {
  background: #3db16b;
  border-color: #3db16b;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover,
a.btn:not(:disabled):not(.disabled):hover[href='#next'],
a.btn:not(:disabled):not(.disabled):hover[href='#previous'],
.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href='#next'],
a.btn:not(:disabled):not(.disabled):focus[href='#previous'],
.btn.btn-primary:not(:disabled):not(.disabled):active,
a.btn:not(:disabled):not(.disabled):active[href='#next'],
a.btn:not(:disabled):not(.disabled):active[href='#previous'] {
  background: #1f934d;
  border-color: #1f934d;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href='#next'],
a.btn:not(:disabled):not(.disabled):focus[href='#previous'] {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.4) !important;
}

.btn.btn-primary.btn-pulse:not(:disabled):not(.disabled),
a.btn.btn-pulse:not(:disabled):not(.disabled)[href='#next'],
a.btn.btn-pulse:not(:disabled):not(.disabled)[href='#previous'] {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-primary:hover,
a.btn:hover[href='#next'],
a.btn:hover[href='#previous'] {
  -webkit-animation: none;
}

.btn.btn-gradient-primary {
  background: -moz- oldlinear-gradient(70deg, #65d993, #2ea25c);
  background: linear-gradient(20deg, #65d993, #2ea25c);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-primary:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-primary:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-primary:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #3db16b, #2ea25c);
  background: linear-gradient(20deg, #3db16b, #2ea25c);
  border-color: transparent;
}

.btn.btn-gradient-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.5);
}

.btn.btn-gradient-primary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-primary:hover {
  -webkit-animation: none;
}

.btn.btn-light-primary {
  background: rgba(61, 177, 107, 0.3);
  border-color: transparent;
  color: #0b7f39;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):hover,
.btn.btn-light-primary:not(:disabled):not(.disabled):focus,
.btn.btn-light-primary:not(:disabled):not(.disabled):active {
  background: rgba(61, 177, 107, 0.5);
  border-color: transparent;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.2);
}

.btn.btn-light-primary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-primary:hover {
  -webkit-animation: none;
}

.btn.btn-outline-primary {
  background: none;
  border-color: #3db16b;
  color: #33a761;
  border-width: 1px;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):hover {
  background: #3db16b;
  border-color: #3db16b;
  color: white;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):focus,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #1f934d;
  border-color: #1f934d;
  color: white;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.4);
}

.btn.btn-outline-primary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-primary:hover {
  -webkit-animation: none;
}

.btn.btn-secondary {
  background: dimgray;
  border-color: dimgray;
}

.btn.btn-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
}

.btn.btn-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
}

.btn.btn-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-secondary {
  background: -moz- oldlinear-gradient(70deg, #919191, #5a5a5a);
  background: linear-gradient(20deg, #919191, #5a5a5a);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-secondary:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, dimgray, #5a5a5a);
  background: linear-gradient(20deg, dimgray, #5a5a5a);
  border-color: transparent;
}

.btn.btn-gradient-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.5);
}

.btn.btn-gradient-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-light-secondary {
  background: rgba(105, 105, 105, 0.3);
  border-color: transparent;
  color: #373737;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-light-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-light-secondary:not(:disabled):not(.disabled):active {
  background: rgba(105, 105, 105, 0.5);
  border-color: transparent;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.2);
}

.btn.btn-light-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-outline-secondary {
  background: none;
  border-color: dimgray;
  color: #5f5f5f;
  border-width: 1px;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  background: dimgray;
  border-color: dimgray;
  color: white;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
  color: white;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4);
}

.btn.btn-outline-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-success {
  background: #0abb87;
  border-color: #0abb87;
}

.btn.btn-success:not(:disabled):not(.disabled):hover,
.btn.btn-success:not(:disabled):not(.disabled):focus,
.btn.btn-success:not(:disabled):not(.disabled):active {
  background: #009d69;
  border-color: #009d69;
}

.btn.btn-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4) !important;
}

.btn.btn-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-success:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-success {
  background: -moz- oldlinear-gradient(70deg, #32e3af, #00ac78);
  background: linear-gradient(20deg, #32e3af, #00ac78);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-success:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-success:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-success:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #0abb87, #00ac78);
  background: linear-gradient(20deg, #0abb87, #00ac78);
  border-color: transparent;
}

.btn.btn-gradient-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);
}

.btn.btn-gradient-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-success:hover {
  -webkit-animation: none;
}

.btn.btn-light-success {
  background: rgba(10, 187, 135, 0.3);
  border-color: transparent;
  color: #008955;
}

.btn.btn-light-success:not(:disabled):not(.disabled):hover,
.btn.btn-light-success:not(:disabled):not(.disabled):focus,
.btn.btn-light-success:not(:disabled):not(.disabled):active {
  background: rgba(10, 187, 135, 0.5);
  border-color: transparent;
}

.btn.btn-light-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.2);
}

.btn.btn-light-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-success:hover {
  -webkit-animation: none;
}

.btn.btn-outline-success {
  background: none;
  border-color: #0abb87;
  color: #00b17d;
  border-width: 1px;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):hover {
  background: #0abb87;
  border-color: #0abb87;
  color: white;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):focus,
.btn.btn-outline-success:not(:disabled):not(.disabled):active {
  background: #009d69;
  border-color: #009d69;
  color: white;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4);
}

.btn.btn-outline-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-success:hover {
  -webkit-animation: none;
}

.btn.btn-danger {
  background: #ea4141;
  border-color: #ea4141;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover,
.btn.btn-danger:not(:disabled):not(.disabled):focus,
.btn.btn-danger:not(:disabled):not(.disabled):active {
  background: #cc2323;
  border-color: #cc2323;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.4) !important;
}

.btn.btn-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-danger:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-danger {
  background: -moz- oldlinear-gradient(70deg, #ff6969, #db3232);
  background: linear-gradient(20deg, #ff6969, #db3232);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-danger:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-danger:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-danger:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #ea4141, #db3232);
  background: linear-gradient(20deg, #ea4141, #db3232);
  border-color: transparent;
}

.btn.btn-gradient-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.5);
}

.btn.btn-gradient-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-danger:hover {
  -webkit-animation: none;
}

.btn.btn-light-danger {
  background: rgba(234, 65, 65, 0.3);
  border-color: transparent;
  color: #b80f0f;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):hover,
.btn.btn-light-danger:not(:disabled):not(.disabled):focus,
.btn.btn-light-danger:not(:disabled):not(.disabled):active {
  background: rgba(234, 65, 65, 0.5);
  border-color: transparent;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.2);
}

.btn.btn-light-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-danger:hover {
  -webkit-animation: none;
}

.btn.btn-outline-danger {
  background: none;
  border-color: #ea4141;
  color: #e03737;
  border-width: 1px;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):hover {
  background: #ea4141;
  border-color: #ea4141;
  color: white;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):focus,
.btn.btn-outline-danger:not(:disabled):not(.disabled):active {
  background: #cc2323;
  border-color: #cc2323;
  color: white;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.4);
}

.btn.btn-outline-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-danger:hover {
  -webkit-animation: none;
}

.btn.btn-warning {
  background: #ff9d36;
  border-color: #ff9d36;
}

.btn.btn-warning:not(:disabled):not(.disabled):hover,
.btn.btn-warning:not(:disabled):not(.disabled):focus,
.btn.btn-warning:not(:disabled):not(.disabled):active {
  background: #e17f18;
  border-color: #e17f18;
}

.btn.btn-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.4) !important;
}

.btn.btn-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-warning:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-warning {
  background: -moz- oldlinear-gradient(70deg, #ffc55e, #f08e27);
  background: linear-gradient(20deg, #ffc55e, #f08e27);
  border-color: transparent;
  color: white;
  color: #212529;
}

.btn.btn-gradient-warning:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-warning:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-warning:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #ff9d36, #f08e27);
  background: linear-gradient(20deg, #ff9d36, #f08e27);
  border-color: transparent;
}

.btn.btn-gradient-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.5);
}

.btn.btn-gradient-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-warning:hover {
  -webkit-animation: none;
}

.btn.btn-light-warning {
  background: rgba(255, 157, 54, 0.3);
  border-color: transparent;
  color: #cd6b04;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):hover,
.btn.btn-light-warning:not(:disabled):not(.disabled):focus,
.btn.btn-light-warning:not(:disabled):not(.disabled):active {
  background: rgba(255, 157, 54, 0.5);
  border-color: transparent;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.2);
}

.btn.btn-light-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-warning:hover {
  -webkit-animation: none;
}

.btn.btn-outline-warning {
  background: none;
  border-color: #ff9d36;
  color: #f5932c;
  border-width: 1px;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):hover {
  background: #ff9d36;
  border-color: #ff9d36;
  color: white;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):focus,
.btn.btn-outline-warning:not(:disabled):not(.disabled):active {
  background: #e17f18;
  border-color: #e17f18;
  color: white;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.4);
}

.btn.btn-outline-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-warning:hover {
  -webkit-animation: none;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):hover,
.btn.btn-outline-warning:not(:disabled):not(.disabled):focus,
.btn.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #212529;
}

.btn.btn-info {
  background: #00bcd4;
  border-color: #00bcd4;
}

.btn.btn-info:not(:disabled):not(.disabled):hover,
.btn.btn-info:not(:disabled):not(.disabled):focus,
.btn.btn-info:not(:disabled):not(.disabled):active {
  background: #009eb6;
  border-color: #009eb6;
}

.btn.btn-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
}

.btn.btn-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-info:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-info {
  background: -moz- oldlinear-gradient(70deg, #28e4fc, #00adc5);
  background: linear-gradient(20deg, #28e4fc, #00adc5);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-info:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-info:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-info:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #00bcd4, #00adc5);
  background: linear-gradient(20deg, #00bcd4, #00adc5);
  border-color: transparent;
}

.btn.btn-gradient-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}

.btn.btn-gradient-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-info:hover {
  -webkit-animation: none;
}

.btn.btn-light-info {
  background: rgba(0, 188, 212, 0.3);
  border-color: transparent;
  color: #008aa2;
}

.btn.btn-light-info:not(:disabled):not(.disabled):hover,
.btn.btn-light-info:not(:disabled):not(.disabled):focus,
.btn.btn-light-info:not(:disabled):not(.disabled):active {
  background: rgba(0, 188, 212, 0.5);
  border-color: transparent;
}

.btn.btn-light-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.2);
}

.btn.btn-light-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-info:hover {
  -webkit-animation: none;
}

.btn.btn-outline-info {
  background: none;
  border-color: #00bcd4;
  color: #00b2ca;
  border-width: 1px;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):hover {
  background: #00bcd4;
  border-color: #00bcd4;
  color: white;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):focus,
.btn.btn-outline-info:not(:disabled):not(.disabled):active {
  background: #009eb6;
  border-color: #009eb6;
  color: white;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4);
}

.btn.btn-outline-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-info:hover {
  -webkit-animation: none;
}

.btn.btn-light {
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.btn.btn-light:not(:disabled):not(.disabled):hover,
.btn.btn-light:not(:disabled):not(.disabled):focus,
.btn.btn-light:not(:disabled):not(.disabled):active {
  background: #c8c8c8;
  border-color: #c8c8c8;
}

.btn.btn-light:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
}

.btn.btn-light.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-light {
  background: -moz- oldlinear-gradient(70deg, white, #d7d7d7);
  background: linear-gradient(20deg, white, #d7d7d7);
  border-color: transparent;
  color: white;
  color: inherit;
}

.btn.btn-gradient-light:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-light:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-light:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #e6e6e6, #d7d7d7);
  background: linear-gradient(20deg, #e6e6e6, #d7d7d7);
  border-color: transparent;
}

.btn.btn-gradient-light:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.5);
}

.btn.btn-gradient-light.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-light:hover {
  -webkit-animation: none;
}

.btn.btn-outline-light {
  background: none;
  border-color: #e6e6e6;
  color: gainsboro;
  border-width: 1px;
  color: #212529;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):hover {
  background: #e6e6e6;
  border-color: #e6e6e6;
  color: white;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):focus,
.btn.btn-outline-light:not(:disabled):not(.disabled):active {
  background: #c8c8c8;
  border-color: #c8c8c8;
  color: white;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4);
}

.btn.btn-outline-light.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-light:hover {
  -webkit-animation: none;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):hover,
.btn.btn-outline-light:not(:disabled):not(.disabled):focus,
.btn.btn-outline-light:not(:disabled):not(.disabled):active {
  color: #212529;
}

.btn.btn-dark {
  background: #3a3f51;
  border-color: #3a3f51;
}

.btn.btn-dark:not(:disabled):not(.disabled):hover,
.btn.btn-dark:not(:disabled):not(.disabled):focus,
.btn.btn-dark:not(:disabled):not(.disabled):active {
  background: #1c2133;
  border-color: #1c2133;
}

.btn.btn-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4) !important;
}

.btn.btn-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-dark:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-dark {
  background: -moz- oldlinear-gradient(70deg, #626779, #2b3042);
  background: linear-gradient(20deg, #626779, #2b3042);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-dark:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-dark:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-dark:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #3a3f51, #2b3042);
  background: linear-gradient(20deg, #3a3f51, #2b3042);
  border-color: transparent;
}

.btn.btn-gradient-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5);
}

.btn.btn-gradient-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-dark:hover {
  -webkit-animation: none;
}

.btn.btn-light-dark {
  background: rgba(58, 63, 81, 0.3);
  border-color: transparent;
  color: #080d1f;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):hover,
.btn.btn-light-dark:not(:disabled):not(.disabled):focus,
.btn.btn-light-dark:not(:disabled):not(.disabled):active {
  background: rgba(58, 63, 81, 0.5);
  border-color: transparent;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.2);
}

.btn.btn-light-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-dark:hover {
  -webkit-animation: none;
}

.btn.btn-outline-dark {
  background: none;
  border-color: #3a3f51;
  color: #303547;
  border-width: 1px;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):hover {
  background: #3a3f51;
  border-color: #3a3f51;
  color: white;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):focus,
.btn.btn-outline-dark:not(:disabled):not(.disabled):active {
  background: #1c2133;
  border-color: #1c2133;
  color: white;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4);
}

.btn.btn-outline-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-dark:hover {
  -webkit-animation: none;
}

.btn.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: white;
}

.btn.btn-facebook:not(:disabled):not(.disabled):hover,
.btn.btn-facebook:not(:disabled):not(.disabled):focus,
.btn.btn-facebook:not(:disabled):not(.disabled):active {
  background: #1d3b7a;
  border-color: #1d3b7a;
}

.btn.btn-facebook:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4) !important;
}

.btn.btn-facebook.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-facebook:hover {
  -webkit-animation: none;
}

.btn.btn-outline-facebook {
  background: none;
  border-color: #3b5998;
  color: #314f8e;
  border-width: 1px;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):hover {
  background: #3b5998;
  border-color: #3b5998;
  color: white;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):focus,
.btn.btn-outline-facebook:not(:disabled):not(.disabled):active {
  background: #1d3b7a;
  border-color: #1d3b7a;
  color: white;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4);
}

.btn.btn-outline-facebook.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-facebook:hover {
  -webkit-animation: none;
}

.btn.btn-google {
  background: #db4437;
  border-color: #db4437;
  color: white;
}

.btn.btn-google:not(:disabled):not(.disabled):hover,
.btn.btn-google:not(:disabled):not(.disabled):focus,
.btn.btn-google:not(:disabled):not(.disabled):active {
  background: #bd2619;
  border-color: #bd2619;
}

.btn.btn-google:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
}

.btn.btn-google.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-google:hover {
  -webkit-animation: none;
}

.btn.btn-outline-google {
  background: none;
  border-color: #db4437;
  color: #d13a2d;
  border-width: 1px;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):hover {
  background: #db4437;
  border-color: #db4437;
  color: white;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):focus,
.btn.btn-outline-google:not(:disabled):not(.disabled):active {
  background: #bd2619;
  border-color: #bd2619;
  color: white;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4);
}

.btn.btn-outline-google.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-google:hover {
  -webkit-animation: none;
}

.btn.btn-twitter {
  background: #55acee;
  border-color: #55acee;
  color: white;
}

.btn.btn-twitter:not(:disabled):not(.disabled):hover,
.btn.btn-twitter:not(:disabled):not(.disabled):focus,
.btn.btn-twitter:not(:disabled):not(.disabled):active {
  background: #378ed0;
  border-color: #378ed0;
}

.btn.btn-twitter:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
}

.btn.btn-twitter.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-twitter:hover {
  -webkit-animation: none;
}

.btn.btn-outline-twitter {
  background: none;
  border-color: #55acee;
  color: #4ba2e4;
  border-width: 1px;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):hover {
  background: #55acee;
  border-color: #55acee;
  color: white;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):focus,
.btn.btn-outline-twitter:not(:disabled):not(.disabled):active {
  background: #378ed0;
  border-color: #378ed0;
  color: white;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4);
}

.btn.btn-outline-twitter.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-twitter:hover {
  -webkit-animation: none;
}

.btn.btn-linkedin {
  background: #0077b5;
  border-color: #0077b5;
  color: white;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):hover,
.btn.btn-linkedin:not(:disabled):not(.disabled):focus,
.btn.btn-linkedin:not(:disabled):not(.disabled):active {
  background: #005997;
  border-color: #005997;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
}

.btn.btn-linkedin.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-linkedin:hover {
  -webkit-animation: none;
}

.btn.btn-outline-linkedin {
  background: none;
  border-color: #0077b5;
  color: #006dab;
  border-width: 1px;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):hover {
  background: #0077b5;
  border-color: #0077b5;
  color: white;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):focus,
.btn.btn-outline-linkedin:not(:disabled):not(.disabled):active {
  background: #005997;
  border-color: #005997;
  color: white;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4);
}

.btn.btn-outline-linkedin.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-linkedin:hover {
  -webkit-animation: none;
}

.btn.btn-whatsapp {
  background: #43d854;
  border-color: #43d854;
  color: white;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):hover,
.btn.btn-whatsapp:not(:disabled):not(.disabled):focus,
.btn.btn-whatsapp:not(:disabled):not(.disabled):active {
  background: #25ba36;
  border-color: #25ba36;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
}

.btn.btn-whatsapp.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-whatsapp:hover {
  -webkit-animation: none;
}

.btn.btn-outline-whatsapp {
  background: none;
  border-color: #43d854;
  color: #39ce4a;
  border-width: 1px;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):hover {
  background: #43d854;
  border-color: #43d854;
  color: white;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):focus,
.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):active {
  background: #25ba36;
  border-color: #25ba36;
  color: white;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4);
}

.btn.btn-outline-whatsapp.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-whatsapp:hover {
  -webkit-animation: none;
}

.btn.btn-instagram {
  background: #3f729b;
  border-color: #3f729b;
  color: white;
}

.btn.btn-instagram:not(:disabled):not(.disabled):hover,
.btn.btn-instagram:not(:disabled):not(.disabled):focus,
.btn.btn-instagram:not(:disabled):not(.disabled):active {
  background: #21547d;
  border-color: #21547d;
}

.btn.btn-instagram:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
}

.btn.btn-instagram.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-instagram:hover {
  -webkit-animation: none;
}

.btn.btn-outline-instagram {
  background: none;
  border-color: #3f729b;
  color: #356891;
  border-width: 1px;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):hover {
  background: #3f729b;
  border-color: #3f729b;
  color: white;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):focus,
.btn.btn-outline-instagram:not(:disabled):not(.disabled):active {
  background: #21547d;
  border-color: #21547d;
  color: white;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4);
}

.btn.btn-outline-instagram.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-instagram:hover {
  -webkit-animation: none;
}

.btn.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: white;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):hover,
.btn.btn-dribbble:not(:disabled):not(.disabled):focus,
.btn.btn-dribbble:not(:disabled):not(.disabled):active {
  background: #cc2e6b;
  border-color: #cc2e6b;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
}

.btn.btn-dribbble.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-dribbble:hover {
  -webkit-animation: none;
}

.btn.btn-outline-dribbble {
  background: none;
  border-color: #ea4c89;
  color: #e0427f;
  border-width: 1px;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):hover {
  background: #ea4c89;
  border-color: #ea4c89;
  color: white;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):focus,
.btn.btn-outline-dribbble:not(:disabled):not(.disabled):active {
  background: #cc2e6b;
  border-color: #cc2e6b;
  color: white;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4);
}

.btn.btn-outline-dribbble.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-dribbble:hover {
  -webkit-animation: none;
}

.btn.btn-youtube {
  background: #cd201f;
  border-color: #cd201f;
  color: white;
}

.btn.btn-youtube:not(:disabled):not(.disabled):hover,
.btn.btn-youtube:not(:disabled):not(.disabled):focus,
.btn.btn-youtube:not(:disabled):not(.disabled):active {
  background: #af0201;
  border-color: #af0201;
}

.btn.btn-youtube:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4) !important;
}

.btn.btn-youtube.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-youtube:hover {
  -webkit-animation: none;
}

.btn.btn-outline-youtube {
  background: none;
  border-color: #cd201f;
  color: #c31615;
  border-width: 1px;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):hover {
  background: #cd201f;
  border-color: #cd201f;
  color: white;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):focus,
.btn.btn-outline-youtube:not(:disabled):not(.disabled):active {
  background: #af0201;
  border-color: #af0201;
  color: white;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4);
}

.btn.btn-outline-youtube.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-youtube:hover {
  -webkit-animation: none;
}

.btn.btn-github {
  background: #00405d;
  border-color: #00405d;
  color: white;
}

.btn.btn-github:not(:disabled):not(.disabled):hover,
.btn.btn-github:not(:disabled):not(.disabled):focus,
.btn.btn-github:not(:disabled):not(.disabled):active {
  background: #00223f;
  border-color: #00223f;
}

.btn.btn-github:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4) !important;
}

.btn.btn-github.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-github:hover {
  -webkit-animation: none;
}

.btn.btn-outline-github {
  background: none;
  border-color: #00405d;
  color: #003653;
  border-width: 1px;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):hover {
  background: #00405d;
  border-color: #00405d;
  color: white;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):focus,
.btn.btn-outline-github:not(:disabled):not(.disabled):active {
  background: #00223f;
  border-color: #00223f;
  color: white;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4);
}

.btn.btn-outline-github.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-github:hover {
  -webkit-animation: none;
}

.btn.btn-behance {
  background: #1769ff;
  border-color: #1769ff;
  color: white;
}

.btn.btn-behance:not(:disabled):not(.disabled):hover,
.btn.btn-behance:not(:disabled):not(.disabled):focus,
.btn.btn-behance:not(:disabled):not(.disabled):active {
  background: #004be1;
  border-color: #004be1;
}

.btn.btn-behance:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
}

.btn.btn-behance.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-behance:hover {
  -webkit-animation: none;
}

.btn.btn-outline-behance {
  background: none;
  border-color: #1769ff;
  color: #0d5ff5;
  border-width: 1px;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):hover {
  background: #1769ff;
  border-color: #1769ff;
  color: white;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):focus,
.btn.btn-outline-behance:not(:disabled):not(.disabled):active {
  background: #004be1;
  border-color: #004be1;
  color: white;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4);
}

.btn.btn-outline-behance.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-behance:hover {
  -webkit-animation: none;
}

.btn.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: white;
}

.btn.btn-skype:not(:disabled):not(.disabled):hover,
.btn.btn-skype:not(:disabled):not(.disabled):focus,
.btn.btn-skype:not(:disabled):not(.disabled):active {
  background: #0091d2;
  border-color: #0091d2;
}

.btn.btn-skype:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4) !important;
}

.btn.btn-skype.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-skype:hover {
  -webkit-animation: none;
}

.btn.btn-outline-skype {
  background: none;
  border-color: #00aff0;
  color: #00a5e6;
  border-width: 1px;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):hover {
  background: #00aff0;
  border-color: #00aff0;
  color: white;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):focus,
.btn.btn-outline-skype:not(:disabled):not(.disabled):active {
  background: #0091d2;
  border-color: #0091d2;
  color: white;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4);
}
.btn.btn-outline-skype.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
}
.btn.btn-yahoo {
  background: #410093;
  border-color: #410093;
  color: white;
}
.btn.btn-yahoo:not(:disabled):not(.disabled):hover,
.btn.btn-yahoo:not(:disabled):not(.disabled):focus,
.btn.btn-yahoo:not(:disabled):not(.disabled):active {
  background: #230075;
  border-color: #230075;
}

.btn.btn-yahoo:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4) !important;
}

.btn.btn-yahoo.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-yahoo:hover {
  -webkit-animation: none;
}

.btn.btn-outline-yahoo {
  background: none;
  border-color: #410093;
  color: #370089;
  border-width: 1px;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):hover {
  background: #410093;
  border-color: #410093;
  color: white;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):focus,
.btn.btn-outline-yahoo:not(:disabled):not(.disabled):active {
  background: #230075;
  border-color: #230075;
  color: white;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4);
}

.btn.btn-outline-yahoo.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-yahoo:hover {
  -webkit-animation: none;
}

.btn.btn-apple,
.btn.btn-google-play {
  border-radius: 7px;
}

.btn.btn-apple img,
.btn.btn-google-play img {
  width: 35px;
  margin-right: 10px;
}

.btn.btn-apple i,
.btn.btn-google-play i {
  font-size: 40px;
  margin-right: 10px;
}

.btn.btn-apple > span,
.btn.btn-google-play > span {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.btn.btn-apple > span span:nth-child(2),
.btn.btn-google-play > span span:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

.btn.btn-apple:hover,
.btn.btn-apple:active,
.btn.btn-apple:focus,
.btn.btn-google-play:hover,
.btn.btn-google-play:active,
.btn.btn-google-play:focus {
  background: #040507;
  color: white;
}

.btn.btn-apple {
  border: 1px solid #040507;
  color: #040507;
}

.btn.btn-google-play {
  background: #040507;
  color: white;
}

.btn.btn-google-play > span span:nth-child(1) {
  text-transform: uppercase;
  font-size: 12px;
}

.form-control {
  font-size: 14px;
}

.form-control {
  border-radius: 5px;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.05rem #3db16b;
}

.dark-modal .modal-content {
  background-color: #464852 !important;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  overflow: visible !important;
}

.dark-modal .modal-content .modal-header {
  background-color: #5a5c66 !important;
}

.dark-modal .modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.dark-modal .modal-content .modal-header {
  align-items: center;
  padding: 20px 30px;
  background-color: whitesmoke;
  border-bottom: none;
}

.dark-modal .modal-content .modal-header .modal-title {
  display: flex;
  align-items: center;
  line-height: 13px;
  font-weight: 600;
}

.dark-modal .modal-content .modal-header .modal-title i,
.dark-modal .modal-content .modal-header .modal-title figure.avatar {
  margin-right: 0.7rem;
  font-size: 17px;
}

.dark-modal .modal-content .modal-header .modal-title + button {
  text-shadow: none;
  font-size: 14px;
  margin: 0;
  background-color: #e1e1e1;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
}

.dark-modal .modal-content .modal-body {
  padding: 15px 30px 30px 30px;
}

.dark-modal .modal-content .modal-footer {
  border-top: none;
  padding: 30px;
  padding-top: 0;
}

.dark-modal .modal-open .layout {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.dark-modal .modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.5);
  transform: translate(0, 0) scale(0.5);
}

.dark-modal .modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}

.dark-modal .nav-tabs .nav-link.active {
  border-color: #5a5c66;
  border-bottom-color: #464852;
  background-color: #464852;
  color: rgba(255, 255, 255, 0.7);
}

.dark-modal .nav-tabs .nav-link:hover,
.dark-modal .nav-tabs .nav-link:focus {
  border-color: #5a5c66;
  border-bottom-color: #464852;
}

.dark-modal .nav-tabs {
  border-bottom-color: #5a5c66;
}

.light-modal .modal-content {
  background-color: #ffffff !important;
  font-size: 14px;
  overflow: visible !important;
}

.light-modal .modal-content .modal-header {
  background-color: #f5f5f5 !important;
}

.light-modal .modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.assign-modal .modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  overflow: hidden;
  width: 410px;
}
.assign-body .modal-body {
  height: 550px;
  overflow-y: auto;
  width: 100%;
}

.light-modal .modal-content .modal-header {
  align-items: center;
  padding: 20px 30px;
  background-color: whitesmoke;
  border-bottom: none;
}
.assign-modal .modal-content .modal-header {
  align-items: center;
  padding: 20px 30px;
  background-color: white !important;
  border-bottom: 1px solid #dee2e6;
}
.assign-modal .modal-content .modal-title {
  font-style: normal;
  font-weight: 900 !important;
  font-size: 18px;
  color: #181c2f;
}

.light-modal .modal-content .modal-header .modal-title {
  display: flex;
  align-items: center;
  line-height: 13px;
  font-weight: 600;
}

.light-modal .modal-content .modal-header .modal-title i,
.light-modal .modal-content .modal-header .modal-title figure.avatar {
  margin-right: 0.7rem;
  font-size: 17px;
}

.light-modal .modal-content .modal-header .modal-title + button {
  text-shadow: none;
  font-size: 14px;
  margin: 0;
  background-color: #e1e1e1;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
}

.light-modal .modal-content .modal-body {
  padding: 15px 30px 30px 30px;
}

.light-modal .modal-content .modal-footer {
  border-top: none;
  padding: 30px;
  padding-top: 0;
}
.assign-footer {
  border-top: 1px solid #dee2e6 !important;
  padding: 30px !important;
  padding-top: 25px !important;
  min-height: 106px;
}
.assign-footer .btn.disabled {
  background: #dbdbdb;
  border-radius: 5px;
  border-color: transparent !important;
}
.assign-footer-btn {
  background-color: #fff !important;
  color: #666666;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}
.assign-footer-btn:hover {
  color: #666666 !important;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.light-modal .modal-open .layout {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.light-modal .modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.5);
  transform: translate(0, 0) scale(0.5);
}

.light-modal .modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}

.light-modal .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.light-modal .sms-header.nav-tabs .nav-link.active {
  color: #5fbc76;
  background-color: #fff;
  border: none !important;
  border-bottom: 1px solid #5fbc76 !important;
}

.light-modal .nav-tabs .nav-link:hover,
.light-modal .nav-tabs .nav-link:focus {
  border-color: #dee2e6;
  border-bottom-color: #dee2e6;
}

.light-modal .sms-header.nav-tabs .nav-link:hover {
  border: none;
}
.light-modal .sms-header.nav-tabs .nav-link:focus {
  border: none;
  border-bottom: 1px solid #5fbc76;
}

.light-modal .nav-tabs {
  border-bottom-color: #dee2e6;
}

.dark-modal input[type='text'],
.dark-modal textarea,
.dark-modal select {
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
  border-color: #6e707a;
}

.dark-modal input[type='text']:focus,
.dark-modal textarea:focus,
.dark-modal select:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: #5a5c66;
  border-color: #aaacb6;
}

.light-modal input[type='text'],
.light-modal textarea,
.light-modal select {
  background-color: #ffffff;
  /* color: #212529;
  border-color: #6e707a; */
}
.light-modal input[type='text']:disabled,
.light-modal textarea:disabled,
.light-modal select:disabled {
  border-color: hsl(0, 0%, 70%);
}

.light-modal input[type='text']:focus,
.light-modal textarea:focus,
.light-modal select:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #aaacb6;
}
.input-range__label--min {
  display: none;
}
.input-range__label--max {
  display: none;
}
.check-ring-state {
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-user {
  display: flex;
  height: 100vh;
}

.layout-user .content {
  flex: 1 1;
  margin-left: 70px;
  flex-direction: column;
  overflow: auto;
}

.initiating-call.modal-body {
  font-size: 20px;
  font-weight: 600;
}

.react-datetime-picker__wrapper {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 0px 10px;
}
.custom-switch {
  z-index: 0;
}

.contact-name {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}
.liststwo {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  padding: 8px;
}

.infoname {
  margin-left: 10px;
}

.grpbox {
  padding-inline: 8px;
  padding-block: 4px;
  border: 1px solid #9ca3af;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.boxName {
  margin: 0px;
  margin-left: 4px;
  font-size: 12px;
  color: #9ca3af;
}

.main {
  display: flex;
  align-items: flex-start;
  flex: 1 1;
  padding: 30px;
}
.card-head {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px 0px 15px;
}

.img {
  border-radius: 50%;
  object-fit: contain;
}

.titleDiv {
  margin-left: 15px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.detail-name {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.chipsDiv {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.chipsinnerDiv {
  display: flex;
  align-items: center;
  flex: 1 1;
  text-align: center;
}

.displaychips {
  margin-right: 8px;
  padding: 2px;
  text-align: center;
}

.popBox {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnEdit {
  background-color: #22c55e;
  color: white;
  padding-inline: 12px;
  padding-block: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.headerTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 10px;
  margin-left: 0px;
}

.contactList {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  margin: 10px;
  margin-left: 0px;
}

.contactDetailContainer {
  padding-left: 10px;
}

.vertical {
  border-right: 1px solid #d1d5db;
  height: 435px;
  overflow-x: hidden;
}

.recent-msg {
  height: 435px;
  overflow-x: hidden;
}

.data {
  font-size: 10px;
  font-weight: 300;
  /* margin-left: 20px; */
}
.lists {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 20px;
  margin: 10px;
}

.cardTitle {
  font-size: 14px;
  font-weight: 600;
  padding-left: 1%;
}

.contactDetailContainer .headerTitle {
  margin-bottom: 25px;
}

.listValue {
  font-size: 15px;
  font-weight: 400;
  padding-left: 20px;
  padding: 0px 25px 25px;
  margin: 10px;
}

.lable {
  margin: 8;
  margin-left: 4;
  font-size: 12;
  color: #9ca3af;
}

.boxdata {
  height: 12px;
  width: 20px;
  border-radius: 8px;
}

.right-middle-data {
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.bodyData {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: 30px;
}

.bodyData .container {
  padding: 0 5px;
  max-width: 100%;
}

.listvaluedata {
  margin: 5px;
}
.checkboxInput {
  margin: 5px;
  padding: 10px;
}

.checkbox:checked:before {
  background-color: red;
}

.maindata {
  background-color: red;
}

.errorMsg {
  padding-left: 12px;
}

.colData {
  padding-left: 0px;
  padding-right: 5px;
}

.colMsgData {
  padding-left: 0px;
  padding-right: 15px;
}
.customized{
  flex: 19% 1;
  max-width: 100%;
}
.notePrivate{
  margin: 6px;
}

.checkboxstyle {
  margin: 6px;
  padding: 10px;
}

.drop-distributionData {
  border-bottom: 1px solid #ddd;
  display: flex;
  margin: 12px 16px;
}

.profileimgs {
  border-radius: 50%;
  object-fit: contain;
}

.dist-badge-data {
  width: 13%;
  display: block !important;
  padding: 10px !important;
  margin-right: 10px !important;
  border-radius: 7px !important;
}
.drop-checkbox {
  width: auto !important;
  margin: 6px;
  padding: 10px;
}

.backArrowBtn {
  margin: 10px;
  background-color: #22c55e;
  border: none;
  color: white;
  padding-inline: 12px;
  padding-block: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  width: auto;
}

.street {
  display: flex;
}
.addressContact {
  margin-top: -5px;
  padding-left: 5px;
}

.addressList {
  margin: 0 0 0 0;
}

.layout-user {
  display: flex;
  height: 100vh;
}

.layout-user .content {
  flex: 1 1;
  margin-left: 70px;
  flex-direction: column;
  overflow: auto;
  display: flex;
}

.user-body {
  margin: 10px;
}

.Unread-message {
  padding: 100px;
  font-size: 29px;
  cursor: pointer;
}

.call-devices {
  list-style: none;
  display: flex;
  margin: 10px;
  font-size: 20px;
  justify-content: center;
}


.call-Desk-count {
  color: blue;
}
.call-Soft-count {
  color: gold;
}

.call-des{
  /* padding: 20px; */
  margin: 20px;
  font-size: 15px;
}


.voice-mail-box{
  cursor: pointer;
  padding: 35px;
}
.CallDevices-box {
  border: 1px solid #ddd;
  /* margin-bottom: 30px; */
  background-color: #ffffff;
  height: 97%;
}

.PhoneCalls-box{
  border: 1px solid #ddd;
  background-color: #ffffff;
  height:97%;
}


.pie-data{
  display: flex;
  align-items: center;
}


.voice-main{
    display: flex;
    flex-direction: column;
    grid-gap: 8;
    gap: 8;
}


.voice-card{
  display: flex;
  align-items: center;
  grid-gap: 4;
  gap: 4;
}
.no-voice-mail-div{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 273px;
}

.no-data-text{
  font-size: 20px;
}

.textcall{
    padding-left: 13px;
}




.campaign {
  padding: 30px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.brand-of-section {
  background-color: #3db16b;
  height: 60px;
  border: 1px solid gray;
  border-radius: 10px;
  color: white;
  text-align: center;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
}

.brand-inner {
  margin: 15px;
}

.report-table-content table tbody {
  background: #ffffff;
}

.report-table-content table thead th {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #3db16b;
  color: #ffffff;
}

.report-table-content table td {
  vertical-align: middle;
}

.report-table-content table td {
  text-align: center;
}

.Relation-box {
  text-align: center;
  height: 100px;
  padding: 20px 15px;
  box-sizing: border-box;
  background-color: #3db16b;
  border-radius: 0 0 10px 10px;
  margin: 10px;
  cursor: pointer;
}

.drop-down-ui {
  flex: 0 0 50%;
  max-width: 50%;
  border-radius: 5px;
}

.brand-relation-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100px;
  border-radius: 12px;
  border: '5px solid red';
  margin-top: 6px;
  flex-direction: row-reverse;
}

.brand-rel-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 80px;
  border: 1px solid #3db16b;
  border-radius: 1%;
  flex-direction: row-reverse;
  margin-top: 10px;
  align-items: center;
  padding: 10px;
  width: 700px;
}

.brand-title {
  display: flex;
  align-items: center;
  /* color: red; */
  text-align: center;
  margin-left: 30%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  list-style: none;
  margin: 10px;
}

.pagination ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.pagination ul li {
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
  font-weight: 600;
  cursor: pointer;
}

.pagination ul li a {
  padding-inline: 20px;
  padding-block: 12px;
  display: block;
}

.pagination ul li:hover {
  background-color: #3db16b;
  border-color: #3db16b;
  color: white;
}

.pagination ul li.disabled {
  cursor: not-allowed;
}

.pagination ul li.disabled:hover {
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
  color: black;
}

.pagination ul li.selected {
  background-color: #3db16b;
  border-color: #3db16b;
  color: white;
}

.pagination ul li:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.pagination ul li:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.box-data {
  margin: 0 40px;
  padding: 0;
  background-color: #fff;
  height: 30%;
}

.main {
  position: relative;
  padding: 10px 10px 20px;
  margin: 20px 0px;
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
}

.box-inner {
  text-align: center;
  height: 105px;
  padding: 20px 15px;
  box-sizing: border-box;
  background: rgba(243, 244, 244, 0.75);
  border-radius: 0 0 10px 10px;
  float: left;
  width: 33.33%;
  padding: 10px;
}

.title-desk {
  max-height: 60px;
  text-align: center;
  padding: 30px 0 12px;
  background: rgb(48, 179, 101);
  border-radius: 10px 10px 0 0;
  width: 172px;
  margin-top: 15px;
  color: white;
}

.box-detalis {
  text-align: center;
  height: 105px;
  padding: 20px 15px;
  box-sizing: border-box;
  background: rgba(243, 244, 244, 0.75);
  border-radius: 0 0 10px 10px;
  width: 172px;
}

.tag {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: black;
}

.back-color {
  background-color: white;
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  margin: 0;
}

.title-of-form {
  font-size: 15px;
  font-weight: bold;
  margin: 10px;
}

.loading-text {
  text-align: center;
  margin-block: 8px;
  font-size: 25px;
}

.campings-part {
  margin: 20px;
  padding: 20px;
  background-color: red;
}

.heading-block {
  position: relative;
  margin-bottom: 18px;
}
.heading1 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #49535d;
  font-family: 'Roboto';
}
.box-data {
  /* flex-grow: 0; */
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 8px;
  /* flex-basis: 100%; */
}

.usecase {
  max-width: 100%;
}
.remove-extra-style {
  list-style-type: none;
}

.list-nested {
  background: #fff;
  padding: 5px;
  /* padding: 10px 5px; */
  transition: all 0.3s;
}
.main-box-data {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #c0ccd4;
}

.heading1 {
  margin-left: 35px;
  margin-top: 2px;
}

.wrapper-preview {
  border: 1px solid #c0ccd4;
  padding: 25px 30px;
  margin-bottom: 30px;
}

.heading-block {
  position: relative;
  margin-bottom: 18px;
}

.paragraph {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #68737a;
  font-family: 'Roboto';
}

.listing-data {
  position: relative;
  padding-left: 40px;
}

.main-div-data {
  color: #fff;
  font-weight: 500;
  padding: 40px 10px;
  background: #0091b3;
  text-align: center;
  font-size: 12px;
}

.main-div-data-inner {
  color: #fff;
  font-weight: 500;
  padding: 40px 10px;
  background: red;
  text-align: center;
  font-size: 12px;
}

.tddata {
  background-color: rgb(48, 179, 101);
  color: white;
}

.form-rapper{
  background-color: white;
  border-radius: 26px;
}

.wrap-cam-card {
  margin: 10px;
  padding: 10px;
}

.use-start-case {
  margin-right: 10%;
  padding-left: 10%;
}

.text-display {
  /* text-align: center; */
  margin-top: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: center;
}
.display-map-data {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.mno-listing .list-unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ul-data-added {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 10px;
  /* color: #0091b3;
    background: rgba(0,145,179,.1);
    padding: 12px 20px 15px;
    height: 50px;
    box-sizing: border-box; */
}

.art-data {
  margin: 10px;
  /* color: #fff;
    font-weight: 500;
    padding: 40px 10px;
    background: #0091b3;
    text-align: center;
    font-size: 12px; */
}

.render-section {
  color: #0091b3;
  background: rgba(0, 145, 179, 0.1);
  padding: 12px 20px 15px;
  height: 50px;
  box-sizing: border-box;
}

.media-box {
  width: 100%;
  border: 1px solid #c0ccd4;
  height: 275px;
  display: flex;
  position: relative;
  background: rgba(0, 145, 179, 0.06);
  box-sizing: border-box;
  align-items: center;
  padding-top: 52px;
  flex-direction: column;
  margin: 10px;
}

.browser-box {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.text-data {
  display: flex;
  padding-left: 10px;
}

.text-box-detalis {
  background-color: white;
  padding: 10px;
  width: 425px;
  border: 1px solid gray;
  border-radius: 10px;
  color: black;
  margin: 5px;
}

.check-style {
  margin: 5px;
}

.Attributes-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inner-form-detalis {
  padding: 15px 10px;
  background: #ffffff;
  max-height: 150px;
  min-height: 90px;
  overflow-y: auto;
}

.input-ui {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-animation-name: mui-auto-fill-cancel;
          animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.mani-text-ui {
  color: #fff;
  font-weight: 500;
  background: #0091b3;
  text-align: center;
  font-size: 12px;
  margin: 5px;
  padding: 10px;
  min-height: 400px;
  min-width: 200px;
}

.textmuti {
  margin: 10px;
  color: #68737a;
  position: relative;
  font-size: 14px;
  text-align: center;
}

.heading-sample-msg {
  position: relative;
  margin-bottom: 18px;
}

.sms-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #49535d;
  font-family: 'Roboto';
}

.sample-muti-files-list {
  color: #0091b3;
  /* height: 35px;
    display: flex;
    font-size: 14px;
    background: rgba(0, 145, 179, 0.06);
    align-items: center; */
  /* font-weight: 500; */
  /* flex-direction: row; */
  margin: '-65px';
}

.liforsampleimg {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  /* padding-bottom: 8px; */
  justify-content: flex-start;
  text-decoration: none;
}

.spamtaglist {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  /* letter-spacing: 0.00938em; */
}
/* .limainlist{
  padding: 12px 10px;
    background: rgba(0,145,179,.1);
    color: #0091b3;
    font-weight: 500;
    font-size: 12px;
} */

.text2 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #49535d;
  font-family: 'Roboto';
}

.litextlist {
  padding: 12px 10px;
  background: rgba(0, 145, 179, 0.1);
  color: #0091b3;
  font-weight: 500;
  font-size: 12px;
}

.content-row {
  background: #fff;
  padding: 15px 10px;
  transition: all 0.3s;
}
.title {
  max-width: unset;
}

.head {
  margin-left: 35px;
  margin-top: 2px;
}
.pargrap {
  color: #0091b3;
  font-weight: 500;
}

.MuiGrid-spacing {
  width: calc(100% + 32px);
  margin: -16px;
}
/* .grp-data{
  display: flex;
  justify-content: space-between;
} */

.use-cases-listing {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #c0ccd4;
}

.heading-text{
  margin-top: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    justify-content: center;
}
.head-text-two{
  color: #0091b3;
    margin-left: 5px;
}

.heading-row{
      padding: 12px 10px;
    background: rgba(0,145,179,.1);
    color: #0091b3;
    font-weight: 500;
    font-size: 12px;
}

.box-fill{
      width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.contentRow{
  background: #fff;
    padding: 15px 10px;
    transition: all .3s ease;
}

.data-added{
  display: flex;
  align-items: center;
}

.td-data{
  width: 50pc !important;
  padding: 10px;
  text-align: center;
}

.done-btn{
  margin-left: 36%;
  padding: 7px;
}


.extradiv{
  background-color: red;
}


.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color:#3db16b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: #3db16b;
}

.padding {
  padding: 5rem;
}

.card {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(19, 24, 44, 0.125);
  border-radius: 0.25rem;
}

/* 3db16b */
.tabing-part {
  margin: 10px;
  padding: 30px;
}

.brand-part {
  margin-top: 10px;
  padding-top: 10px;
}

.nav-pills .nav-link.active{
  background-color: #3db16b;
}



.tabcontent .content {
    margin: 0;
}

.tabcontent .content .campaign {
    padding: 0;
}

.tabcontent .brand-inner {
    margin: 0 !important;
}


.tabcontent .col-lg-12 {
    padding: 0;
}

.tabcontent .tab-content {
    margin: 0;
}


div#left-tabs-example-tabpane-second .light .content {
    padding: 0 15px;
}

div#left-tabs-example-tabpane-second .layout-user {
  height: auto;
}


.tablist {
    height: 100%;
    background: #fff;
    padding: 5px;
}

.flagInput {
  width: 150%;
}

.table-inner .row .col-sm {
  margin: 0;
}

.table-inner span.mani-text-ui {
  margin: 0;
  min-height: 1px;
  color: #fff;
  font-weight: 500;
  padding: 40px 10px;
  background: #3db16b;
  text-align: center;
  font-size: 12px;
  margin: 0 0 0 10px !important;
}

.table-inner .row .col-sm {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
}

.table-inner .row {
  margin: 0 0 10px 0 !important;
}

.table-inner .ul-data-added {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.table-inner .ul-data-added li {
  width: 100%;
}

.table-inner .ul-data-added p.qt-top {
  margin: 0;
  color: black;
  background: white;
  padding: 12px 20px 15px;
  box-sizing: border-box;
  width: 100%;
}

.table-inner .ul-data-added span.qt-bottom {
  word-break: break-all;
  padding: 13px 0;
  box-sizing: border-box;
  background: rgba(243, 244, 244, 0.75);
  display: block;
}

.campaign-details-form .wrapper-preview p.title-of-form {
  margin: 0 0 10px 0;
}

.campaign-details-form .wrapper-preview input.form-control,
.campaign-details-form .wrapper-preview textarea.form-control {
  margin: 0 !important;
}

.campaign-details-form .wrapper-preview .col-6 {
  padding: 0;
  margin-bottom: 15px;
}

.react-tel-input .form-control {
  width: 100% !important;
  margin-bottom: "20px";
}

