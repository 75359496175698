.main-container {
  margin: 0px;
  text-align: left;
}

.history {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 30px;
}

.history-search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 26px;
}

.history-search label {
  display: block;
  text-align: left;
  font-size: 14px;
  color: grey;
}

.state-date {
  margin-right: 8px;
  width: 140px;
}

.calendar1 {
  width: 130px;
  background-position: 95% 50%;
  background-color: white;
  position: relative;
}

.end-date {
  margin-right: 8px;
  width: 140px;
}

.history-search-button {
  position: absolute;
  bottom: 0px;
}

.call-search-text {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 15rem !important;
  background-position: 95% 50%;
  background-color: white;
}

.page-num {
  font-size: 0.7rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.button-disable {
  color: grey !important;
}

.button-enable {
  color: #000 !important;
}

.call-history-table {
  border: 0px;
  width: 100%;
  margin-left: 15px;
}

.history-table-title {
  font-size: 12px;
  color: grey;
  margin-bottom: 15px;
  width: 100%;
}

.call-history-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  margin-top: -1px;
  float: left;
  width: 100%;
  border-top: solid 1px #bcbcc2;
  border-bottom: solid 1px #bcbcc2;
}
.about-call-history {
  border-top: none !important;
  border-bottom: none !important;
}

.history-from {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.call-icon {
  float: left;
}

.calls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: gray;
}

.incalls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #3da00e;
}

.outcalls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #3553cc;
}

.missed-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #ff3d24;
}

.name {
  margin-bottom: 0;
  font-size: 16px;
}

.number {
  font-size: 14px;
  color: gray;
}

.quick-call {
  position: absolute;
  right: 255px;
  bottom: 0px;
}
.action-btn {
  margin-right: 10px
}
.action-col {
  display: flex;
  flex-direction: row;
}
.action-header {
  padding-left: 40px;
}

.notes-text {
  width: 100% !important;
}

.notes-history {
  font-size: 13px;
  color: #767676;
  margin-top: 10px;
}

.privateKeep{
  margin: 5px;
}
