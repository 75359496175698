
.form-membership .form-wrapper{
    text-align: left;
}
.form-membership .form-wrapper .form-group span {
    display: block;
    text-align: center;
    margin: 20px 0;
}
.form-info {
    margin-bottom: 20px;
    color: #202029;
}
.info{
    display: block;
    margin: 20px 0 5px;
    text-align: center;
    color: #808089;
}
.infobox{text-align: center;}
.link{
    color: #22A5FF;
}
.msg{margin: 5px 0 20px;}