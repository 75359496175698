.flagInput {
  width: 150%;
}

.table-inner .row .col-sm {
  margin: 0;
}

.table-inner span.mani-text-ui {
  margin: 0;
  min-height: 1px;
  color: #fff;
  font-weight: 500;
  padding: 40px 10px;
  background: #3db16b;
  text-align: center;
  font-size: 12px;
  margin: 0 0 0 10px !important;
}

.table-inner .row .col-sm {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
}

.table-inner .row {
  margin: 0 0 10px 0 !important;
}

.table-inner .ul-data-added {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.table-inner .ul-data-added li {
  width: 100%;
}

.table-inner .ul-data-added p.qt-top {
  margin: 0;
  color: black;
  background: white;
  padding: 12px 20px 15px;
  box-sizing: border-box;
  width: 100%;
}

.table-inner .ul-data-added span.qt-bottom {
  word-break: break-all;
  padding: 13px 0;
  box-sizing: border-box;
  background: rgba(243, 244, 244, 0.75);
  display: block;
}

.campaign-details-form .wrapper-preview p.title-of-form {
  margin: 0 0 10px 0;
}

.campaign-details-form .wrapper-preview input.form-control,
.campaign-details-form .wrapper-preview textarea.form-control {
  margin: 0 !important;
}

.campaign-details-form .wrapper-preview .col-6 {
  padding: 0;
  margin-bottom: 15px;
}

.react-tel-input .form-control {
  width: 100% !important;
  margin-bottom: "20px";
}
