.padding {
  padding: 5rem;
}

.card {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(19, 24, 44, 0.125);
  border-radius: 0.25rem;
}

/* 3db16b */
.tabing-part {
  margin: 10px;
  padding: 30px;
}

.brand-part {
  margin-top: 10px;
  padding-top: 10px;
}

.nav-pills .nav-link.active{
  background-color: #3db16b;
}



.tabcontent .content {
    margin: 0;
}

.tabcontent .content .campaign {
    padding: 0;
}

.tabcontent .brand-inner {
    margin: 0 !important;
}


.tabcontent .col-lg-12 {
    padding: 0;
}

.tabcontent .tab-content {
    margin: 0;
}


div#left-tabs-example-tabpane-second .light .content {
    padding: 0 15px;
}

div#left-tabs-example-tabpane-second .layout-user {
  height: auto;
}


.tablist {
    height: 100%;
    background: #fff;
    padding: 5px;
}
