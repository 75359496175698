.header {
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}

.right-dash-heading {
  padding: 19px 20px 18px;
  background: #5fbc76;
  border: none;
  color: #fff;
  display: flex;
}

.right-dash-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;
}
.right-dash {
  width: 100%;
}

.right-dash-middle {
  padding: 50px 50px 0px !important;
}

.dashboard-box {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.dash-box-heading h3 {
  font-size: 18px;
  font-weight: 600;
  float: left;
}
.dash-box-heading span {
  float: right;
  font-size: 18px;
  font-weight: 600;
}
.dash-box-heading {
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}
.dash-box-heading:after {
  content: '';
  display: block;
  clear: both;
}
.box-btm-cont {
  padding: 15px 20px;
}
.box-btm-cont p {
  margin-bottom: 0;
}

.dashboard-chart {
  border: 1px solid #ddd;
  padding: 15px 20px;
  background-color: #ffffff;
}
.dash-fix-content {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.right-dash {
  position: relative;
}

.csv-reader-input {
  background-color: #3db16b;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer;
  margin-left: 1rem !important;
  border-radius: 0.25rem;
  margin-right: 1rem;
  vertical-align: middle;
  height: 40px;
  display: flex;
  align-items: center;
}

.csv-reader-input label {
  margin-bottom: 0 !important;
  cursor: pointer;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .search-input {
    border: 1px solid transparent;
    border-radius: 25px;
    width: 240px !important;
  }
}

.search-input {
  border: 1px solid transparent !important;
  border-radius: 25px !important;
  padding-left: 40px;
}
.contact-search {
  position: absolute;
  margin-top: 2px;
  margin-left: 18px;
  width: auto !important;
}
.contact-search i {
  color: #292f4c;
  opacity: 0.3;
}
.distribution-menu .distribution-icon {
  background-color: #3db16b !important;
  border-color: #3db16b !important;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer !important;
  margin-left: 1rem !important;
  border-radius: 0.25rem;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.distribution-menu .btn.btn-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: none !important;
}
.filter {
  background-color: #3db16b;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer;
  margin-left: 1rem !important;
  border-radius: 0.25rem;
  vertical-align: middle;
}
.filter,
.distribution-icon i {
  color: #fff;
}
.initials-avatar {
  background-color: #3db16b !important;
}
.phone-icon {
  margin-right: 10px;
}
.contact-sideMenu {
  display: flex;
}
.contact-action {
  display: flex;
  justify-content: space-evenly;
  padding-top: 25px !important;
  cursor: pointer;
}
.contact-call {
  margin-right: 10px;
}
.contact-action i {
  font-size: 14px !important;
}
.position-add-dropdown {
  background-color: #3db16b !important;
  border-color: #3db16b !important;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
  cursor: pointer !important;
  border-radius: 0.25rem;
  vertical-align: middle;
  color: #fff;
  margin-top: 10px;
}
.position-add-dropdown:hover {
  color: #fff;
}
.distribution-dropmenu {
  width: 100%;
  min-width: 15rem;
  text-align: center;
  left: -83px !important;
  box-shadow: 3px 3px 7px 1px #aaaaaa;
  transform: initial !important;
  top: 50px !important;
  margin-left: 38px !important;
}
.drop-distribution {
  border-bottom: 1px solid #ddd;
  display: flex;
  margin: 10px 5px;
}
.dist-badge {
  width: 13%;
  display: block !important;
  padding: 10px !important;
  margin-right: 10px !important;
  border-radius: 7px !important;
}
.dist-input {
  border: none !important;
  width: inherit !important;
}
.dist-input:focus-visible {
  outline: none;
}
.drop-filter {
  border-bottom: 1px solid #ddd;
  padding: 10px 24px;
  text-align: left;
}
.filter-header {
  display: flex;
  color: #000;
  font-weight: 700;
  padding: 10px 0px;
  justify-content: space-between;
}
.filter-clear {
  font-size: 12px;
  color: #5fbc76 !important;
}
.drop-filter-distribution {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 10px 24px;
  justify-content: space-between;
}
.drop-filter-distribution i {
  font-size: 12px;
  color: #000;
  font-weight: 600;
  padding-top: 7px;
}
.drop-select {
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
.dist-more {
  color: #5fbc76;
  margin-bottom: 0;
  cursor: pointer;
}
.drop-scroll {
  height: 289;
  overflow-y: auto;
  overflow-x: hidden;
}
.drop-scroll-distribution {
  max-height: 317px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}
.multiple-dist {
  display: flex;
}
.filter-dropMenu {
  width: 100%;
  min-width: 13rem;
  text-align: center;
  left: -285px !important;
  box-shadow: 3px 3px 7px 1px #aaaaaa;
  transform: initial !important;
  top: 126px !important;
  margin-left: 32px !important;
}
.dist-toggle {
  display: none !important;
}

.disDataDis {
  margin-right: 5px;
}

.drop-scrolling {
  height: 155px;
  overflow-y: auto;
  overflow-x: hidden;
}

.iconsSet {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.disIconst button{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.addContactBtn {
    background-color: #3db16b !important;
    border-color: #3db16b !important;
    font-size: 14px;
    width: auto;
    display: inline-flex;
    padding: 10px 15px;
    line-height: 14px;
    cursor: pointer !important;
    margin-left: 1rem !important;
    border-radius: 0.25rem;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

