
.campaign {
  padding: 30px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.brand-of-section {
  background-color: #3db16b;
  height: 60px;
  border: 1px solid gray;
  border-radius: 10px;
  color: white;
  text-align: center;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
}

.brand-inner {
  margin: 15px;
}

.report-table-content table tbody {
  background: #ffffff;
}

.report-table-content table thead th {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #3db16b;
  color: #ffffff;
}

.report-table-content table td {
  vertical-align: middle;
}

.report-table-content table td {
  text-align: center;
}

.Relation-box {
  text-align: center;
  height: 100px;
  padding: 20px 15px;
  box-sizing: border-box;
  background-color: #3db16b;
  border-radius: 0 0 10px 10px;
  margin: 10px;
  cursor: pointer;
}

.drop-down-ui {
  flex: 0 0 50%;
  max-width: 50%;
  border-radius: 5px;
}

.brand-relation-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100px;
  border-radius: 12px;
  border: '5px solid red';
  margin-top: 6px;
  flex-direction: row-reverse;
}

.brand-rel-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 80px;
  border: 1px solid #3db16b;
  border-radius: 1%;
  flex-direction: row-reverse;
  margin-top: 10px;
  align-items: center;
  padding: 10px;
  width: 700px;
}

.brand-title {
  display: flex;
  align-items: center;
  /* color: red; */
  text-align: center;
  margin-left: 30%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  list-style: none;
  margin: 10px;
}

.pagination ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.pagination ul li {
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
  font-weight: 600;
  cursor: pointer;
}

.pagination ul li a {
  padding-inline: 20px;
  padding-block: 12px;
  display: block;
}

.pagination ul li:hover {
  background-color: #3db16b;
  border-color: #3db16b;
  color: white;
}

.pagination ul li.disabled {
  cursor: not-allowed;
}

.pagination ul li.disabled:hover {
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
  color: black;
}

.pagination ul li.selected {
  background-color: #3db16b;
  border-color: #3db16b;
  color: white;
}

.pagination ul li:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.pagination ul li:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.box-data {
  margin: 0 40px;
  padding: 0;
  background-color: #fff;
  height: 30%;
}

.main {
  position: relative;
  padding: 10px 10px 20px;
  margin: 20px 0px;
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
}

.box-inner {
  text-align: center;
  height: 105px;
  padding: 20px 15px;
  box-sizing: border-box;
  background: rgba(243, 244, 244, 0.75);
  border-radius: 0 0 10px 10px;
  float: left;
  width: 33.33%;
  padding: 10px;
}

.title-desk {
  max-height: 60px;
  text-align: center;
  padding: 30px 0 12px;
  background: rgb(48, 179, 101);
  border-radius: 10px 10px 0 0;
  width: 172px;
  margin-top: 15px;
  color: white;
}

.box-detalis {
  text-align: center;
  height: 105px;
  padding: 20px 15px;
  box-sizing: border-box;
  background: rgba(243, 244, 244, 0.75);
  border-radius: 0 0 10px 10px;
  width: 172px;
}

.tag {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: black;
}

.back-color {
  background-color: white;
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  margin: 0;
}

.title-of-form {
  font-size: 15px;
  font-weight: bold;
  margin: 10px;
}

.loading-text {
  text-align: center;
  margin-block: 8px;
  font-size: 25px;
}

.campings-part {
  margin: 20px;
  padding: 20px;
  background-color: red;
}

.heading-block {
  position: relative;
  margin-bottom: 18px;
}
.heading1 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #49535d;
  font-family: 'Roboto';
}
.box-data {
  /* flex-grow: 0; */
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 8px;
  /* flex-basis: 100%; */
}

.usecase {
  max-width: 100%;
}
.remove-extra-style {
  list-style-type: none;
}

.list-nested {
  background: #fff;
  padding: 5px;
  /* padding: 10px 5px; */
  transition: all 0.3s;
}
.main-box-data {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #c0ccd4;
}

.heading1 {
  margin-left: 35px;
  margin-top: 2px;
}

.wrapper-preview {
  border: 1px solid #c0ccd4;
  padding: 25px 30px;
  margin-bottom: 30px;
}

.heading-block {
  position: relative;
  margin-bottom: 18px;
}

.paragraph {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #68737a;
  font-family: 'Roboto';
}

.listing-data {
  position: relative;
  padding-left: 40px;
}

.main-div-data {
  color: #fff;
  font-weight: 500;
  padding: 40px 10px;
  background: #0091b3;
  text-align: center;
  font-size: 12px;
}

.main-div-data-inner {
  color: #fff;
  font-weight: 500;
  padding: 40px 10px;
  background: red;
  text-align: center;
  font-size: 12px;
}

.tddata {
  background-color: rgb(48, 179, 101);
  color: white;
}

.form-rapper{
  background-color: white;
  border-radius: 26px;
}

.wrap-cam-card {
  margin: 10px;
  padding: 10px;
}

.use-start-case {
  margin-right: 10%;
  padding-left: 10%;
}

.text-display {
  /* text-align: center; */
  margin-top: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: center;
}
.display-map-data {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.mno-listing .list-unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ul-data-added {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 10px;
  /* color: #0091b3;
    background: rgba(0,145,179,.1);
    padding: 12px 20px 15px;
    height: 50px;
    box-sizing: border-box; */
}

.art-data {
  margin: 10px;
  /* color: #fff;
    font-weight: 500;
    padding: 40px 10px;
    background: #0091b3;
    text-align: center;
    font-size: 12px; */
}

.render-section {
  color: #0091b3;
  background: rgba(0, 145, 179, 0.1);
  padding: 12px 20px 15px;
  height: 50px;
  box-sizing: border-box;
}

.media-box {
  width: 100%;
  border: 1px solid #c0ccd4;
  height: 275px;
  display: flex;
  position: relative;
  background: rgba(0, 145, 179, 0.06);
  box-sizing: border-box;
  align-items: center;
  padding-top: 52px;
  flex-direction: column;
  margin: 10px;
}

.browser-box {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.text-data {
  display: flex;
  padding-left: 10px;
}

.text-box-detalis {
  background-color: white;
  padding: 10px;
  width: 425px;
  border: 1px solid gray;
  border-radius: 10px;
  color: black;
  margin: 5px;
}

.check-style {
  margin: 5px;
}

.Attributes-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inner-form-detalis {
  padding: 15px 10px;
  background: #ffffff;
  max-height: 150px;
  min-height: 90px;
  overflow-y: auto;
}

.input-ui {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.mani-text-ui {
  color: #fff;
  font-weight: 500;
  background: #0091b3;
  text-align: center;
  font-size: 12px;
  margin: 5px;
  padding: 10px;
  min-height: 400px;
  min-width: 200px;
}

.textmuti {
  margin: 10px;
  color: #68737a;
  position: relative;
  font-size: 14px;
  text-align: center;
}

.heading-sample-msg {
  position: relative;
  margin-bottom: 18px;
}

.sms-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #49535d;
  font-family: 'Roboto';
}

.sample-muti-files-list {
  color: #0091b3;
  /* height: 35px;
    display: flex;
    font-size: 14px;
    background: rgba(0, 145, 179, 0.06);
    align-items: center; */
  /* font-weight: 500; */
  /* flex-direction: row; */
  margin: '-65px';
}

.liforsampleimg {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  /* padding-bottom: 8px; */
  justify-content: flex-start;
  text-decoration: none;
}

.spamtaglist {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  /* letter-spacing: 0.00938em; */
}
/* .limainlist{
  padding: 12px 10px;
    background: rgba(0,145,179,.1);
    color: #0091b3;
    font-weight: 500;
    font-size: 12px;
} */

.text2 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #49535d;
  font-family: 'Roboto';
}

.litextlist {
  padding: 12px 10px;
  background: rgba(0, 145, 179, 0.1);
  color: #0091b3;
  font-weight: 500;
  font-size: 12px;
}

.content-row {
  background: #fff;
  padding: 15px 10px;
  transition: all 0.3s;
}
.title {
  max-width: unset;
}

.head {
  margin-left: 35px;
  margin-top: 2px;
}
.pargrap {
  color: #0091b3;
  font-weight: 500;
}

.MuiGrid-spacing {
  width: calc(100% + 32px);
  margin: -16px;
}
/* .grp-data{
  display: flex;
  justify-content: space-between;
} */

.use-cases-listing {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #c0ccd4;
}

.heading-text{
  margin-top: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    justify-content: center;
}
.head-text-two{
  color: #0091b3;
    margin-left: 5px;
}

.heading-row{
      padding: 12px 10px;
    background: rgba(0,145,179,.1);
    color: #0091b3;
    font-weight: 500;
    font-size: 12px;
}

.box-fill{
      width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.contentRow{
  background: #fff;
    padding: 15px 10px;
    transition: all .3s ease;
}

.data-added{
  display: flex;
  align-items: center;
}

.td-data{
  width: 50pc !important;
  padding: 10px;
  text-align: center;
}

.done-btn{
  margin-left: 36%;
  padding: 7px;
}


.extradiv{
  background-color: red;
}

