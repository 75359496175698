.form-membership .form-wrapper {
  text-align: left;
}
.form-membership .form-wrapper .form-group p {
  color: #ea4141;
}
.form-info {
  margin-bottom: 20px;
  color: #202029;
}
.info {
  display: block;
  margin: 20px 0 5px;
  text-align: center;
  color: #808089;
}
.infobox {
  text-align: center;
}
.link {
  color: #22a5ff;
}
.msg {
  margin: 5px 0 20px;
}
