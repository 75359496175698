.user-name {
  font-size: 18px;
}
.report-table table tbody {
  background: #ffffff;
}
.report-table table thead th {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  background-color: #3db16b;
  color: #ffffff;
}
.report-table table td {
  vertical-align: middle;
}
.report-table table td {
  text-align: center;
}
.user-list {
  font-size: 18px;
  padding: 12px 5px;
  color: #555;
  border: 1px solid #dcdcdc;
  background: #f6f6f6;
  margin-bottom: 5px;
  padding-left: 20px;
}
